import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlName,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { Endpoint } from "src/app/shared/endpoints";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  showNotificationCount: boolean;
  notificationCount: number;
  noResultReturned: boolean;
  showPassword = false;
  showConfirmPassword = false;
  Code: string;
  Password: string;
  ConfirmPassword: string;
  message: boolean;

  constructor(
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,
    private router: Router,
    
    private toastr: ToastrService,
  ) { }

  changePasswordForm = new UntypedFormGroup({
    Code: new UntypedFormControl("", [Validators.required, Validators.email]),
    Password: new UntypedFormControl("", Validators.required),
    ConfirmPassword: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", Validators.required),
  });

  ngOnInit(): void {
    
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.email = JSON.parse(localStorage.getItem("userEmail")); 
    this.showNotificationCount = false;
    this.notificationCount = 4;
    
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.changePasswordForm = this.formbuilder.group({
      Code: new UntypedFormControl("", Validators.required),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),
      Password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
      ]),
      ConfirmPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
        this.equalto("Password"),
      ]),
    });

    this.getProfileUser();
  }
  equalto(field_name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }


  signIn() {
    this.noResultReturned = true;
    const userModel = {
      EmailAddress: this.email,
      Password: this.Password,
      ConfirmPassword: this.ConfirmPassword,
      ChangePasswordHash: this.Code,
    };



    this.userserviceService
      .changeOldPassword(Endpoint.ChangePassword, userModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        this.router.navigate(["/login"]);
      });
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }
  showMessage() {
    if (this.Code == null || undefined) {
      this.message = true; 
      this.toastr.warning(" Check email for activation code .");
    }
    else {
      this.message = false; 
    }
  }

  showToastForConfirmPassword() {
    if (this.Password != this.ConfirmPassword || this.ConfirmPassword != null) {
      this.message = true;
      this.toastr.warning(" Please enter correct password ."); 
    }
    else {
      this.message = false; 
    }
  }
  showToastForDelete(message) {
    this.toastr.success(message);
  }
  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
}
