
import { Component, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { GetContentService } from "src/app/Services/getcontent.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectionRephraseAIComponent } from "../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";

@Component({
  selector: "app-steps",
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  fileData: File = null;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  iname: string;
  _steps = [];
  images: any = [];
  allfiles: any = [];
  allfile: any = [];
  videos: any = [];
  flows: any;
  information: string;
  description: string;
  stepToEdit: any;
  showImageorVideo: boolean;
  Url: string;
  posterUrl: string;
  Description: string;
  showVideo: boolean;
  showMedia: string;
  showImage: boolean;
  stepIndex: number;
  isStepCoverPageURISet: boolean;
  fileName: string;
  noResultReturned: boolean;
  isLoading: boolean;
  progress: number;
  videoUrl: string;
  videoPosterUrl: string;

  constructor(
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    private router: Router,
    private dialog:MatDialog,
    private toastManager: ToastrService, 
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private getContentService:GetContentService

  ) {
    this._steps = [];
    this.stepIndex = -1;
    this.stepToEdit = JSON.parse(localStorage.getItem("stepToEdit"));
    if (this.stepToEdit != null) {
      this.Url = this.stepToEdit.stepToEdit.Url;
      this.posterUrl = this.stepToEdit.stepToEdit.posterUrl;
      this.Description = this.stepToEdit.stepToEdit.Description;
      this.showImage = this.stepToEdit.stepToEdit.showImage;
      this.showVideo = this.stepToEdit.stepToEdit.showVideo;
      this.stepIndex = this.stepToEdit.index;
      this.fileName = this.stepToEdit.stepToEdit.stepFileName;
    } else {
      this.Url = "";
      this.Description = "";
      this.stepIndex = -1;
      this.posterUrl = "";
    }

    if (this.Url != "") {
      this.isStepCoverPageURISet = true;
    } else {
      this.isStepCoverPageURISet = false;
    }
  }

  ngOnInit(): void {
    this.flowServiceService.setScrollTop();
    this.information = JSON.parse(localStorage.getItem("information"));
    this.noResultReturned = true;
    // 
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    //
    this.noResultReturned = false;
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });


    this._steps = JSON.parse(localStorage.getItem("stepsCoverPageDetails"));
  }
  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;
 
  GoToOriginalText() {
    this.Description = this.originalContentTitleText;
  }

  rephraseDescriptionText() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.Description }  // Pass the text to the dialog
    });
    if (!this.isRephrased) {
      this.originalContentTitleText = this.Description;
      console.log(this.Description);
    }
    this.isRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  
        this.Description = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
        this.isRephraseInProgress = false;
      }
    });
  }
  done() {
    var stepsCoverPage = {
      url: this.Url,
      posterUrl: this.posterUrl,
      description: this.Description,
      stepFileName: this.fileName,
    };


    if (this._steps == null) {
      this._steps = [];
      this._steps.push(stepsCoverPage);
    } else if (this.stepIndex >= 0) {
      var stepValue = this._steps[this.stepIndex];


      if (stepValue != undefined) {
        stepValue.description = this.Description;
        stepValue.url = this.Url;
        this._steps.splice(this.stepIndex, 1, stepValue);
      } else {
        this._steps.push(stepsCoverPage);
      }
    } else {
      this._steps.push(stepsCoverPage);
    }

    localStorage.setItem("stepsCoverPageDetails", JSON.stringify(this._steps));
    this.router.navigate(["/create-flow"], {
      relativeTo: this._activatedRoute,
    });
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }
  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  close() {
    localStorage.setItem("stepsCoverPageDetails", JSON.stringify(this._steps));
    this.router.navigate(["/create-flow"]);
  }

  removePicture() {
    this.profilePicture = null;
  }

  getFileName(fileName) {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  selectFiles(event: any) {
    if (event.target.files) {
      this.noResultReturned = true;
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      this.isStepCoverPageURISet = true;
      this.isLoading = true; // Add loading state
      
      // Check if it's an image file
      const imageExtensions = ["JPEG", "jpeg", "png", "jpg", "PNG", "JFIF", "jfif", "TIFF", "tiff", "TIF", "tif", "JPG"];
      const videoExtensions = ["MP4", "mp4", "MOV", "mov", "WMV", "wmv", "FLV", "flv", "AVCHD", "avchd", "WEBM", "webm", 
                              "MKV", "mkv", "VOB", "vob", "OGV", "ogv", "GIFY", "gifv", "M4P", "m4p", "M4V", "m4v", 
                              "3GP", "3gp", "3G2", "3g2", "AMV", "amv", "NSV", "nsv", "OGG", "ogg", "F4V", "f4v", 
                              "F4P", "f4p", "F4A", "f4a", "F4B", "f4b"];
  
      const isImage = imageExtensions.some(ext => this.fileData.name.includes(ext));
      const isVideo = videoExtensions.some(ext => this.fileData.name.includes(ext));
      
      const uploadUrl = isImage 
        ? environment.apiBaseUrl + environment.uploadImageUrl 
        : environment.apiBaseUrl + environment.uploadVideoUrl;
  
      this.http
        .post(uploadUrl, formData, {
          reportProgress: true,
          observe: 'events'
        })
        .subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress: {
                if (event.total !== undefined) {
                  const progress = (event.loaded / event.total) * 100;
                  if (progress !== 100) {
                    this.progress = progress;
                  }
                }
                break;
              }
              case HttpEventType.Response:
                this.handleFileUpload(event, isImage);
                break;
            }
          },
          (error) => {
            this.isLoading = false;
            this.noResultReturned = false;
            this.fileName = '';
            this.toastManager.warning("Please upload files up to 80MB");
            console.error('An error occurred:', error);
          }
        );
      }
  }
  
  handleFileUpload(res: HttpResponse<any>, isImage: boolean) {
    this.isLoading = false;
    this.noResultReturned = false;
    const mediaUploadStatus = res.body as any;
    
    if (mediaUploadStatus != null) {
      if (isImage) {
        this.showImage = true;
        this.showVideo = false;
        this.Url = environment.imageStorageBaseUrl + mediaUploadStatus.fileName;
      } else {
        this.showVideo = true;
        this.showImage = false;
        this.Url = environment.videoStorageBaseUrl + mediaUploadStatus.fileName;
        this.posterUrl = environment.imageStorageBaseUrl + mediaUploadStatus.posterImageName;
      }
      this.toastManager.success("File has been uploaded successfully.");
      this.progress = 100;
    } else {
      // Handle null response
      alert(mediaUploadStatus?.message || "Upload failed");
    }
  }
}
