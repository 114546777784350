
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-qrcode",
  templateUrl: "./qrcode.component.html",
  styleUrls: ["./qrcode.component.scss"],
})
export class QRCodeComponent implements OnInit, AfterViewInit {
  qrcodename: string;
  title = "generate-qrcode";
  elementType: "url" | "canvas" | "img" = "url";
  value: string;
  display = false;
  href: string;

  // ename = 'Angular' +VERSION.major;
  //  elementType = NgxQrcodeElementTypes.URL;
  // correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  // value='https://flowchest.azurewebsites.net/grid';

  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  share: any;

  flowData: any;
  showvideo: boolean;
  showimage: boolean;
  imageURI: any;
  videoURI: any;
  displayedColumns: string[] = ["position", "name"];
  dataSource = [];
  dataSourceSteps = [];
  imageSource: any;
  description: any;
  nextCount: number;
  itemsRequired: number;
  likecolor: any;
  viewCount: any;
  viewText: any;
  followButtonText: any;
  followingcolor: any;
  followingbgcolor: any;
  showFollow: any;
  isFirstSlide: any;
  isLastSlide: any;
  progressPercentage: number;
  progressBy: number;
  stepCount: number;
  bookmarkcolor: any;
  stepNumber: number;
  isMobileView: boolean;
  carouselOptions: any;
  showVideoOrImage: boolean;
  facebookBtn: any;
  twitterBtn: any;
  pinterestBtn: any;
  linkedinBtn: any;
  whatsappBtn: any;
  flowCoverImageUrl: any;
  @ViewChild('qrCodeImage') qrCodeImage: ElementRef;
  //  facebookBtn:any;

  constructor(
    private userserviceService: UserserviceService,
    private router: Router,

    private dataService: DataService,
    private flowServiceService: FlowServiceService,
    public dialogRef: MatDialogRef<QRCodeComponent>
  ) { }


  ngAfterViewInit(): void {
    this.waitForQRCodeRender();
  }

  private waitForQRCodeRender() {
    const interval = setInterval(() => {
      const canvas = document.querySelector('#qrCodeImage canvas');
      if (canvas) {
        clearInterval(interval);
        console.log("clearinterval");
      }
    }, 100);
  }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    this.flowData = JSON.parse(localStorage.getItem("flow"));
    if (this.flowData != null) {
      this.imageSource = this.flowData.url;
      this.display = true;
      this.qrcodename = environment.webBaseUrl + `/guide/${this.generateUrlSlug(this.flowData.title)}/${this.flowData.rowKey}`;
      this.generateQRCode();
      this.getProfileUser();
    }
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
  }
  close() {
    this.dialogRef.close();
  }
  closedClicked() {
    this.router.navigate([`/guide/${this.generateUrlSlug(this.flowData.title)}/${this.flowData.rowKey}`]); 
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }
  loadSteps(steps) {
    steps.forEach((element) => {
      this.dataSourceSteps.push({
        title: element.title,
        url: element.url,
        description: element.description,
        id: element.rowKey,
        ShowImage: this.showimage,
      });
    });
  }
  getProfileUser(): void {
    if (this.userData != null) {

      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  generateQRCode() {
    if (this.qrcodename == "") {
      this.display = false;
      return;
    } else {
      this.value = this.qrcodename;
    }
  }

  onDownloadQRCode() {
    this.downloadQRCode();
  }

  downloadQRCode() {
    const canvas = document.querySelector('#qrCodeImage canvas') as HTMLCanvasElement;
    console.log(canvas);
    if (!canvas) {
      console.error("QR code canvas not found!");
      return;
    }

    const imageUrl = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'qr_code.png';
    link.click();
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  generateUrlSlug(html) {
    if (!html) return ''; 
    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }
}
