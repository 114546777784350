import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Console } from "console";

import { ToastrService } from "ngx-toastr";
import { BundleService } from "src/app/Services/bundleservice.service";
import { GetContentService } from "src/app/Services/getcontent.service";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { environment } from "src/environments/environment";
import { SelectionRephraseAIComponent } from "../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-edit-bundle",
  templateUrl: "./edit-bundle.component.html",
  styleUrls: ["./edit-bundle.component.scss"],
})
export class EditBundleComponent implements OnInit {
  description: string;
  title: string;
  operationInProgress: boolean;
  bundleImageUrl: string;
  fileData: File = null;
  showVideo: boolean;
  fileName: string;
  noResultReturned: boolean;
  addbundleselectedflows: [];
  userData: any;
  selectedFlowIds: any[];
  showFlows: boolean;
  bFlows: boolean;
  coverImageUri: any;
  bundleId: any;
  uniqueId: any;
  CategoryName: any;
  showDeleteContentMenu: boolean;
  private _flows: any;
  allBundleFlows: any[];
  flowTitle: any;
  flowUniqueCode: any;
  flowImage: any;
  flowUserProfilePic: any;
  flowuserName: any;
  flowUserName: any;
  bundleData: any;
  showMenu: boolean;
  imageSource: string;
  videoPlayCounter: any;
  viewUnloaded: any;
  bundleFlows: any;
  price: any;
  isPaid: boolean;
  sffilelink: any;
  isAddSupportingDocument: boolean;
  linkName: any;
  hyperLink: any;
  supportingDocumentHyperLinks: any[];
  supportingDocumentDocuments: any[];
  documentName: any;
  noApprovedFlows: any;
  tabIndex: number;
  FlowCount: number;
  sffilename: string;
  totalSupportingDocuments: number;
  showMessage: boolean;
  selectedFlow: boolean;
  progress: number = 0;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private bundleService: BundleService,
    private getContentService: GetContentService,
    private activateRouter: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.bundleFlows = [];
    this.title = "";
    this.description = "";
    this.operationInProgress = false;
    this.selectedFlowIds = [];
    this.supportingDocumentHyperLinks = [];
    this.supportingDocumentDocuments = [];
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    let bundleData = JSON.parse(localStorage.getItem("createbundledetails"));
    this.activateRouter.paramMap.subscribe((params) => {
      var bundleId = params.get("id");
      this.bundleId = bundleId;
      if (bundleId != null && bundleId != '') this.getBundleDetails(bundleId);
    });
    console.log(bundleData);
  }
  // originalTitleText!: string;
  // repharaseButtonTitleText: string = "Rephrase text with AI";
  // isTitleRephrased: boolean = false;
  // isTitleRephraseInProgress: boolean = false;

  // GoToOriginalTitleText() {
  //   this.bundleData.title = this.originalTitleText;
  // }

  // async rephraseTitleText(input: string) {

  //   if (input == undefined || input == "") {
  //     this.toastr.warning(
  //       "The Text To Rephrase is required."
  //     ); 
  //     return;
  //   }

  //   if (!this.isTitleRephrased) {
  //     this.originalTitleText = input;
  //   }

  //   this.isTitleRephrased = true;
  //   this.isTitleRephraseInProgress = true;
  //   console.log(input);
  //   var aiModel = {
  //     textToRephrase: input
  //   };
  //   (await this.getContentService
  //     .getContentFromAI(aiModel))
  //   .subscribe((aiResponse) => {
  //     if(aiResponse){
  //       console.log(aiResponse);
  //       console.log(aiResponse[0].completions);
  //       this.bundleData.title = aiResponse[0].completions;
  //       this.isTitleRephraseInProgress = false;
  //     }
  //   });
  //   this.isTitleRephraseInProgress = false; 
  // }

  // originalContentTitleText!: string;
  // repharaseButtonText: string = "Rephrase text with AI";
  // isRephrased: boolean = false;
  // isRephraseInProgress: boolean = false;

  // GoToOriginalText() {
  //   this.bundleData.description = this.originalContentTitleText;
  // }

  // async rephraseDescriptionText(input: string) {

  //   if (input == undefined || input == "") {
  //     this.toastr.warning(
  //       "The Text To Rephrase is required."
  //     );
  //     return;
  //   }

  //   if (!this.isRephrased) {
  //     this.originalContentTitleText = input;
  //   }

  //   this.isRephrased = true;
  //   this.isRephraseInProgress = true;
  //   console.log(input);
  //   var aiModel = {
  //     textToRephrase: input
  //   };
  //   (await this.getContentService
  //     .getContentFromAI(aiModel))
  //   .subscribe((aiResponse) => {
  //     if(aiResponse){
  //       console.log(aiResponse);
  //       console.log(aiResponse[0].completions);
  //       this.bundleData.description = aiResponse[0].completions;
  //       this.isRephraseInProgress = false;
  //     }
  //   });
  //   this.isRephraseInProgress = false;

  // }
  drop(event) { }

  getBundleDetails(bundleId) {
    this.bundleFlows = [];
    this.operationInProgress = true;
    var email = "";
    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      BundleId: bundleId,
      userEmail: email
    });


    this.bundleService.getBundleById(flowQueryModel).subscribe((data) => {
      this.operationInProgress = false;
      if (data.bundleModel != null) {
        this.bundleData = data.bundleModel;
        this.addbundleselectedflows = JSON.parse(
          localStorage.getItem("addbundleselectedflows")
        );

        if (localStorage.getItem("createbundledetails") != null) {
          var bundleModel = JSON.parse(
            localStorage.getItem("createbundledetails")
          );

          if (bundleModel != null && bundleModel != undefined) {
            this.bundleData.title = bundleModel.title;
            this.bundleData.description = bundleModel.description;
            this.bundleData.url = bundleModel.url;
            this.bundleData.id = bundleModel.rowKey;
            this.bundleData.isPaid = bundleModel.isPaid;
            this.bundleData.price = bundleModel.price;
            this.bundleData.flowSupportingDocumentsHyperLinkModels =
              bundleModel.flowSupportingDocumentsHyperLinkModels;
            this.bundleData.flowSupportingDocumentsDocumentModels =
              bundleModel.flowSupportingDocumentsDocumentModels;
            this.bundleData.flowSupportingDocumentsHyperLinkModels.length =
              bundleModel.flowSupportingDocumentsHyperLinkModels.length;
            this.bundleData.flowSupportingDocumentsDocumentModels.length =
              bundleModel.flowSupportingDocumentsDocumentModels.length;
          }
        }

        if (
          this.addbundleselectedflows != null &&
          this.addbundleselectedflows != undefined
        ) {
          this.bundleFlows = this.addbundleselectedflows;
        } else {
          this.bundleData.flows.forEach((element) => {
            this.bundleFlows.push({ flow: element, isSelected: true });
          });
          localStorage.setItem(
            "addbundleselectedflows",
            JSON.stringify(this.bundleFlows)
          );
        }
        this.showVideoFromFile(this.bundleData.url);

        if (this.bundleFlows.length >= 2) {
          this.bFlows = true;
        } else {
          this.bFlows = false;
        }

        if (
          this.userData != null &&
          this.userData.emailAddress != this.bundleData.customerEmail
        ) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
        }

        if (
          this.bundleData.profilePic != undefined &&
          this.bundleData.profilePic != null &&
          this.bundleData.profilePic != ""
        ) {
          this.showImage = true;
        } else {
          this.showImage = false;
        }
      } else {
        this.toastr.success(
          "The bundle you are trying to open is no longer available. Explore how-to DIY bundles here"
        );
        this.router.navigate(["/"]);
      }
    });
  }

  checkValue() {
    console.log(this.bundleData.isPaid);
    if (!this.bundleData.isPaid) {
      this.bundleData.price = null;
    }
  }

  showVideoFromFile(filePath: string) {
    if (
      filePath.includes("MP4") ||
      filePath.includes("mp4") ||
      filePath.includes("MOV") ||
      filePath.includes("mov") ||
      filePath.includes("WMV") ||
      filePath.includes("wmv") ||
      filePath.includes("FLV") ||
      filePath.includes("flv") ||
      filePath.includes("AVCHD") ||
      filePath.includes("avchd") ||
      filePath.includes("WEBM") ||
      filePath.includes("webm") ||
      filePath.includes("MKV") ||
      filePath.includes("mkv") ||
      filePath.includes("VOB") ||
      filePath.includes("vob") ||
      filePath.includes("OGV") ||
      filePath.includes("ogv") ||
      filePath.includes("GIFY") ||
      filePath.includes("gifv") ||
      filePath.includes("M4P") ||
      filePath.includes("m4p") ||
      filePath.includes("M4V") ||
      filePath.includes("m4v") ||
      filePath.includes("3GP") ||
      filePath.includes("3gp") ||
      filePath.includes("3G2") ||
      filePath.includes("3g2") ||
      filePath.includes("AMV") ||
      filePath.includes("amv") ||
      filePath.includes("NSV") ||
      filePath.includes("nsv") ||
      filePath.includes("OGG") ||
      filePath.includes("ogg") ||
      filePath.includes("FLV") ||
      filePath.includes("flv") ||
      filePath.includes("F4V") ||
      filePath.includes("f4v") ||
      filePath.includes("F4P") ||
      filePath.includes("f4p") ||
      filePath.includes("F4A") ||
      filePath.includes("f4a") ||
      filePath.includes("F4B") ||
      filePath.includes("f4b")
    ) {
      this.showVideo = true;
      this.imageSource = filePath;
    } else {
      this.showImage = false;
      this.imageSource = filePath;
    }
  }

  vidEnded(event) {
    var playcount = 0;
    var video = this.videoPlayCounter.filter(
      (v) => v.currentSrc == event.target.currentSrc
    );
    if (video != null) {
      playcount = video.length;
    }

    if (playcount < 3) {
      this.videoPlayCounter.push({
        eventobj: event,
        currentSrc: event.target.currentSrc,
        playcount: playcount,
      });
      event.target.play();
    }
  }

  vidfocus(event) { }

  vidplay(event) {
    if (!this.viewUnloaded) {
      var videos = this.videoPlayCounter.filter((v) => v.id == event.target.id);

      if (videos.length > 3) {
        videos.forEach((element) => {
          var obj = this.videoPlayCounter.indexOf(element);

          if (obj > -1) {
            this.videoPlayCounter.splice(obj, 1);
          }
        });
      }
      event.target.play();
    }
  }

  saveFlowToServerAccessLevel(accessType) {
    console.log(this.bundleData);
    this.selectedFlow = false;
    console.log(this.bundleFlows);
    console.log(this.selectedFlow);
    this.operationInProgress = true;
    this.bundleFlows.forEach((element) => {
      this.selectedFlowIds.push((element as any).flow.rowKey);
    });

    console.log(this.selectedFlowIds);

    var paidFlow = this.bundleFlows.filter(f => f.flow.price != null)[0];

    console.log(paidFlow);

    var bundlePrice = this.bundleData.price;
    console.log(bundlePrice);


    if ((paidFlow != null || paidFlow != undefined) && bundlePrice == null) {
      this.selectedFlow = true;
      this.toastr.warning("Please add price to bundle to publish as you have selected paid flows to be part of this bundle.");
      return;
    }

    if ((paidFlow == null || paidFlow == undefined) && bundlePrice != null) {
      this.selectedFlow = true;
      this.toastr.warning("Please add paid flows to bundle to publish as you have added price to this bundle.");
      return;
    }


    var bundleModel = {
      userName: this.userData.userName,
      uniqueId: this.bundleData.uniqueId,
      description: this.bundleData.description,
      customerEmail: this.userData.emailAddress,
      title: this.bundleData.title,
      accessType: accessType,
      url: this.bundleData.url,
      type: "Bundle",
      bundleStatus: "Approved",
      flowIds: this.selectedFlowIds,
      rowKey: this.bundleData.rowKey,
      price: this.bundleData.price,
      isPaid: this.bundleData.isPaid,
      flowSupportingDocumentsHyperLinkModels:
        this.bundleData.flowSupportingDocumentsHyperLinkModels,
      flowSupportingDocumentsDocumentModels:
        this.bundleData.flowSupportingDocumentsDocumentModels,
      PartitionKey: this.bundleData.partitionKey,
    };

    this.progress = 0;
    if (this.userData != null) {
      if (this.bundleData.price != null) {
        this.bundleService
          .PostBundle(bundleModel, this.userData.emailAddress) 
          .subscribe({
            next: (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request sent to server.');
                  break;

                case HttpEventType.UploadProgress:
                  this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                  console.log(`Progress: ${this.progress}%`);
                  break;

                case HttpEventType.Response:
                  console.log('Upload complete:', event.body);   
                  localStorage.setItem("draftTabIndex", "1"); 
                  this.cdr.detectChanges();
                  
                  setTimeout(() => {
                    this.progress = 100;
                    console.log("set is loading");
                    this.noResultReturned = false;
                    this.toastr.success("Bundle successfully published"); 
                    this.router.navigate(['/user-profile']);
                    this.resetProgress();
                  }, 5000);


                  break;

                default:
                  console.log('Unhandled event:', event);
              }
            },
            error: (err) => {
              console.error('Error uploading:', err);
              this.resetProgress();
            }
          }); 
        console.log("Included paid flows also added price", this.bundleData.price);
      }
      else {
        if (this.bundleData.price == null || this.bundleData.price == undefined) {
          this.bundleService
            .PostBundle(bundleModel, this.userData.emailAddress)
            .subscribe({
              next: (event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    console.log('Request sent to server.');
                    break;
  
                  case HttpEventType.UploadProgress:
                    this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                    console.log(`Progress: ${this.progress}%`);
                    break;
  
                  case HttpEventType.Response:
                    console.log('Upload complete:', event.body);   
                    localStorage.setItem("draftTabIndex", "1"); 
                    this.cdr.detectChanges();
                    
                    setTimeout(() => {
                      this.progress = 100;
                      console.log("set is loading");
                      this.noResultReturned = false;
                      this.toastr.success("Bundle successfully published"); 
                      this.router.navigate(['/user-profile']);
                      this.resetProgress();
                    }, 5000);
  
  
                    break;
  
                  default:
                    console.log('Unhandled event:', event);
                }
              },
              error: (err) => {
                console.error('Error uploading:', err);
                this.resetProgress();
              }
            }); 
          //console.log("Free bundle publish", this.bundleData.price);
        } else {
          this.noResultReturned = false;
          this.showMessage = true;
          this.toastr.warning("Please add price to bundle to publish as you have selected paid flows to be part of this bundle.");
        }
      }
    }
  }

  resetProgress() {
    setTimeout(() => {
      this.progress = 0;
      this.isLoading = false;
    }, 500); // Small delay before hiding progress
  }

  uploadSupportingDocuments(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);

      // this.isStepCoverPageURISet = true;
      this.isAddSupportingDocument = true;
      if (
        this.fileData.name.includes("pdf") ||
        this.fileData.name.includes("PDF")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadDocumentsUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showImage = true;
            this.showVideo = false;
            this.noResultReturned = false;
            //
            if (mediaUploadStatus != null) {
              this.sffilename = mediaUploadStatus.fileName;
              this.sffilelink =
                environment.documentsStorageBaseUrl +
                mediaUploadStatus.fileName;
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }

  restrictedAccessPage(accessType) {
    var bundleModel = {
      uniqueId: this.bundleData.uniqueId,
      Description: this.bundleData.Description,
      CustomerEmail: this.userData.EmailAddress,
      Title: this.bundleData.Title,
      AccessType: accessType,
      Url: this.bundleData.Url,
      Type: "Bundle",
      BundleStatus: "Approved",
      FlowIds: this.selectedFlowIds,
      Id: this.bundleData.Id,
    };
    localStorage.setItem("flowModel", JSON.stringify(bundleModel));
    this.router.navigate(["/restrictedbundle"]);
  }

  flowClicked(flow) { }

  viewUserProfile(flow) { }

  addflowstoBundle() {
    var bundle = {
      Title: this.title,
      Description: this.description,
      Url: this.bundleImageUrl,
      Id: this.bundleData.Id,
      IsPaid: this.bundleData.IsPaid,
      Price: this.bundleData.Price,
      FlowSupportingDocumentsDocumentModels:
        this.bundleData.flowSupportingDocumentsDocumentModels,
      flowSupportingDocumentsHyperLinkModels:
        this.bundleData.flowSupportingDocumentsHyperLinkModels,
    };
    localStorage.setItem("naviagatedToAddFlowsFrom", "editbundle");
    localStorage.setItem(
      "createbundledetails",
      JSON.stringify(this.bundleData)
    );
    this.router.navigate(["/addFlowBundle"]);
  }

  // selectFiles(event, source) {
  //   if (event.target.files) {
  //     this.noResultReturned = true;
  //     //
  //     this.fileData = <File>event.target.files[0];
  //     const formData = new FormData();
  //     formData.append("fileData", this.fileData);

  //     // this.isStepCoverPageURISet = true;
  //     this.isAddSupportingDocument = true;
  //     if (
  //       this.fileData.name.includes("JPEG") ||
  //       this.fileData.name.includes("jpeg") ||
  //       this.fileData.name.includes("png") ||
  //       this.fileData.name.includes("jpg") ||
  //       this.fileData.name.includes("PNG") ||
  //       this.fileData.name.includes("JFIF") ||
  //       this.fileData.name.includes("jfif") ||
  //       this.fileData.name.includes("TIFF") ||
  //       this.fileData.name.includes("tiff") ||
  //       this.fileData.name.includes("TIF") ||
  //       this.fileData.name.includes("tif") ||
  //       this.fileData.name.includes("JPG") ||
  //       this.fileData.name.includes("pdf") ||
  //       this.fileData.name.includes("PDF")
  //     ) {
  //       this.http
  //         .post(
  //           environment.apiBaseUrl + environment.uploadImageUrl,
  //           formData
  //         )
  //         .subscribe((data) => {
  //           var mediaUploadStatus = data as any;
  //           this.showImage = true;
  //           this.showVideo = false;
  //           this.noResultReturned = false;

  //           if (mediaUploadStatus != null) {
  //             if (source == "cp") {
  //               this.fileName = this.fileData.name;
  //               this.bundleImageUrl =
  //                 environment.imageStorageBaseUrl +
  //                 mediaUploadStatus.fileName;
  //             } else if (source == "sd") {
  //               this.sffilelink = mediaUploadStatus.fileName;
  //             }
  //             if(this.bundleImageUrl !=null && this.bundleImageUrl !=undefined){
  //               this.bundleData.url=this.bundleImageUrl;
  //             }else{
  //               this.bundleData.url=this.sffilelink;
  //             } 
  //           } else {
  //             alert(mediaUploadStatus.message);
  //           }
  //         });
  //     } else if (
  //       this.fileData.name.includes("MP4") ||
  //       this.fileData.name.includes("mp4") ||
  //       this.fileData.name.includes("MOV") ||
  //       this.fileData.name.includes("mov") ||
  //       this.fileData.name.includes("WMV") ||
  //       this.fileData.name.includes("wmv") ||
  //       this.fileData.name.includes("FLV") ||
  //       this.fileData.name.includes("flv") ||
  //       this.fileData.name.includes("AVCHD") ||
  //       this.fileData.name.includes("avchd") ||
  //       this.fileData.name.includes("WEBM") ||
  //       this.fileData.name.includes("webm") ||
  //       this.fileData.name.includes("MKV") ||
  //       this.fileData.name.includes("mkv") ||
  //       this.fileData.name.includes("VOB") ||
  //       this.fileData.name.includes("vob") ||
  //       this.fileData.name.includes("OGV") ||
  //       this.fileData.name.includes("ogv") ||
  //       this.fileData.name.includes("GIFY") ||
  //       this.fileData.name.includes("gifv") ||
  //       this.fileData.name.includes("M4P") ||
  //       this.fileData.name.includes("m4p") ||
  //       this.fileData.name.includes("M4V") ||
  //       this.fileData.name.includes("m4v") ||
  //       this.fileData.name.includes("3GP") ||
  //       this.fileData.name.includes("3gp") ||
  //       this.fileData.name.includes("3G2") ||
  //       this.fileData.name.includes("3g2") ||
  //       this.fileData.name.includes("AMV") ||
  //       this.fileData.name.includes("amv") ||
  //       this.fileData.name.includes("NSV") ||
  //       this.fileData.name.includes("nsv") ||
  //       this.fileData.name.includes("OGG") ||
  //       this.fileData.name.includes("ogg") ||
  //       this.fileData.name.includes("FLV") ||
  //       this.fileData.name.includes("flv") ||
  //       this.fileData.name.includes("F4V") ||
  //       this.fileData.name.includes("f4v") ||
  //       this.fileData.name.includes("F4P") ||
  //       this.fileData.name.includes("f4p") ||
  //       this.fileData.name.includes("F4A") ||
  //       this.fileData.name.includes("f4a") ||
  //       this.fileData.name.includes("F4B") ||
  //       this.fileData.name.includes("f4b")
  //     ) {
  //       this.http
  //         .post(
  //           environment.apiBaseUrl + environment.uploadVideoUrl,
  //           formData
  //         )
  //         .subscribe((data) => {
  //           var mediaUploadStatus = data as any;
  //           this.showVideo = true;
  //           this.showImage = false;
  //           this.noResultReturned = false;
  //           if (mediaUploadStatus != null) {
  //             if (source == "cp") {
  //               this.fileName = this.fileData.name;
  //               this.bundleImageUrl =
  //                 environment.videoStorageBaseUrl +
  //                 mediaUploadStatus.fileName;
  //             } else if (source == "sd") {
  //               this.sffilelink = mediaUploadStatus.fileName;
  //             }

  //             if(this.bundleImageUrl !=null && this.bundleImageUrl !=undefined){
  //               this.bundleData.url=this.bundleImageUrl;
  //             }else{
  //               this.bundleData.url=this.sffilelink;
  //             } 
  //           } else {
  //             alert(mediaUploadStatus.message);
  //           }
  //         });
  //     }
  //   }

  //   console.log(this.bundleData);
  // }



  selectFiles(event: any) {
    if (event.target.files) {
      this.noResultReturned = true;
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      this.isLoading = true; // Add loading state

      // File extensions for images and videos
      const imageExtensions = ["jpeg", "png", "jpg", "jfif", "tiff", "tif"];
      const videoExtensions = [
        "mp4", "mov", "wmv", "flv", "avchd", "webm", "mkv", "vob", "ogv",
        "gifv", "m4p", "m4v", "3gp", "3g2", "amv", "nsv", "ogg", "f4v", "f4p", "f4a", "f4b"
      ];

      // Convert file extension to lowercase and check type
      const fileNameLower = this.fileData.name.toLowerCase();
      const isImage = imageExtensions.some(ext => fileNameLower.endsWith(ext));
      const isVideo = videoExtensions.some(ext => fileNameLower.endsWith(ext));

      // Set the upload URL
      const uploadUrl = isImage
        ? environment.apiBaseUrl + environment.uploadImageUrl
        : environment.apiBaseUrl + environment.uploadVideoUrl;

      this.http.post(uploadUrl, formData, { reportProgress: true, observe: 'events' })
        .subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total !== undefined) {
                  const progress = (event.loaded / event.total) * 100;
                  if (progress !== 100) {
                    this.progress = progress;
                  }
                }
                break;
              case HttpEventType.Response:
                this.handleFileUpload(event, isImage);
                break;
            }
          },
          (error) => {
            this.isLoading = false;
            this.noResultReturned = false;
            this.fileName = '';
            this.toastr.warning("Please upload files up to 80MB");
            console.error('An error occurred:', error);
          }
        );
    }
  }

  handleFileUpload(res: HttpResponse<any>, isImage: boolean) {
    this.isLoading = false;
    this.noResultReturned = false;
    const mediaUploadStatus = res.body;
    // this.sffilelink = mediaUploadStatus.fileName;

    if (mediaUploadStatus) {
      if (isImage) {
        this.showImage = true;
        this.showVideo = false;
        this.bundleImageUrl = environment.imageStorageBaseUrl + mediaUploadStatus.fileName;
      } else {
        this.showVideo = true;
        this.showImage = false;
        this.bundleImageUrl = environment.videoStorageBaseUrl + mediaUploadStatus.fileName;
        // this.posterUrl = environment.imageStorageBaseUrl + mediaUploadStatus.posterImageName;
      }
      this.toastr.success("File has been uploaded successfully.");
      this.progress = 100;
      if (this.bundleImageUrl != null && this.bundleImageUrl != undefined) {
        this.bundleData.url = this.bundleImageUrl;
      } else {
        this.bundleData.url = this.sffilelink;
      }
    } else {
      this.toastr.error("Upload failed.");
    }
  }


  deleteBundle(item, index) {
    this.addbundleselectedflows.splice(index, 1);
    localStorage.setItem(
      "addbundleselectedflows",
      JSON.stringify(this.addbundleselectedflows)
    );

    if (this.addbundleselectedflows.length >= 2) {
      this.bFlows = true;
    } else {
      this.bFlows = false;
    }
  }

  leave() {
    this.router.navigate(["/viewbundle/" + this.bundleData.rowKey]);
  }

  showImage: boolean;
  showBundleVideo: boolean;
  showCoverImage: boolean;
  showCoverVideo: boolean;

  isCoverAImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showCoverImage = true;
      this.showCoverVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showCoverVideo = true;
      this.showCoverImage = false;
    }
  }

  isImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showImage = true;
      this.showBundleVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showBundleVideo = true;
      this.showImage = false;
    }
  }

  deleteFlow(item, index) {
    this.bundleFlows.splice(index, 1);
    localStorage.setItem(
      "addbundleselectedflows",
      JSON.stringify(this.bundleFlows)
    );

    if (this.bundleFlows.length >= 2) {
      this.bFlows = true;
    } else {
      this.bFlows = false;
    }
  }

  editLink(index, link) {
    this.linkName = link.linkName;
    this.hyperLink = link.linkUrl;
    localStorage.setItem("editBundlesupportingDocumentHyperLinksIndex", index);
  }

  addLink() {
    var editsupportingDocumentHyperLinksIndex = localStorage.getItem(
      "editBundlesupportingDocumentHyperLinksIndex"
    );
    if (editsupportingDocumentHyperLinksIndex != null) {
      this.bundleData.flowSupportingDocumentsHyperLinkModels.splice(
        parseInt(editsupportingDocumentHyperLinksIndex),
        1
      );

      var slink = {
        linkName: this.linkName,
        linkUrl: this.hyperLink,
      };
      this.bundleData.flowSupportingDocumentsHyperLinkModels
        .splice(parseInt(editsupportingDocumentHyperLinksIndex), 0, slink);
    } else {

      this.bundleData.flowSupportingDocumentsHyperLinkModels.push({
        linkName: this.linkName,
        linkUrl: this.hyperLink,
      });
    }

    this.linkName = "";
    this.hyperLink = "";
    localStorage.setItem(
      "BundleSupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );
    localStorage.removeItem("editBundlesupportingDocumentHyperLinksIndex");
  }

  addSupportingDocument() {
    var editSupportingDocumentRequiredIndex = localStorage.getItem(
      "editBundleSupportingDocumentRequiredIndex"
    );

    if (editSupportingDocumentRequiredIndex != null) {
      this.bundleData.flowSupportingDocumentsDocumentModels.splice(
        parseInt(editSupportingDocumentRequiredIndex),
        1
      );

      var document = {
        documentName: this.documentName,
        documentUrl: this.sffilelink,
      };

      this.bundleData.flowSupportingDocumentsDocumentModels
        .splice(parseInt(editSupportingDocumentRequiredIndex), 0, document);
    }
    else {
      this.bundleData.flowSupportingDocumentsDocumentModels.push({
        documentName: this.documentName,
        documentUrl: this.sffilelink,
      });
    }
    this.documentName = "";
    this.sffilelink = "";

    localStorage.setItem(
      "BundleSupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    localStorage.removeItem("editBundleSupportingDocumentRequiredIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  editSupportingDocument(index, document) {
    this.documentName = document.documentName;
    this.sffilelink = document.documentUrl;
    localStorage.setItem("editBundleSupportingDocumentRequiredIndex", index);
  }

  tabChanged(tabChangedEvent) { }

  remove(index) {
    this.supportingDocumentDocuments.splice(index, 1);

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  removeLink(index) {
    this.supportingDocumentHyperLinks.splice(index, 1);
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );
  }

  originalTitleText!: string;
  repharaseButtonTitleText: string = "Rephrase text with AI";
  isTitleRephrased: boolean = false;
  isTitleRephraseInProgress: boolean = false;

  GoToOriginalTitleText() {
    this.bundleData.title = this.originalTitleText;
  }

  openRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.bundleData.title }  // Pass the text to the dialog
    });
    if (!this.isTitleRephrased) {
      this.originalTitleText = this.bundleData.title;
    }
    this.isTitleRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style
        this.bundleData.title = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isTitleRephrased = true;
        this.isTitleRephraseInProgress = false;
      }
    });
  }
  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;

  GoToOriginalText() {
    this.bundleData.description = this.originalContentTitleText;
  }

  openDescrptionRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.bundleData.description }  // Pass the text to the dialog
    });
    if (!this.isRephrased) {
      this.originalContentTitleText = this.bundleData.description;
      console.log(this.description);
    }
    this.isRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  
        this.bundleData.description = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
        this.isRephraseInProgress = false;
      }
    });
  }
}
