
import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DataService } from "src/app/Services/dataservice.service";
import { HttpClient, HttpEvent, HttpResponse } from "@angular/common/http";
import { HttpEventType } from "@angular/common/http";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { link } from "fs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { GetContentService } from "src/app/Services/getcontent.service";
import { log } from "console";
import { SelectionRephraseAIComponent } from "../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { GenerateKeyword } from "../generate-keyword/generate-keyword.component";
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.scss']
})
export class CreateBlogComponent implements OnInit {
  userData: any;
  keywords: string[] = [];
  keyword: string;
  showImage: boolean;
  noResultReturned!: boolean;
  showDefaultImage: boolean;
  profilePicture: any;
  email: any;
  phone: any;
  uname: any;
  name: any;
  posterURI: any;
  fileData: File;
  isLoading: boolean;
  progress: number;
  fileName: string;
  coverPageURI: string;
  sffilename: any; 
  currenttitlelenght = 0;
  currentdesclenght = 0;
  title: string; 
  imageURI: null;
  enableButton: boolean;
  duration: any;
  rowKey: any;
  partitionKey: any;
  authorName: any;
  isUploading: boolean;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  maxtitlelenght = 150;
  maxdesclenght = 500;
  @Input() description: string = '';

  originalTitleText!: string;
  repharaseButtonTitleText: string = "Rephrase text with AI";
  isTitleRephrased: boolean = false;
  isTitleRephraseInProgress: boolean = false;
  blogData: any;
  isBlogPublished!: boolean;

  
  constructor(
    private router: Router,
    private http: HttpClient,
    private userserviceService: UserserviceService,
    private cdr: ChangeDetectorRef,
    private toastManager: ToastrService,
    public dialog: MatDialog,
    private flowServiceService: FlowServiceService
  ) {
    this.title = "";
    this.description = "";
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
    this.noResultReturned = false;

    //
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.profilePicture = this.userData.profilePicUrl;
    this.authorName = this.userData.firstName;
    console.log(this.userData);
    this.titleChanged();

    this.blogData = JSON.parse(localStorage.getItem("selectedBlogContent")); 
    if(this.blogData){
      this.setBlogData(this.blogData);
    }
  }

  addKeyword(event: any): void {
    const input = event.input;
    const value = event.value;
    console.log(value);
    console.log(input);
    // const newKeyword = this.keyword.trim();
    if (value && !this.keywords.includes(value)) {
      this.keywords.push(value);
      // Clear input field
      if (input) {
        input.value = '';
      }
    }

  }

  updateInputField(selectedKeyword: string) {
    this.keyword = selectedKeyword; // Update input field when selecting from the list
  }

  removeKeyword(index: number) {
    this.keywords.splice(index, 1);
  }

  editKeyword(index: number, key: string) {
    this.keyword = key;
    this.keywords.splice(index, 1);
  }

  isImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showImage = true;
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  submit() {
    this.isBlogPublished = true;
    //
    var blogModel = {
      coverImage: this.coverPageURI,
      description: this.description,
      title: this.title,
      authorName: this.authorName,
      duration: this.duration ? String(this.duration) : "",
      partitionKey: this.userData.partitionKey,
      rowKey : this.blogData ? this.blogData.rowKey : '',
      hashtags: this.keywords.join(", ")
    };

    console.log(blogModel);

    if (this.userData) {
      this.isUploading = true;
      this.progress = 0;
      this.flowServiceService
        .publishBlog(blogModel, this.userData.emailAddress)
        .subscribe({
          next: (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('Request sent to server.');
                break;

              case HttpEventType.UploadProgress:
                this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                console.log(`Progress: ${this.progress}%`);
                break;

              case HttpEventType.Response:
                const result = event.body;
                console.log('Upload complete:', event.body); 
                localStorage.setItem('selectedBlogContent', JSON.stringify(result)); 
                this.cdr.detectChanges();

                setTimeout(() => {
                  this.progress = 100;
                  console.log("set is loading");
                  this.noResultReturned = false;
                  this.isBlogPublished = false;
                  this.toastManager.success("Blog successfully published");
                  this.router.navigate(['/blog/' + this.getTitle(result.title)]);
                  this.resetProgress();
                }, 1000);


                break;

              default:
                console.log('Unhandled event:', event);
            }
          },
          error: (err) => {
            console.error('Error uploading:', err);
            this.resetProgress();
          }
        });
    } else {
      localStorage.setItem("selectedBlogContent", JSON.stringify(blogModel));
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    }

    this.noResultReturned = false;
  }

  getTitle(title: any) {
    // Replace spaces with hyphens and convert to lowercase
    return title.replace(/\s+/g, '-');
  }

  resetProgress() {
    setTimeout(() => {
      this.progress = 0;
      this.isUploading = false;
    }, 500); // Small delay before hiding progress
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    }
  }
  removePicture() {
    this.profilePicture = null;
  }
  setPosterImage(fileData) {
    let url = fileData.replace("." + this.getExtention(fileData), ".jpg");
    this.posterURI = url;
  }

  getExtention(fileData) {
    return fileData.slice(((fileData.lastIndexOf(".") - 1) >>> 0) + 2);
  }



  openLink(url) {
    url = "https://" + url;
    window.open(url);
  }

  selectFiles(event: any, source: string) {
    if (event.target.files) {
      this.noResultReturned = true;
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.isLoading = true;

      // File type detection
      const imageExtensions = ["JPEG", "jpeg", "png", "jpg", "PNG", "JFIF", "jfif", "TIFF", "tiff", "TIF", "tif", "JPG", "pdf", "PDF"];
      const videoExtensions = ["MP4", "mp4", "MOV", "mov", "WMV", "wmv", "FLV", "flv", "AVCHD", "avchd", "WEBM", "webm",
        "MKV", "mkv", "VOB", "vob", "OGV", "ogv", "GIFY", "gifv", "M4P", "m4p", "M4V", "m4v",
        "3GP", "3gp", "3G2", "3g2", "AMV", "amv", "NSV", "nsv", "OGG", "ogg", "F4V", "f4v",
        "F4P", "f4p", "F4A", "f4a", "F4B", "f4b"];

      const isImageOrPdf = imageExtensions.some(ext => this.fileData.name.includes(ext));
      const isVideo = videoExtensions.some(ext => this.fileData.name.includes(ext));

      const uploadUrl = isImageOrPdf
        ? environment.apiBaseUrl + environment.uploadImageUrl
        : environment.apiBaseUrl + environment.uploadVideoUrl;

      this.http
        .post(uploadUrl, formData, {
          reportProgress: true,
          observe: 'events'
        })
        .subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total) {
                  this.progress = Math.round((event.loaded / event.total) * 100);
                }
                break;
              case HttpEventType.Response:
                this.handleFileUpload(event.body, isImageOrPdf, source);
                break;
            }
          },
          (error) => {
            this.isLoading = false;
            this.noResultReturned = false;
            this.fileName = '';
            this.toastManager.warning("Please upload files up to 500MB");
            console.error('Upload error:', error);
          }
        );
    }
  }

  handleFileUpload(response: any, isImageOrPdf: boolean, source: string) {
    this.isLoading = false;
    this.noResultReturned = false;
    const mediaUploadStatus = response;

    if (mediaUploadStatus) {
      if (isImageOrPdf) {
        this.showImage = true;
        if (source === "cp") {
          this.fileName = this.fileData.name;
          this.coverPageURI = environment.imageStorageBaseUrl + mediaUploadStatus.fileName;
        } else if (source === "sd") {
          this.sffilename = mediaUploadStatus.fileName;
        }
      }
      this.toastManager.success("File has been uploaded successfully.");
      this.progress = 100;
    } else {
      this.toastManager.error("Upload failed");
    }
  }

  titleChanged() {
    this.title = this.title.trim();

    if (this.title.length == this.maxtitlelenght) {
      this.title = this.title.substring(0, this.title.length);
    }

    if (this.currenttitlelenght <= this.maxtitlelenght)
      this.currenttitlelenght = this.title.length;

    this.setIsDisable();
  }

  setIsDisable() {
    if (
      this.currenttitlelenght > 0 &&
      this.currentdesclenght > 0 &&
      (this.imageURI != null)
    ) {
      this.enableButton = false;
    } else {
      this.enableButton = true;
    }
  }

  descChanged() {
    this.description = this.description.trim();
    if (this.description.length == this.maxdesclenght) {
      this.description = this.description.substring(0, this.description.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght)
      this.currentdesclenght = this.description.length;

    this.setIsDisable();
  }

  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;

  GoToOriginalText() {
    this.description = this.originalContentTitleText;
  }
  openDescrptionRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.description }  // Pass the text to the dialog
    });
    if (!this.isTitleRephrased) {
      this.originalContentTitleText = this.description;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isRephraseInProgress = true;
        // Update the title with the rephrased text and show the selected style 
        this.description = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
      }
    });
  } 

  GoToOriginalTitleText() {
    this.title = this.originalTitleText;
  }

  async rephraseTitleText(input: string) {

    if (input == undefined || input == "") {
      this.toastManager.warning(
        "The Text To Rephrase is required."
      );
      return;
    }
  }

  openRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.title }  // Pass the text to the dialog
    });
    if (!this.isTitleRephrased) {
      this.originalTitleText = this.title;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style
        this.isTitleRephraseInProgress = true;
        this.title = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isTitleRephrased = true;
      }
    });
  }
  openGetKeyWordsDialog() {
    const dialogRef = this.dialog.open(GenerateKeyword, {
      width: '400px',
      data: { textToRephrase: this.title, textDescrption: this.description }  // Pass the text to the dialog
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  

        if (result.predefinedKeywords.length > 0) {
          this.keywords = Array.from(new Set([...this.keywords, ...result.predefinedKeywords]));
        } else {
          this.toastManager.info("Keyword generation failed. Please provide more specific data in the title and description.");
          this.keywords = []; // Ensures predefinedKeywords is always an array
        }
      }
    });
  }

  setBlogData(blogData) {
    console.log(blogData.hashtags);
    this.coverPageURI = blogData.coverImage,
      this.description = blogData.description,
      this.title = blogData.title,
      this.authorName = blogData.authorName,
      this.duration = parseInt(blogData.duration, 10),
      this.partitionKey = blogData.partitionKey,
      this.keywords = blogData.hashtags.split(',').map(tag => tag.trim());
      this.showImage = true;
      console.log(this.keywords);
  }
}  
