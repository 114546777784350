import { WelcomedialogComponent } from "./../welcomedialog/welcomedialog.component";
import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { FlowServiceService } from "../../Services/flow-service.service";
import { Router, NavigationExtras } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";

import { UserserviceService } from "src/app/Services/userservice.service";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
// import * as firebase from "firebase";
import { sample } from "rxjs/operators";
import { sampleAnimations } from "./sample.animations";
import { Location } from "@angular/common";
import { BundledialogComponent } from "../bundledialog/bundledialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { Console } from "console";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-sample",
  templateUrl: "./sample.component.html",
  styleUrls: ["./sample.component.scss"],
  animations: sampleAnimations,
})
export class SampleComponent implements OnInit {

  userDisplayEmail: any;
  userDisplayName: any;
  userData: any;
  userEmail: any;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  carouselOptions: any;
  profilePicture: string = "";
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  showAnyUserProfilePic: boolean;
  flowData: any;
  showNotificationCount: boolean;
  notificationCount: number;
  isMobileView: boolean;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any = [];
  flows: any = [];
  notificationscount: number;
  noResultReturned: boolean;
  account_circle!: string;
  // CarouselOptions = { items: 3, dots: true, nav: true };

  categories: any[] = [];
  showDefaultImage: boolean;
  getNotificationUrl: string;
  isItBundle: boolean;
  bundleIcon: any;

  constructor(
    private flowServiceService: FlowServiceService,
    private router: Router,
    private dataService: DataService,

    private userserviceService: UserserviceService,
    private eventEmitterService: EventEmitterService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private location: Location,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.getNotificationUrl = "v1/NotificationController/GetUserNotifications";
    this.categories = [];
    this.notificationsarray = null;
    this.getScreenSize();
    this.userDisplayEmail = "";
    this.eventEmitterService.callEvent();
    this.eventEmitterService.setHomePageIconColorEvent("home");

    if (this.eventEmitterService.setProfilePictureCompleted === undefined) {
      this.eventEmitterService.setProfilePictureCompleted =
        this.eventEmitterService.setProfilePictureFunction.subscribe(
          (profilePicture: string) => {
            this.showDefaultImage = false;
            this.profilePicture = profilePicture;
          }
        );
    }

    this.titleandmetaserviceService.setTitle("Home Page");

  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
    this.flows = [];
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    this.eventEmitterService.setNotificationCompleted =
      this.eventEmitterService.setNotificationFunction.subscribe(
        (notificationModelArray: any) => {
          this.notificationsarray = [];
          this.showNotificationCount = true;
          const notifications = JSON.parse(notificationModelArray);
          notifications.forEach((element) => {
            this.notificationsarray.push(element.notification);
          });
          this.notificationscount = this.notificationsarray.length;
          this.ref.detectChanges();
        }
      );
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.userDisplayName = this.uname = this.userData.UserName;
      this.name = this.userData.firstName;
      this.getProfileUser();
      this.getnotification();
    }
    //else {
    //   this.getProfileUser();
    // }

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    //
    this.noResultReturned = true;
    this.getFlows();
    var dontshowagain = localStorage.getItem("dontshowagain");
    if (dontshowagain == null && dontshowagain == undefined) {
      this.welcomeDialog();
    }
    //
    // this.getProfileUser();
  }

  initialize() { }

  checkLoggedIn() {
    if (this.userData === null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    } else {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");

      localStorage.setItem("navigatebackfromcreateflow", this.location.path());
      localStorage.setItem(
        "navigatebackfromcreatebundle",
        this.location.path()
      );
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    }
  }

  onScroll() {
    // this.delay(2000).then(() => { 
    //   this.delayedServiceCall();
    // });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem("pageIndex");
    pageIndex = (parseInt(pageIndex) + 2).toString();
    localStorage.setItem("pageIndex", pageIndex);

    this.flowServiceService
      .getFlows(this.userDisplayEmail, pageIndex)
      .subscribe((data) => {
        if (data.flows.length == 0) {
          this.noResultReturned = false;
        } else {
          this.noResultReturned = true;

          data.flows.forEach((element) => {
            this.categories.push(element);
          });

          localStorage.setItem(
            "allcategoryflowsforviewprofilepage",
            JSON.stringify(this.categories)
          );
        }


      });
  }

  notification() {
    this.router.navigate(["/notifications"]);
  }

  getFlows(): void {
    this.noResultReturned = true;
    localStorage.removeItem("pageIndex");
    localStorage.setItem("pageIndex", "1");
    this.flowServiceService
      .getFlows(this.userDisplayEmail, "0")
      .subscribe((data) => {
        this.noResultReturned = false;
        this.categories = data.Flows;
        this.titleandmetaserviceService.setTags(data.Flows);
        localStorage.setItem(
          "allcategoryflowsforviewprofilepage",
          JSON.stringify(this.categories)
        );
      });
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize();
  }

  resize() {
    this.carouselOptions = {
      margin: 20,
      nav: true,
      dots: false,
      pagination: false,
      responsiveClass: true,
      loop: false,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        300: {
          items: 2,
          nav: true,
        },
        400: {
          items: 2,
          nav: true,
        },
        550: {
          items: 2,
          nav: true,
        },
        600: {
          items: 2.5,
          nav: true,
        },
        700: {
          items: 3,
          nav: true,
        },
        750: {
          items: 4,
          nav: true,
        },
        800: {
          items: 4,
          nav: true,
        },
        900: {
          items: 5,
          nav: true,
        },
        1000: {
          items: 5,
          nav: true,
          loop: false,
        },

        1100: {
          items: 6,
          nav: true,
          loop: false,
        },

        1200: {
          items: 6,
          nav: true,
          loop: false,
        },
        1300: {
          items: 6,
          nav: true,
          loop: false,
        },
        1400: {
          items: 6,
          nav: true,
          loop: false,
        },
        1500: {
          items: 6,
          nav: true,
          loop: false,
        },
      },
    };
  }

  onResize(event) {
    this.resize();

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  flowByCategory(itemCategory) {
    localStorage.setItem(
      "flowByCategoryCategoryName",
      itemCategory.categoryName
    );
    this.dataService.flowByCategorieschangeMessage(itemCategory.flowModels);
    this.router.navigate(["/flowbycategory"]);
  }

  viewUserProfile(selectedFlow) {
    const userFlows = [];
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedFlow.customerEmail) {
        userFlows.push(selectedFlow);
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
     // localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/home']); 
    }
  }

  createFlow() {
    localStorage.setItem("navigatebackfromcreateflow", this.location.path());
    localStorage.setItem("navigatebackfromcreatebundle", this.location.path());
    this.router.navigate(["/create-flow"]);
  }

  avatarClicked(notification: any, source: any) {
    if (source == "flow clicked") {
      if (notification.contentType == "Flow") {
        localStorage.setItem("notificationflowId", notification.contentId);
        localStorage.setItem("flow", null);
        this.router.navigate([`/guide/${this.generateUrlSlug(notification.contentTitle)}/${notification.contentId}`]); 
      } else if (notification.contentType == "Bundle") {
        this.router.navigate(["/viewbundle/" + notification.rowKey]);
      }
    } else if (source == "profile clicked") {
      if (this.userData.emailAddress === notification.notifierUserEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }

      this.dataService.flowByCategorieschangeMessage(notification);
    }
  }

  generateUrlSlug(html) {
    if (!html) return ''; 
    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }


  search() {
    this.router.navigate(["/"]);
  }

  flowClicked(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "/");
    if (flow.Type == "Bundle") {
      //this.isItBundle = true;
      this.router.navigate(["/viewbundle/" + flow.Id]);
    } else {
      //this.isItBundle = false;
      this.router.navigate([`/guide/${this.generateUrlSlug(flow.title)}/${flow.Id}`]); 
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    // this.router.navigate(['/login'], {
    //     queryParams: { returnUrl: this.router.url }
    //   }); 
    localStorage.setItem("returnUrl", this.router.url);
    this.router.navigate(['/registration']); 
  }

  getProfileUser(): void {
    // 
    this.showNotificationCount = true;
    this.userserviceService
      .getProfileUser()
      .subscribe((data) => {
        if (
          data.UserData.ProfilePicUrl === "" ||
          data.UserData.ProfilePicUrl == null
        ) {
          this.showDefaultImage = true;
        } else {
          this.showDefaultImage = false;
          this.profilePicture = data.UserData.ProfilePicUrl;
        }
        this.email = data.UserData.EmailAddress;
        this.phone = data.UserData.PhoneNumber;
        this.info = data.UserData.Info;
        this.website = data.UserData.Website;
        this.userDisplayName = this.uname = data.UserData.UserName;
        this.name = data.UserData.FirstName;
        //  
        this.getnotification();
      });
  }

  showImage(itemCategory, item, index) {
    itemCategory.FlowModels.forEach((element) => {
      if (item.ProfilePic !== null) {
        this.showAnyUserProfilePic = false;
      } else {
        this.showAnyUserProfilePic = true;
      }
      if (element.arrow !== undefined) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.Url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }

  getnotification() {
    this.notificationsarray = [];
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: 0,
    });
    
    this.flowServiceService
      .getNotifications(flowQueryModel)
      .subscribe((data) => {
        if (data != null) {
          data.forEach((element) => {
            var notification = {
              notification: {
                ProfilePicUrl: element.profilePicUrl,
                NotifierUserName: element.notifierUserName,
                Message: element.message,
                FlowUrl: element.flowUrl,
                FlowId: element.contentId,
                ContentId: element.contentId,
                ContentTitle: element.contentTitle,
                ContentType: element.contentType,
                NotifierUserEmail: element.notifierUserEmail,
                VideoFlowPosterImageUrl: element.videoFlowPosterImageUrl,
              },
            };
            this.notificationsarray.push(notification);
          });
        }
      });

      console.log(this.notificationsarray);
  }

  openDialog() {
    if (this.userData != null) {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "bundle") {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.router.navigate(["/createbundle"]);
        } else if (result === "flow"){
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.router.navigate(["/create-flow"]);
        }
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    }
  }

  welcomeDialog() {
    this.dialog.open(WelcomedialogComponent);
  }
}
