import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GetContentService } from 'src/app/Services/getcontent.service';
import { testcmsconfig, prodcmsconfig } from 'src/app/shared/cms/cmsconfig';
import { ContentQueryModel } from 'src/app/shared/cms/contentquerymodel';
import { ContentTypes } from 'src/app/shared/enums/content.types';
import { BundledialogComponent } from '../bundledialog/bundledialog.component';
import { Location } from "@angular/common";
import { EventEmitterService } from 'src/app/Services/event-emitter.service';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  blogs: any = [];
  noResultReturned: boolean;
  noAllResultReturned: boolean = false;
  loadMore: boolean;
  pageSize: any;
  coverImageUrl: any;
  timeToRead: any;
  isLoading!: boolean;
  contents: any = [];
  filteredBlogs: any = [];
  add_circle_outlinebgColor: string;
  add_circle_outlineSize: string;
  userData: any;
  add_circle_outlineColor: string;
  selectedFilter: string = 'all'; // Default to "All Blogs"
  noBlogFoundMessage: string;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _cdf: ChangeDetectorRef,
    private flowService: FlowServiceService,
    public location: Location,
    private eventEmitterService: EventEmitterService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.pageSize = 12;
    this.noResultReturned = false;
    this.loadMore = false;
  }
  
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.getBlogs();
    this.eventEmitterService.setUserProfileCreateIconClickedEvent("emoji_objects");
    localStorage.setItem('selectedBlogContent',null);
  }

  loadMoreTips() { }
  onScrollDown() { }

  async getBlogs() {
    this.noResultReturned = true;

    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.Blogs,
    });

    (await this.flowService.getAllBlogs()).subscribe((data) => {
      this.contents = this.filteredBlogs = data;
      this.noResultReturned = false;
    });

    this.isLoading = false;
  }

  setLoadMore(tips: any) {
    this.loadMore = !(tips.length < this.pageSize.Value);
  }

  onTabChanged(event: any) { }
  onRightClick(event: any) { }
  getTitle(title: any) {
    // Replace spaces with hyphens and convert to lowercase
    return title.replace(/\s+/g, '-');
  }
  viewblog(blog: any) {
    localStorage.setItem('selectedBlogContent', JSON.stringify(blog));
    this.router.navigate(['/blog/' + this.getTitle(blog.title)]);
  }

  openDialog() {
    if (this.userData != null) {
      this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
      this.add_circle_outlinebgColor = "white";
      this.add_circle_outlineColor = "#5ea1d8 !important";
      this.add_circle_outlineSize = "25px !important";
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {

        if (result === "bundle") {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.router.navigate(["/createbundle"]);
        } else if (result === "flow") {
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.router.navigate(["/create-flow"]);
        } else {
          this.eventEmitterService.setUserProfileCreateIconClickedEvent("emoji_objects");
          window.location.reload();
        }
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  goToBlog() {
    if (this.userData != null) {
      localStorage.setItem('selectedBlogContent',null);
      this.router.navigate(["/blog"]);
    } else {
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }
  filterBlogs() {
    if (this.userData) {
      if (this.selectedFilter === 'myBlogs') {
        var flowQueryModel = new FlowQueryModel({
          userEmail: this.userData.emailAddress
        });
        this.filteredBlogs = [];
        this.flowService
          .getAllUserBlogs(flowQueryModel)
          .subscribe((data) => {
            this.noResultReturned = false;
            this.filteredBlogs = data || [];

            this.noBlogFoundMessage = data.length === 0
              ? "You haven't written any blogs yet. Start your first one now! 😊"
              : "";
          });
      } else {
        this.filteredBlogs = [...this.contents]; // Show all blogs
      }
    } else {
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }
}
