import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { partition } from 'rxjs';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { DataService } from "src/app/Services/dataservice.service";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SelectionRephraseAIComponent } from '../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent implements OnInit {
  @Input() flowCoverPage: any;
  groupName: string = '';
  question: string = '';
  answer: string = '';
  groups: any[];
  flows: any;
  userData: any;
  userDisplayName: any;
  noResultReturned: boolean;
  faqData: any;
  isLoading!: boolean;
  editFaq: any;
  groupList: string[] = []; // List of options
  // flowCoverPage: any;
  groupedData: Record<string, any[]> = {};
  rowKey: any;

  constructor(private flowServiceService: FlowServiceService,
    private toastr: ToastrService,
    private dataService: DataService,
    private router: Router,
    private dialog: MatDialog,
  ) { }
  async ngOnInit(): Promise<void> {
    this.flowCoverPage = JSON.parse(
      localStorage.getItem("flowCoverPageDetails")
    );
    var editFaq = localStorage.getItem("stepToFaq");
    this.groups = JSON.parse(localStorage.getItem("SupportFaqs"));
    console.log(editFaq);
    if (editFaq) {
      this.editGroup(editFaq,this.groups[editFaq]);
      this.groupDataByGroupName();
      this.getAllGroupName();
    }else{
      this.getSupportFaqs(); 
    }

  }

  drop(event: CdkDragDrop<any[]>) {
    if (!event.item.data) return;

    const previousGroup = event.previousContainer.id;
    const newGroup = event.container.id;
    const item = event.item.data;

    if (previousGroup !== newGroup) {
      // Remove item from old group
      this.groupedData[previousGroup] = this.groupedData[previousGroup].filter(
        (i) => i !== item
      );

      // Update the item with new group information (optional)
      item.groupName = newGroup;

      // Add item to new group
      if (!this.groupedData[newGroup]) {
        this.groupedData[newGroup] = [];
      }
      this.groupedData[newGroup].push(item);
    } else {
      // Reorder within the same group
      moveItemInArray(
        this.groupedData[newGroup],
        event.previousIndex,
        event.currentIndex
      );
    }

    console.log(this.groups);
  }


  getAllDropListIds(): string[] {
    return Object.keys(this.groupedData);
  }

  groupDataByGroupName() {
    if (!this.groups || this.groups.length === 0) {
      this.groupedData = {}; // Ensure no errors on empty or undefined data
      return;
    }

    this.groupedData = this.groups.reduce((acc, item) => {
      const group = item.groupName || "Others"; // Default group if missing
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {} as Record<string, any[]>);

    console.log(this.groupedData);
    this.getAllGroupName();
  }


  // Add a new group to the list and call the API
  addGroup() {
    var editFaqIndex = localStorage.getItem("editFaqIndex");

    if (editFaqIndex != null) {
      this.groups.splice(parseInt(editFaqIndex), 1);
      var item = {
        groupName: this.groupName,
        question: this.question,
        answer: this.answer,
        partitionKey: this.flowCoverPage.rowKey,
        userEmail: this.flowCoverPage.partitionKey,
        rowKey: this.rowKey
      };

      this.groups.splice(parseInt(editFaqIndex), 0, item);
    } else {
      this.groups.push({
        groupName: this.groupName,
        question: this.question,
        answer: this.answer,
        partitionKey: this.flowCoverPage.rowKey,
        userEmail: this.flowCoverPage.partitionKey
      });
    }
    if (this.groupName && !this.groupList.includes(this.groupName.trim())) {
      this.groupList.push(this.groupName.trim()); // Add only if it's unique
    }
    this.groupDataByGroupName();

    // localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    localStorage.removeItem("editFaqIndex");

    this.groupName = '';
    this.question = '';
    this.answer = ''; 
  }

  // Save the FAQ group using the API
  saveFaq(group: any) {
    this.isLoading = true;
    this.flowServiceService
      .addSupportFaq(group)
      .subscribe((data) => {
        if (data != null) {
          this.faqData = data;
          this.toastr.success('All FAQs saved successfully!');
          this.isLoading = false;
          this.router.navigate(["/create-flow"]);
        } else {
          this.toastr.error('Faqs saving failed. Please try again!');
        }
      });
  }

  getAllGroupName() {
    if (this.groups && this.groups.length > 0) { // Ensure groups exist
      this.groups.forEach(element => {
        console.log(element);

        const trimmedGroup = element.groupName.trim();
        if (trimmedGroup && !this.groupList.includes(trimmedGroup)) {
          this.groupList.push(trimmedGroup); // Add only if unique
        }
      });
    }
    console.log(this.groupList);
  }


  getSupportFaqs() {
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.flowCoverPage.rowKey,
    });
    this.flowServiceService.getSupportFaq(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      console.log(data);
      this.groups = [];
      data.forEach((element: any) => {
        if (element.isValid) {
          this.groups.push({
            groupName: element.groupName,
            answer: element.answer,
            question: element.question,
            partitionKey: element.partitionKey,
            flowRowKey: element.partitionKey,
            userEmail: element.userEmail,
            rowKey: element.rowKey
          });
        }
      });
      console.log(this.groups);
      this.groupDataByGroupName();
      this.getAllGroupName();
    });
  }
  // Remove a group from the list
  removeGroup(index: number, group: any) {
    console.log(group);
    var model = {
      partitionKey: group.flowRowKey,
      rowKey: group.rowKey,
      groupName: group.groupName,
      answer: group.answer,
      question: group.question,
      userEmail: group.userEmail
    };
    this.flowServiceService
      .deleteSupportFaq(model)
      .subscribe((data) => {
        console.log(data);
        if (data) {
          this.faqData = data;
          this.toastr.success('All FAQs saved successfully!');
          this.groups.splice(index, 1);
          console.log(this.groups);
        } else {
          this.toastr.error('Faqs deletion failed. Please try again!');
        }
      });
  }

  // Edit an existing group
  editGroup(index,item) {
    var group = this.groups[this.getGlobalIndexForFaq(item)];
    console.log(group);
    this.groupName = group.groupName;
    this.question = group.question;
    this.answer = group.answer;
    this.rowKey = group.rowKey
    // this.groups.splice(index, 1);
    // this.removeGroup(index,group); // Remove the old entry while editing
    localStorage.setItem("editFaqIndex", index);
  }

    // Efficiently get the index using flatMap
    getGlobalIndexForFaq(item: any): number { 
      return this.groups.findIndex(i =>
        i.rowKey === item.rowKey);
    } 
  // Done with adding all groups
  async done() {
    console.log(this.groups);
    await this.saveFaq(this.groups);
  }

  close() {
    localStorage.setItem("FaqData", JSON.stringify(this.faqData));
    this.router.navigate(["/create-flow"]);
  }

  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;

  GoToOriginalText() {
    this.answer = this.originalContentTitleText;
  }

  rephraseDescriptionText() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.answer }  // Pass the text to the dialog
    });
    if (!this.isRephrased) {
      this.originalContentTitleText = this.answer;
      console.log(this.answer);
    }
    this.isRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  
        this.answer = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
        this.isRephraseInProgress = false;
      }
    });
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(
  //     this.groups,
  //     event.previousIndex,
  //     event.currentIndex
  //   );
  // }

  onSelectGroup(event: MatAutocompleteSelectedEvent) {
    this.groupName = event.option.value; // Update the input with the selected value
  }
}