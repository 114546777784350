import { ValidationComponent } from "./pages/validation/validation.component";
import { CaptureImageComponent } from "./pages/capture-image/capture-image.component";
import { ToastrModule } from "ngx-toastr";
import { MatRadioModule } from "@angular/material/radio";
import { NgModule } from "@angular/core";
 
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SampleComponent } from "./pages/sample/sample.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";

// import { AngularFireDatabaseModule } from "@angular/fire/database";
// import { AngularFireAuthModule } from "@angular/fire/auth";
// import { AngularFireModule } from "@angular/fire";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { environment } from "../environments/environment";

// import {
//   TranslateService,
//   TranslateModule,
//   TranslateLoader,
// } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { EmployeeComponent } from "./pages/employee/employee.component";
import { LocalDatePipe } from "./pages/pipes/local-date.pipe";
import { LocalNumberPipe } from "./pages/pipes/local-number.pipe";
import { CurrencyPipe } from "@angular/common";
import { LoginComponent } from "./pages/login/login.component";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { ViewflowComponent } from "./pages/viewflow/viewflow.component";
import { DataService } from "./Services/dataservice.service";
import { OwlModule } from "ngx-owl-carousel";
import { TermsandconditionsComponent } from "./termsandconditions/termsandconditions.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { ViewbyidComponent } from "./pages/viewbyid/viewbyid.component";
import { LimitToPipe } from "./limit-to.pipe";
import { FlowByCategoryComponent } from "./pages/flow-by-category/flow-by-category.component";
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { PreviewPageComponent } from "./pages/preview-page/preview-page.component";
import { ForgetPasswordComponent } from "./pages/forget-password/forget-password.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ViewProfileComponent } from "./pages/view-profile/view-profile.component";
import { CreateFlowComponent } from "./pages/create-flow/create-flow.component";
import { QRCodeComponent } from "./pages/qrcode/qrcode.component";
import { CommentComponent } from "./pages/comment/comment.component";
import { MatListModule } from "@angular/material/list";
import { EditCommentComponent } from "./pages/edit-comment/edit-comment.component";
import { ChangeOldPasswordComponent } from "./pages/change-old-password/change-old-password.component";
import { CoverPageComponent } from "./pages/cover-page/cover-page.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { StepsComponent } from "./pages/steps/steps.component";
import { ItemsComponent } from "./pages/items/items.component";
import { YesnodialogComponent } from "./pages/yesnodialog/yesnodialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatBadgeModule } from "@angular/material/badge";
import { MatGridListModule } from "@angular/material/grid-list";
import { NotificationsComponent } from "./pages/notifications/notifications.component";
import { CarouselComponent } from "./pages/carousel/carousel.component";
import { NgQrScannerModule} from "angular2-qrscanner";
// import * as firebase from "firebase";
import { DialogExampleComponent } from "./dialog-example/dialog-example.component";
import { SearchComponent } from "./pages/search/search.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { BulbGuideComponent } from "./pages/bulb-guide/bulb-guide.component";
import { InAppropriateComponent } from "./pages/in-appropriate/in-appropriate.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { ImageDialogComponent } from "./pages/image-dialog/image-dialog.component";
// import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { UserMentionComponent } from "./pages/user-mention/user-mention.component";
import { PublishDialogComponent } from "./pages/publish-dialog/publish-dialog.component";
import { FollowersComponent } from "./pages/followers/followers.component";
import { FollowingComponent } from "./pages/following/following.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CancalFlowDialogComponent } from "./pages/cancal-flow-dialog/cancal-flow-dialog.component";
import { RestrictedAccessComponent } from "./pages/restricted-access/restricted-access.component";
import { EditFlowComponent } from "./pages/edit-flow/edit-flow.component";
import { UserProfileQrCodeComponent } from "./pages/user-profile-qr-code/user-profile-qr-code.component";
import { ShowFlowImageDialogComponent } from "./pages/show-flow-image-dialog/show-flow-image-dialog.component";
 
import { ScrollSpyDirective } from "./shared/ScrollSpyDirective";
import { BundledialogComponent } from "./pages/bundledialog/bundledialog.component";
import { CreatebundleComponent } from "./pages/createbundle/createbundle.component";
import { AddflowsinBundleComponent } from "./pages/addflowsin-bundle/addflowsin-bundle.component";
import { ViewBundleComponent } from "./pages/view-bundle/view-bundle.component";
import { RestrictedforbundleComponent } from "./pages/restrictedforbundle/restrictedforbundle.component";
import { EditBundleComponent } from "./pages/edit-bundle/edit-bundle.component";
import { DeleteBundleDialogComponent } from "./pages/delete-bundle-dialog/delete-bundle-dialog.component";
import { ReportBundleComponent } from "./pages/report-bundle/report-bundle.component";
import { BundleqrcodeComponent } from "./pages/bundleqrcode/bundleqrcode.component";
import { WelcomedialogComponent } from "./pages/welcomedialog/welcomedialog.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ThirdpartyliabrariesComponent } from "./pages/thirdpartyliabraries/thirdpartyliabraries.component";
import { ActivateaccountComponent } from "./pages/activateaccount/activateaccount.component";
 
import { ArticleComponent } from "./pages/article/article.component";
import { ShowArticleDialogComponent } from "./pages/show-article-dialog/show-article-dialog.component";
import { AuthInterceptor } from "./auth.interceptor";
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { BaddressComponent } from './pages/baddress/baddress.component';
import { VouchercodeComponent } from './pages/vouchercode/vouchercode.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { EmbededComponent } from './pages/embeded/embeded.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { EmbedforbundlesComponent } from './pages/embedforbundles/embedforbundles.component';
import { SupportDocumentComponent } from './pages/support-document/support-document.component';
import { ViewUserProfileQrCodeComponent } from './pages/view-user-profile-qr-code/view-user-profile-qr-code.component';
import { AnalyticsComponent } from './pages/analyics/analytics.component';
import { EditItemComponent } from './pages/edit-item/edit-item.component';
import { CouponCodeComponent } from './pages/coupon-code/coupon-code.component';
import { ContentAnalyticsComponent } from './pages/content-analytics/content-analytics.component';
import { PreviewAuthorComponent } from "./pages/blogs/blogpages/author/author.component";
import { PreviewBlogComponent } from "./pages/blogs/blog/blog.component";
import { PreviewBulletComponent } from "./pages/blogs/blogpages/bullet/bullet.component";
import { PreviewFormattedComponentComponent } from "./pages/blogs/blogpages/formatted-component/formatted-component.component";
import { PreviewImagesComponent } from "./pages/blogs/blogpages/images/images.component";
import { PreviewParagraphComponent } from "./pages/blogs/blogpages/paragraph/paragraph.component";
import { BlogsComponent } from "./pages/blogs/blogs.component";
import { PricingplanComponent } from "./pages/pricingplan/pricingplan.component";
import { ExcludeTranslationDirective } from "./shared/excludeTranslationDirective";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { QrCodeModule } from 'ng-qrcode';
import { QuillModule } from 'ngx-quill';
import { DeleteUserDialogComponent } from "./pages/delete-user-dialog/delete-user-dialog.component";
import { SelectionRephraseAIComponent } from "./pages/selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";
import { FaqComponent } from './pages/faq/faq.component'; 
import { SupportFaqComponent } from "./pages/support-faqs/support-faqs.component";
import { CheckoutNavigatorComponent } from './pages/checkout-navigator/checkout-navigator.component';
import { DonationPopupComponent } from './pages/donation-popup/donation-popup.component';
import { GiftedfundsComponent } from './pages/giftedfunds/giftedfunds.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ConsultationsComponent } from './pages/consultations/consultations.component';
import { BookconsultationsComponent } from './pages/bookconsultations/bookconsultations.component';
import { ScheduleconsultationsComponent } from './pages/scheduleconsultations/scheduleconsultations.component';
import { MeetLinkDialogComponent } from './pages/meet-link-dialog/meet-link-dialog.component';
import { FeedbackformsComponent } from './pages/feedbackforms/feedbackforms.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { BrowserModule } from "@angular/platform-browser";
 
export const firebaseConfig = {
  apiKey: "AIzaSyCf5kewVEMujarBhU1tWFmuzPNvzLQAuT8",
  authDomain: ".firebaseapp.com",
  databaseURL: "https://.firebaseio.com",
  storageBucket: ".appspot.com",
  messagingSenderId: "",
};

// firebase.initializeApp(firebaseConfig);
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
    declarations: [
        AppComponent,
        SampleComponent,
        BulbGuideComponent,
        EmployeeComponent,
        SearchComponent,
        LocalDatePipe,
        LocalNumberPipe,
        InAppropriateComponent,
        LoginComponent,
        RegistrationComponent,
        ViewflowComponent,
        TermsandconditionsComponent,
        PrivacypolicyComponent,
        ViewbyidComponent,
        LimitToPipe,
        FlowByCategoryComponent,
        UserProfileComponent,
        EditProfileComponent,
        PreviewPageComponent,
        ForgetPasswordComponent,
        ChangePasswordComponent,
        ViewProfileComponent,
        AboutUsComponent,
        CreateFlowComponent,
        QRCodeComponent,
        CommentComponent,
        EditCommentComponent,
        ChangeOldPasswordComponent,
        CoverPageComponent,
        CategoriesComponent,
        CaptureImageComponent,
        ImageDialogComponent,
        StepsComponent,
        ItemsComponent,
        YesnodialogComponent,
        NotificationsComponent,
        CarouselComponent,
        DialogExampleComponent,
        SearchComponent,
        BulbGuideComponent,
        InAppropriateComponent,
        AboutUsComponent,
        ImageDialogComponent,
        ValidationComponent,
        UserMentionComponent,
        PublishDialogComponent,
        FollowersComponent,
        FollowingComponent,
        CancalFlowDialogComponent,
        RestrictedAccessComponent,
        EditFlowComponent,
        UserProfileQrCodeComponent,
        ShowFlowImageDialogComponent,
        ScrollSpyDirective,
        BundledialogComponent,
        CreatebundleComponent,
        AddflowsinBundleComponent,
        ViewBundleComponent,
        RestrictedforbundleComponent,
        EditBundleComponent,
        DeleteBundleDialogComponent,
        ReportBundleComponent,
        BundleqrcodeComponent,
        WelcomedialogComponent,
        ThirdpartyliabrariesComponent,
        ActivateaccountComponent,
        ArticleComponent,
        ShowArticleDialogComponent,
        NotFoundComponent,
        CheckoutComponent,
        BaddressComponent,
        VouchercodeComponent,
        UserDashboardComponent,
        AdminDashboardComponent,
        EmbededComponent,
        EmbedforbundlesComponent,
        SupportDocumentComponent,
        SupportFaqComponent,
        ViewUserProfileQrCodeComponent,
        AnalyticsComponent,
        EditItemComponent,
        CouponCodeComponent,
        ContentAnalyticsComponent,
        PricingplanComponent,
        BlogsComponent,
        PreviewAuthorComponent,
        PreviewBlogComponent,
        PreviewBulletComponent,
        PreviewParagraphComponent,
        PreviewImagesComponent,
        PreviewFormattedComponentComponent,
        ExcludeTranslationDirective,
        DeleteUserDialogComponent,
        SelectionRephraseAIComponent,
        FaqComponent,
        CheckoutNavigatorComponent,
        DonationPopupComponent,
        GiftedfundsComponent, 
        SettingsComponent, 
        ConsultationsComponent, 
        BookconsultationsComponent, 
        ScheduleconsultationsComponent, MeetLinkDialogComponent, FeedbackformsComponent 
          ],
    imports: [  
      BrowserModule,
      MatExpansionModule,
        BrowserAnimationsModule,
        MatButtonModule,
        ScrollingModule, 
        DragDropModule,
        AppRoutingModule,

        InfiniteScrollModule,
        //NgQrScannerModule,
        NgxPayPalModule,
        ClipboardModule,
        ToastrModule.forRoot({
            timeOut: 2000,
        }),
        // ZXingScannerModule,
        BrowserAnimationsModule,
        MatSelectModule,
        FormsModule,
        MatBadgeModule,
        MatSidenavModule,
        MatInputModule,
        MatToolbarModule,
        HttpClientModule,
        MatButtonModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule,
        MatTabsModule,
        MatMenuModule,
        MatButtonModule,
        // OwlModule, 
        CarouselModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatCheckboxModule,
        MatTableModule,
        MatSlideToggleModule,
        QrCodeModule,
        QuillModule.forRoot()
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: HttpLoaderFactory,
        //         deps: [HttpClient],
        //     },
        // }),
    ],
    providers: [
        //TranslateService,
        CurrencyPipe,
        LocalNumberPipe,
        DataService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
