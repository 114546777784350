import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { ContentQueryModel } from "../shared/cms/contentquerymodel";
import { testcmsconfig, prodcmsconfig, cmsendpointconfig } from "../shared/cms/cmsconfig";
import { Endpoint } from "../shared/endpoints";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GetContentService {

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) {
  }

  getAuthToken(loginModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      cmsendpointconfig.baseAPiUrl + cmsendpointconfig.getAuthToken,
      loginModel,
      {
        headers: header,
      }
    );
  } 
  async getContentFromAI(aiModel: any):Promise<any>{
    //var token = await this.getToken(); 
    console.log("AI");
    let header = new HttpHeaders();
    //header = header.append("token", token); 
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.rephraseTextUrl,
      aiModel,
      {
        headers: header,
      }
    );
  }
  async generateKeyWord(aiModel: any):Promise<any>{
    //var token = await this.getToken(); 
    console.log("AI");
    let header = new HttpHeaders();
    //header = header.append("token", token); 
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.generateKeyWord,
      aiModel,
      {
        headers: header,
      }
    );
  }
  async getToken(): Promise<string> {
    var loginModel = {
      UserName: prodcmsconfig.apiUserName,
      Password: prodcmsconfig.apiUserPassword
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
      responseType: 'text' as 'json', // or 'text' depending on your server response
    };

    var data = await this.http.post<any>(
      cmsendpointconfig.baseAPiUrl + cmsendpointconfig.getAuthToken,
      loginModel,
      httpOptions
    ).toPromise();
 
    return data;
  }

  async getContentByContentQueryModel(contentQueryModel: ContentQueryModel) {
    var token = await this.getToken(); 
    var url = cmsendpointconfig.baseAPiUrl + cmsendpointconfig.getContentByContentQueryModel;

    let header = new HttpHeaders();
    header = header.append("Accept", "application/json");
    header = header.append("Content-Type", "application/json");
    header = header.append("ApiKey", prodcmsconfig.apikey);
    header = header.append("token", token);
    return this.http.post<any>(url, contentQueryModel, {
      headers: header,
    });
  }

  async getContentByRowKey(rowKey): Promise<Observable<any>> {
    var token = await this.getToken();
    var url = cmsendpointconfig.baseAPiUrl + cmsendpointconfig.getContentByRowKey + rowKey;
    let header = new HttpHeaders();
    header = header.append("Accept", "application/json");
    header = header.append("Content-Type", "application/json");
    header = header.append("ApiKey", prodcmsconfig.apikey);
    header = header.append("token", token);
    return this.http.get<any>(url, {
      headers: header,
    });
  }

  async getContentByContentTitleQueryModel(contentQueryModel: ContentQueryModel) {
    var url = cmsendpointconfig.baseAPiUrl + cmsendpointconfig.getContentByContentTitleQueryModel;
    var token = await this.getToken();
    let header = new HttpHeaders();
    header = header.append("Accept", "application/json");
    header = header.append("Content-Type", "application/json");
    header = header.append("ApiKey", prodcmsconfig.apikey);
    header = header.append("token", token);
    return this.http.post<any>(url, contentQueryModel, {
      headers: header,
    });
  }
}
