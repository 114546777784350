export enum Endpoint {
    saveBundleUrl = "api/Bundle",
    getBundleByIdUrl = "api/Bundle/bundleByBundleId",
    inAppropriateBundleUrl = "api/Flow/reportflow",
    deleteBundleUrl = "api/Bundle/deleteBundle",
    getBundleUrl = "api/Bundle/getAllUserBundles",
    getBundleByFlowIdUrl = "api/Bundle/bundleDetailsByFlowId",
    getUsersVoucherCodesUrl = "api/VoucherCode/couponCodeDiscountValue",
    getFlowsByCategoriesUrl = "api/Flow/flowsByCategories", // URL to web api
    getFlowsByIdUrl = "api/Flow/flowByFlowId", // URL to web api
    postContactUsUrl = "api/ContactUs/postContactUs",
    getContactUsRequestsUrl = "api/ContactUsController/getContactUsRequests",
    approvedUrl = "api/Flow/usersApprovedFlows",
    bookMarkUrl = "api/Flow/usersBookMarkFlows",
    draftUrl = "api/Flow/usersDraftFlows",
    usersFlowCommentsUrl =
    "api/FlowComment/allflowComment",
    addFlowCommentUrl = "api/FlowComment/flowComment",
    getVoucherCodeUrl = "api/VoucherCode/voucherCode",
    deleteFlowCommentUrl =
    "api/FlowComment/deleteFlowComment",
    editCommentUrl = "api/FlowComment/flowComment",
    IsFlowLikedUrl = "api/Flow/isFlowLiked",
    LikeFlowUrl = "api/Flow/likeFlow",
    likeBundleUrl="api/Bundle/likeBundle",
    IsUserFollowingUrl = "api/Flow/isUserFollowingUser",
    FollowUserUrl = "api/Flow/postUserFollower",
    IsFlowBookMarkedStatusUrl =
    "api/Flow/flowBookMarkedStatus",
    PostBookMarkFlowUrl = "api/Flow/postBookMarkFlow",
    deleteFlowUrl = "api/Flow/deleteFlow",
    inAppropriateUrl = "api/Flow/reportflow",
    getNotificationUrl = "api/Notification/userNotifications",
    markAsReadNotificationsUrl = "api/Notification/markAsReadNotifications",
    createFlowUrl = "api/Flow",
    postAccessType = "api/Flow/changeFlowAccess",
    getFlowsByInputCategoryUrl =
    "api/Flow/flowsByInputCategory",
    getIsFlowViewedUrl = "api/Flow/isFlowViewed",
    getAllFlowsUrl = "api/Flow/allFlows",
    getAllUserFlowsUrl = "api/Flow/allUserFlows",
    getSearchFlowsUrl = "api/Flow/searchedFlows",
    getSearchApprovedFlowsByFlowQueryModel = "api/Flow/getSearchApprovedFlowsByFlowQueryModel",
    getSearchApprovedBundleByBundleQueryModel = "api/Bundle/getSearchApprovedBundleByBundleQueryModel",
    getUserBundleUrl = "api/Bundle/getUserBundles",
    getUserPublicBundles="api/Bundle/getUserPublicBundles",
    postFollowUserUrl = "api/Flow/postUserFollower",
    flowCheckoutUrl = "api/Payments/checkout",
    getFlowsByItemsRequired = "api/Flow/flowsByItemsRequired",
    postLikeFlowCommentUrl = "api/FlowComment/likedFlowComment",
    PostLinkClickUrl = "api/Flow/LinkClick",
    getProfileUrl = "api/User/GetCustomerProfileByEmail",
    viewUserProfileUrl = "api/User/ViewOtherUserProfile",
    getAllUsersUrl = "api/User/GetAllUsers",
    getFollowersUrl = "api/User/getFollowerUsers",
    getFollowingUrl = "api/User/getFollowingUsers",
    customerModel = "api/User/saveCustomerProfileByEmail",
    saveSettingsForCustomer = "api/User/saveSettingsForCustomerProfileByEmail",
    saveNotifySettingsForCustomer = "api/User/saveNotificationSettingsForCustomerProfileByEmail",
    saveDonateSettingsForCustomer = "api/User/saveDonateSettingsForCustomerProfileByEmail",
    saveDefaultViewSettingsForCustomer = "api/User/saveDefaultViewSettingsForCustomerProfileByEmail",
    userEmailExists = "api/User/GetUserWithEmailAddressExists",
    changeUserNameUrl = "api/User/PostChangeUserName",
    getUserByUserNameUrl = "api/User/getUserByUserName",
    getActivationCodeUrl = "api/User/getActivationCode",
    getProfilepicUrl = "api/User/GetCustomerProfileByEmail",
    registerUser="api/User",
    deleteUser="api/User/deleteAccount",
    activateAccount="api/User/activateAccount",
    forgetPassword="api/User/forgotPassword",
    ChangePassword="api/User/PostChangePassword",
    getPurchasedHistoryFunds="api/User/getPurchaseHistoryByType",
    getFundByCustomerRowKey="api/User/getFundByCustomerRowKey",
    getConsultationsByDate="api/User/getConsultationsByDate",
    getConsultations="api/User/getConsultations",
    saveContentCreatorConsultationsFeedback="api/User/saveContentCreatorConsultationsFeedback",
    saveUserConsultationsFeedback="api/User/saveUserConsultationsFeedback",
    getUserConsultations="api/User/getUserConsultations",
    getConsultationsByRowKey="api/User/getConsultationsByRowKey",
    bookConsultation="api/User/bookConsultation",
    SupportFaq="api/Faq",
    deleteSupportFaq="api/Faq/deleteFaq",
    getSupportFaq="api/Faq/getFaqsDetails",
    getFaqDetailsByQuestion="api/Faq/getFaqDetailsByQuestion",
    //Not changed endpoints urls



    //baseUrl = "https://timitimsorg-staging.azurewebsites.net/",
    // baseUrl = "http://localhost:21255/";

    PostPageViewUrl =
    "api/PageView/pageViewDetails",
    GetPageViewDetailsUrl =
    "api/PageView/getPageViews",
    GetContentCount="api/PageView/getFlowCount",
    GetContent="api/PageView/getFlowContent", 
    GetContentLikeCount="api/PageView/getContentLikeCount",  
    GetContentCommentCount="api/PageView/getContentCommentCount",  
    GetContentSaveCount="api/PageView/getContentSaveCount",  
    GetBundleContentCount="api/PageView/getBundleCount",
    GetPurchaseByConsult="api/PageView/getPurchaseByConsult",
    getUserDashboardUrl = "api/UserDashboard/getUserDashboard", // URL to web api
    getUserDashboardFlows = "api/UserDashboard/getUserDashboardFlows",
    getUserDashboardBundles = "api/UserDashboard/getUserDashboardBundles",
    getUserDashboardPaidFlows = "api/UserDashboard/getUserDashboardPaidFlows",
    getUserDashboardPaidBundles = "api/UserDashboard/getUserDashboardPaidBundles",
    GetLinkClickUrl =
    "api/Flow/GetLinkClicks",
    GetAllLinkClickUrl =
    "api/Flow/GetAllLinkClicks",
    GetAllBundleLinkClicks="api/Flow/GetAllBundleLinkClicks",
    GetAllPageViews= "api/PageView/getAllPageViews",
    updateTimeSpentOnFlowUrl =
    "api/Flow/UpdateTimeSpentOnFlow",
    getCouponCodeDiscountValueUrl =
    "api/VoucherCodeController/getCouponCodeDiscountValue",
    purchasedUrl = "api/Flow/usersPurchasedFlows",
    login = "api/Authentication/login",
    searchFlowsUrl="api/Search/searchFlows",
    searchBundlesUrl="api/Search/searchBundles",
    searchUsersUrl="api/Search/searchUsers",
    searchFaqsUrl="api/Search/searchFaqs",
    searchItemsRequiredUrl="api/Search/searchItemsRequired",
    rephraseTextUrl = 'api/AI/rephraseText',
    generateKeyWord = 'api/AI/generateKeyWord',
    createBlog = "api/Blog",
    getUserBlogsDetails = "api/Blog/getUserBlogsDetails",
    getAllBlogs = "api/Blog/getAllBlogs",
    getBlogDetailsByCategory = "api/Blog/getBlogDetailsByCategory",
    getBlogsByKey = "api/Blog/getBlogsByKey",
    deleteBlog = "api/Blog/deleteBlog",
}