import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { BundleService } from "src/app/Services/bundleservice.service";

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { environment } from "src/environments/environment";
import { RestrictedAccessComponent } from "../restricted-access/restricted-access.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { GetContentService } from "src/app/Services/getcontent.service";
import { SelectionRephraseAIComponent } from "../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";

@Component({
  selector: "app-createbundle",
  templateUrl: "./createbundle.component.html",
  styleUrls: ["./createbundle.component.scss"],
})
export class CreatebundleComponent implements OnInit {
  description: string;
  title: string;
  operationInProgress: boolean;
  bundleImageUrl: string;
  fileData: File = null;
  showVideo: boolean;
  showVideoToggle: any;
  fileName: string;
  noResultReturned: boolean;
  addbundleselectedflows: [];
  userData: any;
  selectedFlowIds: any[];
  showFlows: boolean;
  bFlows: boolean;
  coverImageUri: any;
  uniqueId: any;
  bundleId: any;
  CategoryName: any;
  showDeleteContentMenu: boolean;
  showImage: boolean;
  showImagetoggle: any;
  price: any;
  isPaid: boolean;
  supportingDocumentHyperLinks: any[];
  supportingDocumentDocuments: any[];
  documentName: any;
  filename: any;
  sffilelink: any;
  linkName: any;
  hyperLink: any;
  coverPageURI: string;
  coverPosterPageURI: string;
  isAddSupportingDocument: boolean;
  tabIndex: any;
  FlowCount: any;
  noApprovedFlows: any;
  sffilename: any;
  totalSupportingDocuments: number;
  selectedFlow: boolean;
  showMessage: boolean;
  uploadProgress: number;
  posterUrl: string;
  selectedFile: File;
  showPercentage: boolean;
  isLoading: boolean;
  progress: number;

  constructor(
    private router: Router,
    private http: HttpClient,
    private bundleService: BundleService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private getContentService: GetContentService,
    private cdr: ChangeDetectorRef

  ) {
    this.title = "";
    this.description = "";
    this.operationInProgress = false;
    this.selectedFlowIds = [];
    this.supportingDocumentHyperLinks = [];
    this.supportingDocumentDocuments = [];
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    let bundle = JSON.parse(localStorage.getItem("createbundledetails"));
    this.addbundleselectedflows = JSON.parse(
      localStorage.getItem("addbundleselectedflows")
    );

    if (bundle != null) {
      this.title = bundle.Title;
      this.description = bundle.Description;
      this.bundleImageUrl = bundle.Url;
      this.isPaid = bundle.IsPaid;
      this.price = bundle.Price;
      this.supportingDocumentHyperLinks = bundle.supportingDocumentHyperLinks;
      this.supportingDocumentDocuments = bundle.supportingDocumentDocuments;
      this.isImageOrVideo(this.bundleImageUrl);
      console.log(this.showImage);
      console.log(this.showVideo);
    }

    if (this.addbundleselectedflows != null) {
      if (this.addbundleselectedflows.length >= 2) {
        this.bFlows = true;
      } else {
        this.bFlows = false;
      }
    }
    this.addbundleselectedflows.forEach((element: any) => {
      if (element.flow.price != null) {
        this.selectedFlow = true;
        return;
      }
    });
    if (this.selectedFlow === undefined) {
      this.selectedFlow = false;
    }

    console.log(this.selectedFlow);
  }
  // originalTitleText!: string;
  // repharaseButtonTitleText: string = "Rephrase text with AI";
  // isTitleRephrased: boolean = false;
  // isTitleRephraseInProgress: boolean = false;

  // async rephraseTitleText(input: string) {

  //   if (input == undefined || input == "") {
  //     this.toastr.warning(
  //       "The Text To Rephrase is required."
  //     );
  //     return;
  //   }

  //   if (!this.isTitleRephrased) {
  //     this.originalTitleText = input;
  //   }

  //   this.isTitleRephrased = true;
  //   this.isTitleRephraseInProgress = true;
  //   console.log(input);
  //   var aiModel = {
  //     textToRephrase: input
  //   };
  //   (await this.getContentService
  //     .getContentFromAI(aiModel))
  //     .subscribe((aiResponse) => {
  //       if (aiResponse) {
  //         console.log(aiResponse);
  //         console.log(aiResponse[0].completions);
  //         this.title = aiResponse[0].completions;
  //         this.isTitleRephraseInProgress = false;
  //       }
  //     });
  //   this.isTitleRephraseInProgress = false;
  // }

  originalTitleText!: string;
  repharaseButtonTitleText: string = "Rephrase text with AI";
  isTitleRephrased: boolean = false;
  isTitleRephraseInProgress: boolean = false;
  
  GoToOriginalTitleText() {
    this.title = this.originalTitleText;
  }

  openRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.title }  // Pass the text to the dialog
    });
    if (!this.isTitleRephrased) {
      this.originalTitleText = this.title;
    }
    this.isTitleRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style
        this.title = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isTitleRephrased = true;
        this.isTitleRephraseInProgress = false;
      }
    });
  }
  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress:boolean=false;

  GoToOriginalText() {
    this.description = this.originalContentTitleText;
  }

  openDescrptionRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.description }  // Pass the text to the dialog
    });
    if (!this.isRephrased) {
      this.originalContentTitleText = this.description;
      console.log(this.description);
    }
    this.isRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  
        this.description = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
        this.isRephraseInProgress = false;
      }
    });
  }

  // async rephraseDescriptionText(input: string) {

  //   if (input == undefined || input == "") {
  //     this.toastr.warning(
  //       "The Text To Rephrase is required."
  //     );
  //     return;
  //   }

  //   if (!this.isRephrased) {
  //     this.originalContentTitleText = input;
  //   }

  //   this.isRephrased = true;
  //   this.isRephraseInProgress = true;
  //   console.log(input);
  //   var aiModel = {
  //     textToRephrase: input
  //   };
  //   (await this.getContentService
  //     .getContentFromAI(aiModel))
  //     .subscribe((aiResponse) => {
  //       if (aiResponse) {
  //         console.log(aiResponse);
  //         console.log(aiResponse[0].completions);
  //         this.description = aiResponse[0].completions;
  //         this.isRephraseInProgress = false;
  //       }
  //     });
  //   this.isRephraseInProgress = false;

  // }
  saveFlowToServerAccessLevel(accessType) {
    // if (this.selectedFlow) {
    //   this.showMessage = true;
    //   this.toastr.warning("Please add price to bundle to publish as you have selected paid flows to be part of this bundle.");
    // }
    // else {
    //this.showMessage = false;
    this.noResultReturned = true;
    this.addbundleselectedflows.forEach((element) => {
      this.selectedFlowIds.push((element as any).flow.rowKey);
    });
    // localStorage.removeItem("createbundledetails");
    // localStorage.removeItem("addbundleselectedflows");

    var bundleModel = {
      UniqueCode: "",
      Description: this.description,
      CustomerEmail: this.userData.emailAddress,
      UserName: this.userData.uname,
      Title: this.title,
      AccessType: accessType,
      Url: this.bundleImageUrl,
      Type: "Bundle",
      BundleStatus: "Approved",
      FlowIds: this.selectedFlowIds,
      Price: this.price,
      IsPaid: this.isPaid,
      FlowSupportingDocumentsHyperLinkModels: this.supportingDocumentHyperLinks,
      FlowSupportingDocumentsDocumentModels: this.supportingDocumentDocuments,
      //this.configProvider.ApprovedStatus,
    };


    if (this.userData != null) {
      this.noResultReturned = true;
      this.progress = 0;
      if (this.selectedFlow === true) {
        if (bundleModel.Price != null) {
          this.bundleService
            .PostBundle(bundleModel, this.userData.emailAddress)
            .subscribe({
              next: (event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    console.log('Request sent to server.');
                    break;
  
                  case HttpEventType.UploadProgress:
                    this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                    console.log(`Progress: ${this.progress}%`);
                    break;
  
                  case HttpEventType.Response:
                    console.log('Upload complete:', event.body);   
                    localStorage.setItem("draftTabIndex", "1"); 
                    this.cdr.detectChanges();
                    
                    setTimeout(() => {
                      this.progress = 100;
                      console.log("set is loading");
                      this.noResultReturned = false;
                      this.toastr.success("Bundle successfully published"); 
                      this.router.navigate(['/user-profile']);
                      this.resetProgress();
                    }, 5000);
  
  
                    break;
  
                  default:
                    console.log('Unhandled event:', event);
                }
              },
              error: (err) => {
                console.error('Error uploading:', err);
                this.resetProgress();
              }
            }); 
          // console.log("Included paid flows also added price", bundleModel.Price);
        } else {
          this.noResultReturned = false;
          this.showMessage = true;
          this.toastr.warning("Please add price to bundle to publish as you have selected paid flows to be part of this bundle.");
        }
      }
      else {
        if (bundleModel.Price == null || bundleModel.Price == undefined) {
          this.bundleService
            .PostBundle(bundleModel, this.userData.emailAddress)
            .subscribe({
              next: (event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                    console.log('Request sent to server.');
                    break;
  
                  case HttpEventType.UploadProgress:
                    this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                    console.log(`Progress: ${this.progress}%`);
                    break;
  
                  case HttpEventType.Response:
                    console.log('Upload complete:', event.body);   
                    localStorage.setItem("draftTabIndex", "1"); 
                    this.cdr.detectChanges();
                    
                    setTimeout(() => {
                      this.progress = 100;
                      console.log("set is loading");
                      this.noResultReturned = false;
                      this.toastr.success("Bundle successfully published"); 
                      this.router.navigate(['/user-profile']);
                      this.resetProgress();
                    }, 5000);
  
  
                    break;
  
                  default:
                    console.log('Unhandled event:', event);
                }
              },
              error: (err) => {
                console.error('Error uploading:', err);
                this.resetProgress();
              }
            });  
          console.log("Free bundle publish", bundleModel.Price);
        } else {
          this.noResultReturned = false;
          this.showMessage = true;
          this.toastr.warning("Please add price to bundle to publish as you have selected paid flows to be part of this bundle.");
        }
      }
    }
  }

  resetProgress() {
    setTimeout(() => {
      this.progress = 0;
      this.isLoading = false;
    }, 500); // Small delay before hiding progress
  }
  restrictedAccessPage(accessType) {
    var flowModel = {
      UniqueCode: "",
      Description: this.description,
      CustomerEmail: this.userData.emailAddress,
      Title: this.title,
      AccessType: accessType,
      Url: this.bundleImageUrl,
      Type: "Bundle",
      BundleStatus: "Approved",
      FlowIds: this.selectedFlowIds,
      Price: this.price,
      IsPaid: this.isPaid,
    };
    localStorage.setItem("flowModel", JSON.stringify(flowModel));

    // this.router.navigate(["/restrictedPage"]);
    const dialogRef = this.dialog.open(RestrictedAccessComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("closed");
    });
  }
  flowClicked(flow) { }

  viewUserProfile(flow) { }

  addflowstoBundle() {

    var bundle = {
      Title: this.title,
      Description: this.description,
      Url: this.bundleImageUrl,
      IsPaid: this.isPaid,
      Price: this.price,
      supportingDocumentHyperLinks: this.supportingDocumentHyperLinks,
      supportingDocumentDocuments: this.supportingDocumentDocuments,
    };

    localStorage.setItem("naviagatedToAddFlowsFrom", "createbundle");
    localStorage.setItem("createbundledetails", JSON.stringify(bundle));
    this.router.navigate(["/addFlowBundle"]);
  }

  checkValue() {
    if (!this.isPaid) {
      this.price = null;
    }
  }

  isImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showImage = true;
      this.showVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showVideo = true;
      this.showImage = false;
    }
  }

  selectFiles(event: any) {
    if (event.target.files) {
      this.noResultReturned = true;
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      // this.isStepCoverPageURISet = true;
      this.isLoading = true; // Add loading state
      
      // Check if it's an image file
      const imageExtensions = ["JPEG", "jpeg", "png", "jpg", "PNG", "JFIF", "jfif", "TIFF", "tiff", "TIF", "tif", "JPG"];
      const videoExtensions = ["MP4", "mp4", "MOV", "mov", "WMV", "wmv", "FLV", "flv", "AVCHD", "avchd", "WEBM", "webm", 
                              "MKV", "mkv", "VOB", "vob", "OGV", "ogv", "GIFY", "gifv", "M4P", "m4p", "M4V", "m4v", 
                              "3GP", "3gp", "3G2", "3g2", "AMV", "amv", "NSV", "nsv", "OGG", "ogg", "F4V", "f4v", 
                              "F4P", "f4p", "F4A", "f4a", "F4B", "f4b"];
  
      const isImage = imageExtensions.some(ext => this.fileData.name.includes(ext));
      const isVideo = videoExtensions.some(ext => this.fileData.name.includes(ext));
      
      const uploadUrl = isImage 
        ? environment.apiBaseUrl + environment.uploadImageUrl 
        : environment.apiBaseUrl + environment.uploadVideoUrl;
  
      this.http
        .post(uploadUrl, formData, {
          reportProgress: true,
          observe: 'events'
        })
        .subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress: {
                if (event.total !== undefined) {
                  const progress = (event.loaded / event.total) * 100;
                  if (progress !== 100) {
                    this.progress = progress;
                  }
                }
                break;
              }
              case HttpEventType.Response:
                this.handleFileUpload(event, isImage);
                break;
            }
          },
          (error) => {
            this.isLoading = false;
            this.noResultReturned = false;
            this.fileName = '';
            this.toastr.warning("Please upload files up to 80MB");
            console.error('An error occurred:', error);
          }
        );
      }
  }
  
  handleFileUpload(res: HttpResponse<any>, isImage: boolean) {
    this.isLoading = false;
    this.noResultReturned = false;
    const mediaUploadStatus = res.body as any;
    
    if (mediaUploadStatus != null) {
      if (isImage) {
        this.showImage = true;
        this.showVideo = false;
        this.bundleImageUrl =
        environment.imageStorageBaseUrl +
        mediaUploadStatus.fileName;
      } else {
        this.showVideo = true;
        this.showImage = false;
        this.bundleImageUrl =
        environment.videoStorageBaseUrl +
        mediaUploadStatus.fileName;
        this.posterUrl = environment.imageStorageBaseUrl + mediaUploadStatus.posterImageName;
      }
      this.toastr.success("File has been uploaded successfully.");
      this.progress = 100;
    } else {
      // Handle null response
      alert(mediaUploadStatus?.message || "Upload failed");
    }
  }


  deleteBundle(item, index) {
    this.addbundleselectedflows.splice(index, 1);
    localStorage.setItem(
      "addbundleselectedflows",
      JSON.stringify(this.addbundleselectedflows)
    );

    if (this.addbundleselectedflows.length >= 2) {
      this.bFlows = true;
    } else {
      this.bFlows = false;
    }
  }

  leave() {
    var pageToNavigate = localStorage.getItem("navigatebackfromcreatebundle");
    this.router.navigate([pageToNavigate]);
    localStorage.removeItem("navigatebackfromcreatebundle");
  }
  close() {
    localStorage.setItem(
      "supportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    this.router.navigate(["/create-flow"]);
  }

  editLink(index, link) {
    this.linkName = link.linkName;
    this.hyperLink = link.linkUrl;
    localStorage.setItem("editBundlesupportingDocumentHyperLinksIndex", index);
  }

  addLink() {
    var editsupportingDocumentHyperLinksIndex = localStorage.getItem(
      "editBundlesupportingDocumentHyperLinksIndex"
    );
    if (editsupportingDocumentHyperLinksIndex != null) {
      this.supportingDocumentHyperLinks.splice(
        parseInt(editsupportingDocumentHyperLinksIndex),
        1
      );
    }

    this.supportingDocumentHyperLinks.push({
      LinkName: this.linkName,
      LinkUrl: this.hyperLink,
    });

    this.linkName = "";
    this.hyperLink = "";
    localStorage.setItem(
      "BundleSupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );
    localStorage.removeItem("editBundlesupportingDocumentHyperLinksIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  addSupportingDocument() {
    var editSupportingDocumentRequiredIndex = localStorage.getItem(
      "editBundleSupportingDocumentRequiredIndex"
    );

    if (editSupportingDocumentRequiredIndex != null) {
      this.supportingDocumentDocuments.splice(
        parseInt(editSupportingDocumentRequiredIndex),
        1
      );
    }

    this.supportingDocumentDocuments.push({
      DocumentName: this.documentName,
      DocumentUrl: this.sffilelink,
    });

    this.documentName = "";
    this.sffilelink = "";

    localStorage.setItem(
      "BundleSupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    localStorage.removeItem("editBundleSupportingDocumentRequiredIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  editSupportingDocument(index, document) {
    this.documentName = document.documentName;
    this.sffilelink = document.documentUrl;
    localStorage.setItem("editBundleSupportingDocumentRequiredIndex", index);
  }

  tabChanged(tabChangedEvent) { }

  remove(index) {
    this.supportingDocumentDocuments.splice(index, 1);

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  removeLink(index) {
    this.supportingDocumentHyperLinks.splice(index, 1);
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.supportingDocumentDocuments,
      event.previousIndex,
      event.currentIndex
    );
  }

  uploadSupportingDocuments(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);

      // this.isStepCoverPageURISet = true;
      this.isAddSupportingDocument = true;
      if (
        this.fileData.name.includes("pdf") ||
        this.fileData.name.includes("PDF")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadDocumentsUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showImage = true;
            this.showVideo = false;
            this.noResultReturned = false;
            //
            if (mediaUploadStatus != null) {
              this.sffilename = mediaUploadStatus.fileName;
              this.sffilelink =
                environment.documentsStorageBaseUrl +
                mediaUploadStatus.fileName;
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }
}
