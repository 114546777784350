import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { UserserviceService } from 'src/app/Services/userservice.service';
import { Endpoint } from 'src/app/shared/endpoints';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  enableNotifications: boolean = false;
  enableGifts: boolean = false;
  enablePaidConsult: boolean = false;
  enableConsultation: boolean = false;
  enableScheduling: boolean = false;
  newSlot: { slotTime: string; price: number; isActive: boolean } = { slotTime: '', price: 0, isActive: false };
  slotTimes: string[] = [];
  pricePerSlot: any[] = [];
  userData: any;
  noResultReturned: boolean;
  consultationMeetLink: any;
  consultationZoomLink: any;
  isWeekly: boolean = false;
  isMonthly: boolean = false;
  isOneDay: boolean = false;
  ConsultationAvailiblity: any;
  ConsultationTimeZone: any;
  ConsultationStartTimeAvailiblity: any;
  ConsultationEndTimeAvailiblity: any;
  ConsultationCustomStartDate: any;
  ConsultationCustomEndDate: any;
  isCustomDateVisible: boolean = false;
  consultationForm: FormGroup;
  isEveryDay: boolean;
  isCustom: boolean;
  selectedTimes: any;
  ConsultationMonthDay: any;
  daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
  ];
  isMonFri: boolean;
  ConsultationCustomWeekDays: any;
  defaultView: any;
  enableDefaultView: boolean = false;
  viewTitle: any;
  isMenuOpen = false; // Tracks whether the menu is open
  activeSection: string = 'consultation'; // Default section
  saveConsultSettings: boolean = false;
  isToastShown: any;


  constructor(private userserviceService: UserserviceService,
    private toastr: ToastrService,
    public route: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    // Generate slot times (15 mins to 3 hrs)
    this.consultationForm = this.fb.group({
      ConsultationAvailiblity: ['', Validators.required],
      Monday: [false],
      Tuesday: [false],
      Wednesday: [false],
      Thursday: [false],
      Friday: [false],
      MonthDay: [null],
      OneDayDate: [null],
      consultationCustomStartDate: [''],
      consultationCustomEndDate: [''],
      ConsultationStartTimeAvailiblity: [''],
      ConsultationEndTimeAvailiblity: [''],
      ConsultationTimeZone: [''],
      weeklyDays: this.fb.array([]),
    });

    this.slotTimes = this.generateSlotTimes();
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.getUserDataByEmail();
    console.log(this.userData);
    this.enableGifts = this.userData.enableAcceptFunds;
    this.enablePaidConsult = this.userData.isConsultationPaid != null ? this.userData.isConsultationPaid : false;
    this.enableConsultation = this.userData.enableConsultation;
    this.enableNotifications = this.userData.enableNotifications;
    this.enableScheduling = this.userData.enableScheduling;
    this.enableDefaultView = this.userData.enableDefaultView;
    // console.log(this.ConsultationTimeZone);
    // this.toggleTimeZone(this.ConsultationTimeZone); 
    if (this.userData.pricePerSlot.length > 0) {
      this.pricePerSlot = this.userData.pricePerSlot;
    }
    console.log(this.pricePerSlot);
  }
  onAvailabilityChange(value: string): void {
    this.isEveryDay = value === 'Every Day';
    this.isWeekly = value === 'Weekly';
    this.isCustom = value === 'Custom';
    this.isMonthly = value === 'Monthly';
    this.isMonFri = value === 'Mon-Fri';
    this.isOneDay = value === 'One Day';
    this.isCustomDateVisible = value === 'Custom';
    this.consultationForm.get('ConsultationAvailiblity')?.setValue(value);

    if (this.isWeekly) {
      this.initWeeklyDays(); // Initialize the weekly days
    } else {
      this.clearWeeklyDays();
    }
  }


  generateSlotTimes(): string[] {
    const times: string[] = [];
    for (let minutes = 15; minutes <= 180; minutes += 15) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      times.push(
        hours > 0 ? `${hours} hrs ${mins} mins` : `${mins} mins`
      );
    }
    return times;
  }

  onEnableConsultationChange() {
    if(this.enableConsultation){
      if ((this.consultationMeetLink == null || this.consultationZoomLink == null) && this.ConsultationAvailiblity) {
        this.toastr.warning(
          "Meeting link fields and availability selection are required to enable consultation settings."
        ); 
        this.saveConsultSettings = false;
      } else {
        this.saveConsultSettings = true;
        if (!this.enableConsultation) {
          // Reset PricePerSlot if consultation is disabled
          this.pricePerSlot = [];
        }
      }
    }else{
      this.saveConsultSettings = true;
    }

  }

  addPricePerSlot() {
    // Ensure a valid slot is selected before adding
    if (this.newSlot.slotTime) {
      this.pricePerSlot.push({ ...this.newSlot });
      this.newSlot = { slotTime: '', price: 0, isActive: false }; // Reset the form
      this.selectedTimes.push(this.newSlot.slotTime);

    } else {
      this.toastr.warning(
        "Please select a slot time before adding."
      );
    }
  }
  
  onSettingsSubmit() {
    // Reset toast flag on every submit
    this.isToastShown = false;
  
    // Check form validity
    if (this.consultationForm.invalid && !this.enableConsultation && this.enablePaidConsult) {
      this.showToastOnce("Form is invalid. Please check your input.");
      return;
    }
    // Check form validity
    if (!this.enableConsultation && this.enablePaidConsult && this.pricePerSlot) {
      this.showToastOnce("Form is invalid. Please enable your paid consult as you have added prices for slots.");
      return;
    }
    // Check form validity
    if (this.enableConsultation && !this.enablePaidConsult && this.pricePerSlot) {
      this.showToastOnce("Form is invalid. Please enable your paid consult as you have added prices for slots.");
      return;
    }
  
    if (this.consultationForm.invalid && this.enableConsultation 
      && (this.consultationMeetLink || this.consultationZoomLink)) {
      this.showToastOnce("Form is incomplete or meeting link is missing.");
      return;
    }
    if (!this.consultationMeetLink && !this.consultationZoomLink){
      this.showToastOnce("Form is incomplete or meeting link is missing.");
      return;
    }
  
    // Custom conditions: Ensure at least one day is selected
    const { Monday, Tuesday, Wednesday, Thursday, Friday } = this.consultationForm.value;
    if (!Monday && !Tuesday && !Wednesday && !Thursday && !Friday) {
      this.showToastOnce("Select at least one day for consultation.");
      return;
    }
  
    // If no validation errors, proceed
    if(!this.isToastShown){
      this.saveSettings(); // Your actual form submission logic here
    }
  }
  
  showToastOnce(message: string) {
    if (!this.isToastShown) {
      this.toastr.info(message);
      this.isToastShown = true;
    }
  }
  
  // Disable button based on form state
  isButtonDisabled(): boolean {
    return this.consultationForm.invalid;
  }
  saveSettings() {
    this.noResultReturned = true;
    const formData = this.consultationForm.value;
    console.log(formData);
    console.log(this.consultationForm);
    if (this.ConsultationTimeZone != null) {
      formData.ConsultationTimeZone = this.ConsultationTimeZone;
      console.log("inside consult if", formData.ConsultationTimeZone);
    }
    var formWeekData = this.getSelectedWeekDays(formData);
    if(formWeekData === this.ConsultationCustomWeekDays){
      this.ConsultationCustomWeekDays = this.ConsultationCustomWeekDays
    }else{
      this.ConsultationCustomWeekDays = this.getSelectedWeekDays(formData);
    } 
    const dataToSave = {
      IsConsultationPaid: this.enablePaidConsult,
      EmailAddress: this.userData.emailAddress,
      EnableConsultation: this.enableConsultation,
      EnableScheduling: this.enableScheduling,
      PricePerSlot: this.enableConsultation ? this.pricePerSlot : null,
      ConsultationMeetLink: this.consultationMeetLink,
      ConsultationZoomLink: this.consultationZoomLink,
      ConsultationAvailiblity: formData.ConsultationAvailiblity,
      ConsultationCustomWeekDays:this.ConsultationCustomWeekDays,
      ConsultationMonthDay: formData.MonthDay == null || undefined ? this.ConsultationMonthDay : formData.MonthDay,
      ConsultationOneDayDate: formData.OneDayDate,
      ConsultationCustomStartDate: formData.ConsultationCustomStartDate,
      ConsultationCustomEndDate: formData.ConsultationCustomEndDate,
      ConsultationTimeZone: formData.ConsultationTimeZone,
      ConsultationStartTimeAvailiblity: formData.ConsultationStartTimeAvailiblity,
      ConsultationEndTimeAvailiblity: formData.ConsultationEndTimeAvailiblity,
    }; 
    // Call a service to save data or handle it here
    console.log('Saving Settings:', dataToSave);

    this.userserviceService
      .UpdateSettingsForCustomerProfileDetails(dataToSave)
      .subscribe(
        (data) => {
          this.noResultReturned = false;
          if (data == false) {
            this.toastr.warning(
              "Consulatation setting updated failed."
            );
          } else {
            this.toastr.success(
              "Consulatation setting updated successfully."
            );
            this.route.navigate(["/user-profile"]);
            this.showToast();

          }
        },
        (error) => { }
      );
  }
  saveNotifySettings() {
    this.noResultReturned = true; 
    
    const dataToSave = { 
      EmailAddress: this.userData.emailAddress,
      EnableNotifications: this.enableNotifications, 
    };

    // Call a service to save data or handle it here
    console.log('Saving Settings:', dataToSave);

    this.userserviceService
      .UpdateNotifySettingsForCustomerProfileDetails(dataToSave)
      .subscribe(
        (data) => {
          this.noResultReturned = false;
          if (data == false) {
            this.toastr.warning(
              "Notification setting updated failed."
            );
          } else {
            this.toastr.success(
              "Notification setting updated successfully."
            );
            this.route.navigate(["/user-profile"]);
            this.showToast();

          }
        },
        (error) => { }
      );
  }
  saveDonateSettings() {
    this.noResultReturned = true; 

    const dataToSave = { 
      EmailAddress: this.userData.emailAddress,
      EnableAcceptFunds: this.enableGifts, 
    };

    // Call a service to save data or handle it here
    console.log('Saving Settings:', dataToSave);

    this.userserviceService
      .UpdateDonateSettingsForCustomerProfileDetails(dataToSave)
      .subscribe(
        (data) => {
          this.noResultReturned = false;
          if (data == false) {
            this.toastr.warning(
              "Donation setting updated failed."
            );
          } else {
            this.toastr.success(
              "Donation setting updated successfully."
            );
            this.route.navigate(["/user-profile"]);
            this.showToast();

          }
        },
        (error) => { }
      );
  }
  saveDefaultViewSettings() {
    this.noResultReturned = true; 

    const dataToSave = { 
      EmailAddress: this.userData.emailAddress,
      EnableDefaultView: this.enableDefaultView, 
    };

    // Call a service to save data or handle it here
    console.log('Saving Settings:', dataToSave);

    this.userserviceService
      .UpdateDefaultViewSettingsForCustomerProfileDetails(dataToSave)
      .subscribe(
        (data) => {
          this.noResultReturned = false;
          if (data == false) {
            this.toastr.warning(
              "Default view flow setting updated failed."
            );
          } else {
            this.toastr.success(
              "Default view flow setting updated successfully."
            );
            this.route.navigate(["/user-profile"]);
            this.showToast();

          }
        },
        (error) => { }
      );
  }
  // Toggle the menu visibility
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
  }

  // Show the selected section and close the menu on mobile
  showSection(section: string) {
    this.activeSection = section;
    this.isMenuOpen = false; // Close the menu after selection
  }
  // Helper method to get selected weekdays
  getSelectedWeekDays(formData: any): string[] {
    const selectedDays = [];
    if (formData.Monday) selectedDays.push('Monday');
    if (formData.Tuesday) selectedDays.push('Tuesday');
    if (formData.Wednesday) selectedDays.push('Wednesday');
    if (formData.Thursday) selectedDays.push('Thursday');
    if (formData.Friday) selectedDays.push('Friday');
    return selectedDays;
  }

  toggleDay(day: string) {
    console.log(day);
    const weeklyDays = this.consultationForm.get('weeklyDays') as FormGroup;
    const currentValue = weeklyDays.get(day)?.value;
    weeklyDays.get(day)?.setValue(!currentValue); // Toggle the value
    console.log(weeklyDays);
  }
  toggle(day) {
    this.consultationForm.patchValue({
      day: true
    });

    console.log(day);
    console.log(this.consultationForm);
  }

  toggleTimeZone(day) {
    console.log(day);
    const timeZone = this.consultationForm.get('ConsultationTimeZone') as FormGroup;
    this.ConsultationTimeZone.get(day)?.setValue(!timeZone); // Toggle the value
    console.log(this.consultationForm.value);
  }

  onSubmit(): void {
    // Handle the form submission
    console.log('Form submitted with availability:', this.ConsultationAvailiblity);
  }
  removePricePerSlot(index: number) {
    this.pricePerSlot.splice(index, 1);
  }

  showToast() {
    this.toastr.success(
      "Profile setting updated successful. It might take few minutes to reflects across the site"
    );
  }

  getUserDataByEmail() {
    this.userserviceService
      .getProfileUser()
      .subscribe((data) => {
        console.log(data);
        // data.userData = this.userData;
        // localStorage.setItem("LoggedInUser",data.userData);
        const formData = this.consultationForm.value;
        this.pricePerSlot = data.userData.pricePerSlot;
        if (data.userData.consultationAvailiblity != null) {
          this.ConsultationAvailiblity = data.userData.consultationAvailiblity;
          this.ConsultationCustomEndDate = data.userData.consultationCustomEndDate;
          this.ConsultationCustomStartDate = data.userData.consultationCustomStartDate;
          this.consultationMeetLink = data.userData.consultationMeetLink;
          this.consultationZoomLink = data.userData.consultationZoomLink;
          this.ConsultationTimeZone = data.userData.consultationTimeZone;
          this.ConsultationMonthDay = data.userData.consultationMonthDay;
          if (data.userData.consultationCustomWeekDays && 
            data.userData.consultationCustomWeekDays.length > 0 && 
            data.userData.consultationCustomWeekDays[0] !== " ") { 

            this.ConsultationCustomWeekDays = data.userData.consultationCustomWeekDays;
            // data.userData.consultationCustomWeekDays.forEach((element) => {
            //   // console.log(formData);
            //   // console.log(element);
            //   // if (element == "Monday") formData.Monday(true);
            //   // if (element == "Tuesday") formData.Tuesday(true);
            //   // if (element=="Wednesday") formData.Wednesday(true);
            //   // if (element =="Thursday") formData.Thursday(true);
            //   // if (element =="Friday") formData.Friday(true); 
            //   // this.toggle(element);
            // });
            // console.log(formData);
          }
          this.ConsultationStartTimeAvailiblity = data.userData.consultationStartTimeAvailiblity;
          this.ConsultationEndTimeAvailiblity = data.userData.consultationEndTimeAvailiblity;
          this.onAvailabilityChange(this.ConsultationAvailiblity);
          // this.toggleTimeZone(data.userData.consultationTimeZone);
          console.log(this.ConsultationAvailiblity);
          console.log(this.consultationForm);
          console.log(this.ConsultationStartTimeAvailiblity);
          console.log(this.ConsultationEndTimeAvailiblity);
          console.log(this.ConsultationTimeZone);

        }
      });
  }

  get weeklyDays(): FormArray {
    return this.consultationForm.get('weeklyDays') as FormArray;
  }


  initWeeklyDays() {
    // Clear any existing controls to prevent duplicates
    this.clearWeeklyDays();

    this.daysOfWeek.forEach(() => {
      this.weeklyDays.push(this.fb.control(false)); // Add a control for each day
    });
  }

  clearWeeklyDays() {
    this.weeklyDays.clear(); // Clear the FormArray
  }
  timezones = [
    { value: "UTC-12:00", label: "UTC-12:00" },
    { value: "UTC-11:00", label: "UTC-11:00" },
    { value: "UTC-10:00", label: "UTC-10:00" },
    { value: "UTC-09:00", label: "UTC-09:00" },
    { value: "UTC-08:00", label: "UTC-08:00 (PST)" },
    { value: "UTC-07:00", label: "UTC-07:00 (MST)" },
    { value: "UTC-06:00", label: "UTC-06:00 (CST)" },
    { value: "UTC-05:00", label: "UTC-05:00 (EST)" },
    { value: "UTC-04:00", label: "UTC-04:00 (AST)" },
    { value: "UTC-03:00", label: "UTC-03:00" },
    { value: "UTC-02:00", label: "UTC-02:00" },
    { value: "UTC-01:00", label: "UTC-01:00" },
    { value: "UTC+00:00", label: "UTC+00:00 (GMT)" },
    { value: "UTC+01:00", label: "UTC+01:00 (CET)" },
    { value: "UTC+02:00", label: "UTC+02:00 (EET)" },
    { value: "UTC+03:00", label: "UTC+03:00 (MSK)" },
    { value: "UTC+04:00", label: "UTC+04:00" },
    { value: "UTC+05:00", label: "UTC+05:00" },
    { value: "UTC+05:30", label: "UTC+05:30 (IST)" },
    { value: "UTC+06:00", label: "UTC+06:00" },
    { value: "UTC+07:00", label: "UTC+07:00" },
    { value: "UTC+08:00", label: "UTC+08:00 (CST)" },
    { value: "UTC+09:00", label: "UTC+09:00 (JST)" },
    { value: "UTC+09:30", label: "UTC+09:30 (ACST)" },
    { value: "UTC+10:00", label: "UTC+10:00 (AEST)" },
    { value: "UTC+11:00", label: "UTC+11:00" },
    { value: "UTC+12:00", label: "UTC+12:00 (NZST)" },
    { value: "UTC+13:00", label: "UTC+13:00" },
    { value: "UTC+14:00", label: "UTC+14:00" },
  ];
}

