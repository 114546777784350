import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
// import * as firebase from "firebase";
import { DataService } from "src/app/Services/dataservice.service";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { BundledialogComponent } from "../bundledialog/bundledialog.component";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-bulb-guide",
  templateUrl: "./bulb-guide.component.html",
  styleUrls: ["./bulb-guide.component.scss"],
})
export class BulbGuideComponent implements OnInit {
  userDisplayName: any;
  userData: any;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  carouselOptions: any;
  profilePicture: string = "";
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  flowData: any;
  showNotificationCount: boolean;
  notificationCount: number;
  isMobileView: boolean;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any = [];
  notificationscount: number;
  showDefaultImage: boolean;
  userDisplayEmail: any;
  flows: any = [];
  getNotificationUrl: string;
  ProfilePicUrl: any;
  showAnyUserProfilePic: boolean;

  constructor(
    private flowServiceService: FlowServiceService,
    private router: Router,
    private dataService: DataService,
    private userserviceService: UserserviceService,
    private eventEmitterService: EventEmitterService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    public dialog: MatDialog,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.titleandmetaserviceService.setTitle("Bulb Guide");
    this.getNotificationUrl = "v1/NotificationController/GetUserNotifications";
    this.notificationsarray = null;
    this.getScreenSize();
    this.userDisplayEmail = "";
    this.eventEmitterService.callEvent();
    this.eventEmitterService.setHomePageIconColorEvent("bulb");

    if (this.eventEmitterService.setProfilePictureCompleted === undefined) {
      this.eventEmitterService.setProfilePictureCompleted =
        this.eventEmitterService.setProfilePictureFunction.subscribe(
          (profilePicture: string) => {
            this.showDefaultImage = false;
            this.profilePicture = profilePicture;
          }
        );
    }
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.eventEmitterService.setNotificationCompleted =
      this.eventEmitterService.setNotificationFunction.subscribe(
        (notificationModelArray: any) => {
          this.notificationsarray = [];
          this.showNotificationCount = true;
          const notifications = JSON.parse(notificationModelArray);
          notifications.forEach((element) => {
            this.notificationsarray.push(element.notification);
          });
          this.notificationscount = this.notificationsarray.length;
          this.changeDetectorRef.detectChanges();
        }
      );

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.uname = this.userData.userName;
      this.name = this.userData.firstName;

      this.getProfileUser();
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize();
  }

  resize() {
    this.carouselOptions = {
      margin: 20,
      nav: true,
      dots: false,
      pagination: false,
      responsiveClass: true,
      loop: false,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        300: {
          items: 2,
          nav: true,
        },
        400: {
          items: 2,
          nav: true,
        },
        550: {
          items: 3,
          nav: true,
        },
        600: {
          items: 3,
          nav: true,
        },
        700: {
          items: 3,
          nav: true,
        },
        750: {
          items: 4,
          nav: true,
        },
        800: {
          items: 4,
          nav: true,
        },
        900: {
          items: 5,
          nav: true,
        },
        1000: {
          items: 5,
          nav: true,
          loop: false,
        },

        1100: {
          items: 6,
          nav: true,
          loop: false,
        },

        1200: {
          items: 6,
          nav: true,
          loop: false,
        },
        1300: {
          items: 6,
          nav: true,
          loop: false,
        },
        1400: {
          items: 6,
          nav: true,
          loop: false,
        },
        1500: {
          items: 6,
          nav: true,
          loop: false,
        },
      },
    };
  }

  onResize(event) {
    this.resize();

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  getProfileUser(): void {
    this.showNotificationCount = true;
    this.userserviceService
      .getProfileUser()
      .subscribe((data) => {
        if (
          data.userData.profilePicUrl === "" ||
          data.userData.profilePicUrl == null
        ) {
          this.showDefaultImage = true;
        } else {
          this.showDefaultImage = false;
          this.profilePicture = data.userData.profilePicUrl;
        }
        this.email = data.userData.emailAddress;
        this.phone = data.userData.phoneNumber;
        this.info = data.userData.info;
        this.website = data.userData.website;
        this.userDisplayName = this.uname = data.userData.userName;
        this.name = data.userData.firstName;
        this.getnotification();
      });
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      localStorage.setItem("navigatebackfromcreateflow", this.location.path());
      localStorage.setItem(
        "navigatebackfromcreatebundle",
        this.location.path()
      );
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  avatarClicked(notification: any, source: any) {

    if (source == "flow clicked") {
      if (notification.contentType == "Flow") {
        localStorage.setItem("notificationflowId", notification.contentId);
        localStorage.setItem("flow", null); 
        this.router.navigate([`/guide/${this.generateUrlSlug(notification.contentTitle)}/${notification.contentId}`]); 
      } else if (notification.ContentType == "Bundle") {
        this.router.navigate(["/viewbundle/" + notification.rowKey]);
      }
    } else if (source == "profile clicked") {
      if (this.userData.emailAddress === notification.notifierUserEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }

      this.dataService.flowByCategorieschangeMessage(notification);
    }
  }

  generateUrlSlug(html) {
    if (!html) return ''; 
    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }

  notification() {
    this.router.navigate(["/notifications"]);
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  search() {
    this.router.navigate(["/"]);
  }

  showImage(itemCategory, item, index) {
    itemCategory.flowModels.forEach((element) => {
      if (item.profilePic !== null) {
        this.showAnyUserProfilePic = false;
      } else {
        this.showAnyUserProfilePic = true;
      }
      if (element.arrow !== undefined) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }

  getnotification() {
    this.notificationsarray = [];
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: 0,
    });
    
    this.flowServiceService
      .getNotifications(flowQueryModel)
      .subscribe((data) => {
        if (data.UserNotifications != null) {
          data.UserNotifications.forEach((element) => {
            var notification = {
              notification: {
                ProfilePicUrl: element.profilePicUrl,
                NotifierUserName: element.notifierUserName,
                Message: element.message,
                FlowUrl: element.flowUrl,
                ContentId: element.contentId,
                ContentTitle: element.contentTitle,
                FlowId: element.contentId,
                ContentType: element.contentType,
                NotifierUserEmail: element.notifierUserEmail,
                VideoFlowPosterImageUrl: element.videoFlowPosterImageUrl,
              },
            };
            this.notificationsarray.push(notification);
          });
        }
      });
  }
  openDialog() {
    if (this.userData != null) {
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "bundle")  {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.router.navigate(["/createbundle"]);
        } else{
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.router.navigate(["/create-flow"]);
         
        }
      });
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }
}
