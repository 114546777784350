import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { Chart, ChartOptions, ChartType, ChartDataset, registerables } from 'chart.js/auto';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  userData: any;
  userEmail: string;
  noResultReturned: boolean;
  linksClicked: any = [];
  pageViews: any = [];
  dashboardModel: any;
  flowData: any;
  @ViewChild('pieChartCanvas', { static: true }) pieChartCanvas: ElementRef<HTMLCanvasElement>;
  // ViewChild for Line Chart Canvas 
  @ViewChild('lineChartCanvas', { static: true }) lineChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('lineFlowLikeChartCanvas', { static: true }) lineFlowLikeChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('lineFlowSaveChartCanvas', { static: true }) lineFlowSaveChartCanvas: ElementRef<HTMLCanvasElement>;  
  @ViewChild('lineFlowViewChartCanvas', { static: true }) lineFlowViewChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('lineFlowCommentChartCanvas', { static: true }) lineFlowCommentChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('lineFlowLinkClickChartCanvas', { static: true }) lineFlowLinkClickChartCanvas: ElementRef<HTMLCanvasElement>;
  public pieChartOptions: any = {
    responsive: true,
  };

  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  public lineViewChartLabels: string[] = [];
  public lineChartLabels: string[] = [];
  public lineFlowChartLabels: string[] = [];
  public lineFlowSaveChartLabels: string[] = [];
  public lineFlowViewChartLabels: string[] = [];
  public lineFlowCommentChartLabels: string[] = [];
  public lineFlowLinkClickChartLabels: string[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartData: number[] = [];
  public lineFlowChartData: number[] = [];
  public lineFlowSaveChartData: number[] = [];
  public lineFlowCommentChartData: number[] = [];
  public lineFlowViewChartData: number[] = [];
  public lineFlowLinkClickChartData: number[] = [];

  returningUsersCount: number = 0;
  newUsersCount: number = 0;
  views: number = 0;

  public pieChartLabels: string[] = ['Label 1', 'Label 2', 'Label 3'];
  public pieChartData: number[] = [300, 500, 200];
  public pieChartType: string = 'pie';
  public pieChartLegend: boolean = true;
  pageFlowContent: any;
  pageFlowCommentContent: any;
  pageFlowSaveContent: any;
  pageFlowViewContent: any;
  pageFlowLinkClickContent: any; 
  /**
   *
   */
  constructor(private flowservice: FlowServiceService,
    private router: Router,
    public dialogRef: MatDialogRef<AnalyticsComponent>,) {

  }

  ngAfterViewInit() { 
  }

  ngOnInit(): void {
    Chart.register(...registerables);

    this.flowData = JSON.parse(localStorage.getItem("analyticsflow")); 
    console.log(this.flowData);
    this.views = this.flowData.viewFlows;
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.userEmail = "";

    if (this.userData != null) {
      this.userEmail = this.userData.emailAddress;
    } 
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress 
    });
    this.getUserDashboard(flowQueryModel);
    this.getPageViews();
    this.getFlowLikeCount();
    this.getFlowCommentCount();
    this.getFlowSaveCount();
   // this.getFlowViewCount();
    this.getFlowLinkClickCount();
  }


  close() {
    this.dialogRef.close();
  }

  getUserDashboard(flowQueryModel) {
    this.noResultReturned = true;
    var flowModel=new FlowQueryModel({
     FlowType :this.flowData.flowType,
     FlowId : this.flowData.id
    }); 
    this.flowservice.getLinkClickDetails(flowModel).subscribe((data) => { 
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.linksClicked = data;
    });
  }

  getPageViews() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      FlowId: this.flowData.id,  
      ContentType: this.flowData.flowType, 
    }; 
    this.flowservice.getPageViewDetails(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.pageViews = data;
      //this.setNewandReturningUsers();
      this.pageViewsOverThePeriod();
    });
  }

  getFlowLikeCount() {
    this.noResultReturned = true; 
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.flowData.id,
      userEmail: this.userData.emailAddress ,
      FlowType :this.flowData.flowType,
    });
    var getPageViewDetails = {
      FlowId: this.flowData.id,  
      ContentType: this.flowData.flowType, 
    }; 
    this.flowservice.getContentLikeCount(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false; 
      this.pageFlowContent = data;
      console.log(this.pageFlowContent);
      //this.setNewandReturningUsers();
      this.pageFlowLikeOverThePeriod();
    });
  }

  getFlowViewCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      FlowId: this.flowData.id,  
      ContentType: this.flowData.flowType, 
    }; 
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.flowData.id,
      userEmail: this.userData.emailAddress ,
      FlowType :this.flowData.flowType,
    });
    this.flowservice.getContent(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false; 
      this.pageFlowViewContent = data;
      console.log(this.pageFlowViewContent);
      //this.setNewandReturningUsers();
      this.pageFlowViewOverThePeriod();
    });
  }

  getFlowSaveCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      FlowId: this.flowData.id,  
      ContentType: this.flowData.flowType, 
    }; 
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.flowData.id,
      userEmail: this.userData.emailAddress ,
      FlowType :this.flowData.flowType,
    });
    this.flowservice.getContentSaveCount(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false; 
      this.pageFlowSaveContent = data;
      console.log(this.pageFlowSaveContent);
      //this.setNewandReturningUsers();
      this.pageFlowSaveOverThePeriod();
    });
  }

  getFlowCommentCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      FlowId: this.flowData.id,  
      ContentType: this.flowData.flowType, 
    }; 
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.flowData.id,
      userEmail: this.userData.emailAddress ,
      FlowType :this.flowData.flowType,
    });
    this.flowservice.getContentCommentCount(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false; 
      this.pageFlowCommentContent = data;
      console.log(this.pageFlowCommentContent);
      //this.setNewandReturningUsers();
      this.pageFlowCommentOverThePeriod();
    });
  }
  getFlowLinkClickCount() {
    this.noResultReturned = true;
    var flowModel=new FlowQueryModel({
      FlowType :this.flowData.flowType,
      FlowId : this.flowData.id
     }); 
    this.flowservice.getLinkClickDetails(flowModel).subscribe((data) => {
      this.noResultReturned = false; 
      this.pageFlowLinkClickContent = data;
      console.log(this.pageFlowLinkClickContent);
      //this.setNewandReturningUsers(); 
      this.pageFlowLinkClickOverThePeriod();
    });
  } 
  pageViewsOverThePeriod() {
    this.extractDateRange();
    this.updateChartData();
    this.destroyChart(this.lineChartCanvas);
    this.initLineChart(); 
  }
  pageFlowLikeOverThePeriod() {
    this.extractFlowLikeDateRange();
    this.updateFlowLikeChartData();
    this.destroyChart(this.lineFlowLikeChartCanvas);
    this.initFlowLikeLineChart();
  }
  pageFlowViewOverThePeriod() {
    this.extractFlowViewDateRange();
    this.updateFlowViewChartData();
    this.destroyChart(this.lineFlowViewChartCanvas);
    this.initFlowViewLineChart();
  }
  pageFlowCommentOverThePeriod() {
    this.extractFlowCommentDateRange();
    this.updateFlowCommentChartData();
    this.destroyChart(this.lineFlowCommentChartCanvas);
    this.initFlowCommentLineChart();
  }

  pageFlowLinkClickOverThePeriod() {
    this.extractFlowLinkClickDateRange();
    this.updateFlowLinkClickChartData();
    this.destroyChart(this.lineFlowLinkClickChartCanvas);
    this.initFlowLinkClickLineChart();
  }
  pageFlowSaveOverThePeriod() {
    this.extractFlowSaveDateRange();
    this.updateFlowSaveChartData();
    this.destroyChart(this.lineFlowSaveChartCanvas);
    this.initFlowSaveLineChart();
  }
  extractDateRange() {
    if (this.pageViews && this.pageViews.length > 0) {
  
      // Step 1: Store both original and formatted dates
      const formattedDates = this.pageViews.map(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
  
        // Format date to dd/mm/yy
        const formattedDate = datePipe.transform(myDate, 'dd/MM/yy');
        return { originalDate: myDate, formattedDate };
      });
  
      // Step 2: Sort using the original date
      formattedDates.sort((a, b) => new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime());
  
      // Step 3: Extract sorted formatted dates
      this.lineViewChartLabels = formattedDates.map(item => item.formattedDate); 
    }
  }
  

  private updateChartData() { 
    let totalViews = 0;
    console.log(this.pageFlowContent);
    this.pageViews.forEach((element: any) => {
      totalViews += element.numberOfViews;
      console.log("Updated Views:", totalViews);
      this.lineChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineChartData);
    });
  }

  initLineChart() { 
    // Your line chart configuration here
    const lineChartCtx = this.lineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineViewChartLabels,
        datasets: [{
          data: this.lineChartData,
          label: 'Views Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        // Your line chart options here
      }
    });
  }
  destroyChart(canvas: ElementRef<HTMLCanvasElement>) {
    const ctx = canvas.nativeElement.getContext('2d');
    Chart.getChart(ctx)?.destroy(); // Destroy existing chart instance if it exists
  }

  extractFlowLikeDateRange() {
    if (this.pageFlowContent && this.pageFlowContent.length > 0) {
  
      // Step 1: Store original and formatted dates
      const formattedDates = this.pageFlowContent.map(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
  
        // Format date to dd/mm/yy
        const formattedDate = datePipe.transform(myDate, 'dd/MM/yy');
        return { originalDate: myDate, formattedDate };
      });
  
      // Step 2: Sort using original date
      formattedDates.sort((a, b) => new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime());
  
      // Step 3: Extract sorted formatted dates
      this.lineFlowChartLabels = formattedDates.map(item => item.formattedDate); 
    }
  }
  

  private updateFlowLikeChartData() { 
    let totalViews = 0;
    console.log(this.pageFlowContent);
    this.pageFlowContent.forEach((element: any) => {
      totalViews += element.numberOfViews;
      console.log("Updated totalViews:", totalViews);
      this.lineFlowChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineFlowChartData);
    });
  }

  initFlowLikeLineChart() { 
    const lineChartCtx = this.lineFlowLikeChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineFlowChartLabels,
        datasets: [{
          data: this.lineFlowChartData,
          label: 'Likes Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }

  extractFlowViewDateRange() {
    if (this.pageFlowViewContent && this.pageFlowViewContent.length > 0) {
  
      // Step 1: Store original and formatted dates
      const formattedDates = this.pageFlowViewContent.map(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
  
        // Format date to dd/mm/yy
        const formattedDate = datePipe.transform(myDate, 'dd/MM/yy');
        return { originalDate: myDate, formattedDate };
      });
  
      // Step 2: Sort using original date
      formattedDates.sort((a, b) => new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime());
  
      // Step 3: Extract sorted formatted dates
      this.lineFlowViewChartLabels = formattedDates.map(item => item.formattedDate);
   
    }
  }
  

  private updateFlowViewChartData() { 
    let totalViews = 0;
    console.log(this.pageFlowViewContent);
    this.pageFlowViewContent.forEach((element: any) => {
      totalViews += element.viewCount;
      console.log("Updated totalViews:", totalViews);
      this.lineFlowViewChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineFlowViewChartData);
    });
  }

  initFlowViewLineChart() { 
    const lineChartCtx = this.lineFlowViewChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineFlowViewChartLabels,
        datasets: [{
          data: this.lineFlowViewChartData,
          label: 'Views Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }


  extractFlowSaveDateRange() {
    if (this.pageFlowSaveContent && this.pageFlowSaveContent.length > 0) {
  
      // Step 1: Map to extract and format dates
      const formattedDates = this.pageFlowSaveContent.map(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
  
        // Format date to dd/mm/yy
        const formattedDate = datePipe.transform(myDate, 'dd/MM/yy');
        return { originalDate: myDate, formattedDate };
      });
  
      // Step 2: Sort by original date
      formattedDates.sort((a, b) => new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime());
  
      // Step 3: Populate chart labels
      this.lineFlowSaveChartLabels = formattedDates.map(item => item.formattedDate); 
    }
  }
  

  private updateFlowSaveChartData() { 
    let totalViews = 0;
    console.log(this.pageFlowSaveContent);
    this.pageFlowSaveContent.forEach((element: any) => {
      totalViews += element.numberOfViews;
      console.log("Updated totalSaves:", totalViews);
      this.lineFlowSaveChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineFlowChartData);
    });
  }

  initFlowSaveLineChart() { 
    const lineChartCtx = this.lineFlowSaveChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineFlowSaveChartLabels,
        datasets: [{
          data: this.lineFlowSaveChartData,
          label: 'Saves Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }


  extractFlowCommentDateRange() {
    if (this.pageFlowCommentContent && this.pageFlowCommentContent.length > 0) {
  
      // Step 1: Map to extract and format dates
      const formattedDates = this.pageFlowCommentContent.map(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
  
        // Format date to dd/mm/yy
        const formattedDate = datePipe.transform(myDate, 'dd/MM/yy');
        return { originalDate: myDate, formattedDate };
      });
  
      // Step 2: Sort by original date
      formattedDates.sort((a, b) => new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime());
  
      // Step 3: Populate chart labels
      this.lineFlowCommentChartLabels = formattedDates.map(item => item.formattedDate); 
    }
  }
  

  private updateFlowCommentChartData() { 
    let totalViews = 0;
    console.log(this.pageFlowCommentContent);
    this.pageFlowCommentContent.forEach((element: any) => {
      totalViews += element.numberOfViews;
      console.log("Updated totalViews:", totalViews);
      this.lineFlowCommentChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineFlowCommentChartData);
    });
  }

  initFlowCommentLineChart() { 
    const lineChartCtx = this.lineFlowCommentChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineFlowCommentChartLabels,
        datasets: [{
          data: this.lineFlowCommentChartData,
          label: 'Comments Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }

  extractFlowLinkClickDateRange() {
    if (this.pageFlowLinkClickContent && this.pageFlowLinkClickContent.length > 0) {
  
      // Step 1: Map to extract and format dates
      const formattedDates = this.pageFlowLinkClickContent.map(element => {
        const myDate = element.linkClickModels[0]?.createdDateTime;
        const datePipe = new DatePipe('en-US');
  
        // Ensure the date exists before formatting
        const formattedDate = myDate ? datePipe.transform(myDate, 'dd/MM/yy') : null;
        return { originalDate: myDate, formattedDate };
      }).filter(item => item.formattedDate); // Remove any invalid dates
  
      // Step 2: Sort by original date
      formattedDates.sort((a, b) => new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime());
  
      // Step 3: Populate chart labels
      this.lineFlowLinkClickChartLabels = formattedDates.map(item => item.formattedDate); 
    }
  }
  

  private updateFlowLinkClickChartData() { 
    let totalViews = 0;
    console.log(this.pageFlowLinkClickContent);
    this.pageFlowLinkClickContent.forEach((element: any) => {
      totalViews += element.totalCount;
      console.log("Updated totalClicks:", totalViews);
      this.lineFlowLinkClickChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineFlowLinkClickChartData);
    });
  }

  initFlowLinkClickLineChart() { 
    const lineChartCtx = this.lineFlowLinkClickChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineFlowLinkClickChartLabels,
        datasets: [{
          data: this.lineFlowLinkClickChartData,
          label: 'LinkClicks Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }


  setNewandReturningUsers() {
    const uniqueEmails = new Set<string>();

    this.pageViews.forEach(element => {
      // Check if the email is already in the set
      if (element.userEmail != "" && uniqueEmails.has(element.userEmail)) {
        // User is a returning user
        this.returningUsersCount++;
      } else {
        // User is a new user
        // Add the email to the set
        uniqueEmails.add(element.userEmail);
        this.newUsersCount++;
      }
    });

    const ctx = this.pieChartCanvas.nativeElement.getContext('2d');
    var arr = ['New Visitor', 'Returning Visitor']; 
    const pieChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: arr,
        datasets: [
          {
            data: [this.newUsersCount, this.returningUsersCount],
            backgroundColor: ['green', 'blue'],
          },
        ],
      },
    });
  }
}
