import { BundleService } from "src/app/Services/bundleservice.service";

import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "../../Services/flow-service.service";
import { Router, NavigationExtras } from "@angular/router";
import { UserserviceService } from "src/app/Services/userservice.service";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
@Component({
  selector: "app-flow-by-category",
  templateUrl: "./flow-by-category.component.html",
  styleUrls: ["./flow-by-category.component.scss"],
})
export class FlowByCategoryComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  flows: any;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  categories: any[] = [];
  userDisplayEmail: any;
  CategoryName: string;
  userEmail: any;
  noResultReturned: boolean;

  tempFlows: any = [];
  infiniteScrollCount: number;
  isMobileView: boolean;

  constructor(
    private activateRouter: ActivatedRoute,
    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    private router: Router,
    private userserviceService: UserserviceService,
    
    private bundleService: BundleService,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.titleandmetaserviceService.setTitle("Flows By Category Page");
    this.categories = [];
    this.userDisplayEmail = "";
    this.infiniteScrollCount = 4;
  }

  ngOnInit() {
    this.userEmail = "";
    this.CategoryName = "";

    this.flowServiceService.setScrollTop();
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.CategoryName = localStorage.getItem("flowByCategoryCategoryName");
    this.showNotificationCount = false;
    this.notificationCount = 4;

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userEmail = this.userData.emailAddress;
    }
    window.scrollTo(0, 0);
    // this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
    //   this.flows = flowData;
    //   this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    //   this.flows.forEach((element) => {
    //     this.tempFlows.push(element);
    //   });
    // });
    this.tempFlows = [];
    localStorage.removeItem("pageIndex");
    localStorage.setItem("pageIndex", "0");
    this.noResultReturned = true;
    if (this.CategoryName == "Bundles") {
      this.getBundles("0");
    } else {
      this.getFlows("0");
    }
    this.getProfileUser();
    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth >= 900) {
      this.SlideOptions = { items: 5, dots: false, nav: true };
    }
    if (this.scrWidth >= 700 && this.scrWidth < 900) {
      this.SlideOptions = { items: 4, dots: false, nav: true };
    }
    if (this.scrWidth >= 600 && this.scrWidth < 700) {
      this.SlideOptions = { items: 3, dots: false, nav: true };
    }
    if (this.scrWidth >= 479 && this.scrWidth < 600) {
      this.SlideOptions = { items: 2, dots: false, nav: true };
    }
    if (this.scrWidth < 479) {
      this.SlideOptions = { items: 2, dots: false, nav: true };
    }
    this.resize();
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  getFlows(pageIndex): void {
    this.flowServiceService
      .getFlowsByInputCategory(this.userEmail, this.CategoryName, pageIndex)
      .subscribe((data) => {
        this.noResultReturned = false;
        data.Flows.forEach((element) => {
          this.tempFlows.push(element);
        });
      });
  }

  getBundles(pageIndex): void {
    let email = "";

    if (this.userData != null) {
      email = this.userData.emailAddress;
    }

    this.flowServiceService.getBundles(email, pageIndex).subscribe((data) => {
      this.noResultReturned = false;
      data.bundles.forEach((element) => {
        this.tempFlows.push(element);
      });
    });
  }

  onScrollDown() {
    //
    this.noResultReturned = true;
    this.delay(2000).then(() => {
      this.delayedServiceCall();
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem("pageIndex");
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem("pageIndex", pageIndex);
    if (this.CategoryName == "Bundles") {
      this.getBundles(pageIndex);
    } else {
      this.getFlows(pageIndex);
    }
    // this.flowServiceService
    //   .getFlowsByInputCategory(this.userEmail, this.CategoryName, pageIndex)
    //   .subscribe((data) => {
    //     if (data.Flows.length == 0) {
    //       this.noResultReturned = false;
    //     } else {
    //       this.noResultReturned = true;

    //       data.Flows.forEach((element) => {
    //         this.tempFlows.push(element);
    //       });
    //     }
    //     
    //   });
  }

  resize() {}

  onResize(event) {
    this.resize();

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  viewUserProfile(selectedFlow) {
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedFlow.customerEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  flowClicked(flow) {
    localStorage.setItem("notificationflowId", null);
    localStorage.setItem("navigateTo", "flowbycategory");
    localStorage.setItem("flow", JSON.stringify(flow));
    if (this.CategoryName == "Bundles") {
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else {
      this.router.navigate([`/guide/${this.generateUrlSlug(flow.title)}/${flow.rowKey}`]);
    }
  }

  generateUrlSlug(html) {
    if (!html) return '';

    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            //    
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  showImage(itemCategory, item, index) {
    itemCategory.flowModels.forEach((element) => {
      if (element.arrow !== undefined) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.Url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }
}
