import { BundleService } from "src/app/Services/bundleservice.service";
import { ShowFlowImageDialogComponent } from "./../show-flow-image-dialog/show-flow-image-dialog.component";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { DialogExampleComponent } from "./../../dialog-example/dialog-example.component";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlName,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { FlowServiceService } from "src/app/Services/flow-service.service";

// import { ToastrService } from "ngx-toastr";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { EmbededComponent } from "../embeded/embeded.component";
import { CommentComponent } from "../comment/comment.component";
import { QRCodeComponent } from "../qrcode/qrcode.component";
import { EditCommentComponent } from "../edit-comment/edit-comment.component";
import { InAppropriateComponent } from "../in-appropriate/in-appropriate.component";
import { SupportDocumentComponent } from "../support-document/support-document.component";
import { RestrictedAccessComponent } from "../restricted-access/restricted-access.component";
import { PageTimeTrackerService } from "src/app/Services/page-time-tracker-service.service";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { ToastrService } from "ngx-toastr";
import { SupportFaqComponent } from "../support-faqs/support-faqs.component";
import { CheckoutNavigatorComponent } from "../checkout-navigator/checkout-navigator.component";
@Component({
  selector: "app-viewflow",
  templateUrl: "./viewflow.component.html",
  styleUrls: ["./viewflow.component.scss"],
})
export class ViewflowComponent implements OnInit, OnDestroy {
  userDisplayEmail: any;
  isPurchaseRequired: any;
  notFlowOwner: any;
  duration: any;
  totalTimeSpent: number;
  private pageStartTime: number;
  likeCount: any;
  saveCount: any;
  faqData: any;
  supportFlowFaqs: any;
  groupedFaqs: { groupName: string; faqs: any; }[];
  isFlowPaid: boolean;
  allFaqs: any[];
  showFaq: boolean;

  constructor(
    private activateRouter: ActivatedRoute,
    private dataService: DataService,
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    private bundleService: BundleService,
    private toastr: ToastrService,
    public formbuilder: UntypedFormBuilder,
    public router: Router,

    public dialog: MatDialog,
    private titleandmetaserviceService: TitleandmetaserviceService,
    private pageTimeTrackerService: PageTimeTrackerService,
    private flowservice: FlowServiceService
  ) {
    this.isFlowHomePage = true;
    this.categories = [];
    this.duration = null;
    this.flowData = null;
    this.stepText =
      "Check that you have all items required to complete the instruction";
    this.noResultReturned = false;
  }

  noResultReturned: boolean;
  comment: string;
  comments = [];
  name: string;
  showNotificationCount: boolean;
  notificationCount: number;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;
  stepText: string;
  userDisplayName: any;
  userData: any;
  flowData: any;
  showvideo: boolean;
  showimage: boolean;
  imageURI: any;
  videoURI: any;
  displayedColumns: string[] = ["position", "name"];
  dataSource = [];
  dataSourceSteps = [];
  showDefaultImage: boolean;
  commentsForm: UntypedFormGroup;
  imageSource: any;
  posterImageSource: any;
  description: any;
  nextCount: number;
  itemsRequired: number;
  currentImage: any;
  previousIndex: any;
  likecolor: any;
  viewsCount: any;
  viewText: any;
  followButtonText: any;
  followingcolor: any;
  followingbgcolor: any;
  showFollow: any;
  isFirstSlide: any;
  isLastSlide: any;
  progressPercentage: number;
  progressBy: number;
  stepCount: number;
  bookmarkcolor: any;
  stepNumber: number;
  isMobileView: boolean;
  carouselOptions: any;
  showVideoOrImage: boolean;
  showMenu: boolean;
  IsSteps: boolean;
  categories: any[] = [];
  videoPlayCounter: any = [];
  viewUnloaded: boolean;
  _steps: any = [];
  stepId: number;
  coverImageUri: string;
  title: string;
  inames: any[];
  flowId: any;
  CategoryName: string;
  tempFlows: any = [];
  isFlowHomePage: boolean;
  homeslider = { items: 1, dots: true, nav: true };
  selectedGroup: any = null;
  selectedFaq: any = null;
  imageObject = [
    {},
    {
      image: "assets/images/002.jpg",
    },
    {
      image: "assets/images/003.jpg",
    },
  ];

  question: string = '';

  // This method will be triggered on every keyup event
  onQuestionChange() {
    if (this.question) {
      this.getSupportFaqsByQuestion(this.question);
    }
  }

  conversationHistory: { question: string, answer: string, isGroupSelection: boolean }[] = []; // Conversation history array
  // Handles group selection and appends group info to conversationHistory
  onGroupSelect(group: any) {
    this.checkFlowPaid();
    // Append the selected group to conversation history
    this.conversationHistory.push({
      question: `Selected Group: ${group.groupName}`,
      answer: "", // Empty answer for group selection
      isGroupSelection: true
    });
    console.log("inside grp select");
    // Set the selected group
    this.selectedGroup = group;

  }
  isDivVisible: boolean = false; // Variable to toggle visibility

  // Toggle function to show or hide the div
  toggleDiv() {
    this.isDivVisible = !this.isDivVisible;
    console.log(this.isDivVisible);
    this.isDivVisible = true;
    console.log(this.isDivVisible);
  }

  // Close function to hide the div
  closeDiv() {
    this.isDivVisible = false;
  }
  // Handles FAQ selection and appends question to conversationHistory
  onFaqSelect(faq: any) {
    this.selectedFaq = faq;

    // Append the selected FAQ to conversation history
    this.conversationHistory.push({
      question: faq.question,
      answer: faq.answer,
      isGroupSelection: false
    });
    // Call API to fetch the answer
    this.getSupportFaqsByQuestion(faq.question);
  }

  // Method triggered when user submits a question
  onQuestionSubmit() {
    this.checkFlowPaid();
    if (this.question.trim() !== '') {
      // Add user question to conversation history
      this.conversationHistory.push({ question: this.question, answer: 'Loading...', isGroupSelection: false });

      // Simulate fetching answer from backend
      this.getSupportFaqsByQuestion(this.question);
      // Clear the input field
      this.question = '';
      this.selectedGroup = null;

      console.log("inside questionsumit");
    }
  }

  openLink(iname) {
    var url = iname.wheretofindit;
    if (this.isPurchaseRequired) return;
    else {
      if (url.includes("https://")) {
        url = url;
        window.open(url);
        this.recordLinkClick(iname);
      } else {
        url = "https://" + url;
        window.open(url);
        this.recordLinkClick(iname);
      }
    }
  }

  ViewSupportingDocuments() {
    if (this.flowData.isPaid && this.userData == null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else {
      //this.router.navigate(["/in-Appropriate"]);
      localStorage.setItem("flowData", JSON.stringify(this.flowData));
      const dialogRef = this.dialog.open(SupportDocumentComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }
  ViewSupportingFaqs() {
    if (this.supportFlowFaqs && this.userData == null && this.flowData.isPaid) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else {
      //this.router.navigate(["/in-Appropriate"]);
      localStorage.setItem("flowData", JSON.stringify(this.flowData));
      localStorage.setItem("supportFaq", JSON.stringify(this.supportFlowFaqs));
      const dialogRef = this.dialog.open(SupportFaqComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }

  checkFlowPaid() {
    console.log(this.userData);
    if (this.userData == null) {
      localStorage.setItem("flowData", JSON.stringify(this.flowData));
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else if (this.userData.emailAddress !== this.flowData.customerEmail
      && this.flowData.isPaid && this.flowData.isFlowPurchased == false) {
      this.isFlowPaid = false;
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      this.router.navigate(["/checkout"]);
    } else if (this.userData.emailAddress === this.flowData.customerEmail) {
      this.isFlowPaid = true;
    } else {
      this.isFlowPaid = true;
    }
    console.log(this.isFlowPaid);
  }
  getSupportFaqs(flowId) {
    console.log(flowId);
    var flowQueryModel = new FlowQueryModel({
      FlowId: flowId,
    });
    this.faqData = this.flowServiceService.getSupportFaq(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      console.log(data);
      if (data != null && data.length > 0) {
        this.showFaq = true;
        this.supportFlowFaqs = data;
        this.groupFaqsByGroupName();
      } else {
        this.showFaq = false;
        // this.toastr.success(
        //   "The flow you are trying to open is no longer available. Explore how-to DIY flows here"
        // );
        //this.router.navigate(["/home"]);
      }
      console.log(this.showFaq);
    });
  }

  getSupportFaqsByQuestion(question: string) {
    console.log(question);
    var flowQueryModel = new FlowQueryModel({
      category: question,
    });
    this.faqData = this.flowServiceService.getFaqDetailsByQuestion(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      if (data != null && data.answer) {
        this.supportFlowFaqs = data;
        this.conversationHistory[this.conversationHistory.length - 1].answer = this.supportFlowFaqs.answer;
      } else {
        this.conversationHistory[this.conversationHistory.length - 1].answer =
          "Sorry, I am not able to understand your question. Please type it again.";
      }
    });
  }

  groupFaqsByGroupName() {
    const groups = this.supportFlowFaqs.reduce((acc: any, faq: any) => {
      const groupName = faq.groupName;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(faq);
      return acc;
    }, {});

    this.groupedFaqs = Object.keys(groups).map(groupName => ({
      groupName: groupName,
      faqs: groups[groupName]
    }));
  }
  recordLinkClick(iname) {
    console.log(this.flowData);
    var linkClickDetails = {
      PartitionKey: this.flowData.rowKey,
      Link: iname.wheretofindit,
      LinkTitle: iname.itemkey,
      ContentTitle: this.flowData.title,
      UserEmail: this.userData.emailAddress,
      ContentType: this.flowData.type,
      ContentUrl: this.flowData.url
    };

    linkClickDetails.Link = iname.wheretofindit;
    linkClickDetails.LinkTitle = iname.itemkey;


    this.flowservice.postLinkClick(linkClickDetails).subscribe(res => {

    });
  }

  openDocument(documentName) {
    if (this.isPurchaseRequired) return;
    // documentName =
    //   "https://flowcheststorage.blob.core.windows.net/mediaimage/" +
    //   documentName;

    window.open(documentName);
  }

  ngOnDestroy(): void {
    const pageTime = this.pageTimeTrackerService.getPageTime() / 1000;

    var email = '';
    // if (this.userData != null && this.userData != undefined &&
    //   this.userData.EmailAddress != null
    //   && this.userData.EmailAddress != undefined) { email = this.userData.EmailAddress; }
    // this.flowServiceService.updateFlowTime(email, pageTime, this.flowData.rowKey)
    //   .subscribe(response => { 
    //   }, error => {
    //     console.error('Error:', error);
    //   });
  }

  ngOnInit() {
    this.pageTimeTrackerService.startTracking();
    this.flowServiceService.setScrollTop();
    this.viewUnloaded = true;
    this.videoPlayCounter.forEach((video) => {
      video.target.pause();
    });

    const width = window.innerWidth || document.body.clientWidth;
    this.dataSource = [];
    if (width <= 1024) {
      this.isMobileView = true;
    }

    this.isFirstSlide = true;
    this.progressPercentage = 0;
    this.stepNumber = 0;
    this.isLastSlide = false;
    this.commentsForm = this.formbuilder.group({
      Comment: new UntypedFormControl("", Validators.required),
    });

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    this.showNotificationCount = false;
    this.notificationCount = 4;

    this.stepText =
      "Check that you have all items required to complete the instruction";

    window.scrollTo(0, 0);

    var notificationflowId = JSON.parse(
      localStorage.getItem("notificationflowId")
    );

    // if (flowData == null && notificationflowId != null) {
    //   this.getFlowDetails(notificationflowId);
    // } else {
    this.activateRouter.paramMap.subscribe((params) => {
      var id = params.get("rowKey");
      if (id !== undefined && id != null) {
        this.getFlowDetails(id);
      }
      else {
        var flowData = JSON.parse(localStorage.getItem("flow"));
      }
    });
    //}

    this.resizeSrolling();
  }

  setFlowData() {
    console.log(this.userData);
    localStorage.setItem("flowData", JSON.stringify(this.flowData));
    localStorage.setItem("CreatorUserConsultation", null);
    if (this.userData != null) {
      if (this.userData.phoneNumber === null || this.userData.phoneNumber === "") {
        this.toastr.show("Please add phone no to procced with booking consult.");
      }
      else {
        console.log(this.flowData);
        // localStorage.setItem("CreatorUserConsultation", null);
        // localStorage.setItem("flowData", JSON.stringify(this.flowData));
        this.router.navigateByUrl("/bookconsultation");
      }
    } else {
      localStorage.setItem("returnUrl", "/bookconsultation");
      this.router.navigate(['/registration']);
    }
  }
  performFaqSearch(pageIndex) {
    this.noResultReturned = true;
    this.allFaqs = [];

    this.conversationHistory.push({ question: this.question, answer: 'Loading...', isGroupSelection: false });

    var flowQueryModel = new FlowQueryModel({
      searchString: this.question,
      userEmail: this.email,
      pageIndex: pageIndex,
      partitionKey: this.flowData.rowKey
    });

    this.userserviceService
      .searchInFaqs(flowQueryModel)
      .subscribe((data) => {
        this.question = '';
        this.selectedGroup = null;
        this.noResultReturned = false;
        if (data !== null) {
          this.allFaqs = [];
          data.allFaqs.forEach((element) => {
            console.log(element);
            if (element != null && element.answer) {
              this.supportFlowFaqs = element;
              this.conversationHistory[this.conversationHistory.length - 1].answer = this.supportFlowFaqs.answer;
            } else {
              this.conversationHistory[this.conversationHistory.length - 1].answer =
                "Sorry, I am not able to understand your question. Please type it again.";
            }
            //this.supportFlowFaqs.push(element);
            console.log(this.supportFlowFaqs);
            //this.selectedFaq.push(element);
          });
          console.log(this.selectedFaq);
        } else {
          this.conversationHistory[this.conversationHistory.length - 1].answer =
            "Sorry, I am not able to understand your question. Please type it again.";
        }
      });

  }

  recordPageView() {


    var email = '';
    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress
    }

    var getPageViewDetails = {
      ContentId: this.flowData.rowKey,
      ContentTitle: this.flowData.title,
      UserEmail: email,
      ContentType: this.flowData.type,
      ContentUrl: this.flowData.url
    };

    this.flowServiceService.postPageView(getPageViewDetails).subscribe(res => {

    });
  }

  showFlowImage(imageSource) {
    localStorage.removeItem("itemsRequiredImageToShow");
    localStorage.setItem("flowImageToShow", imageSource);
    this.dialog.open(ShowFlowImageDialogComponent, {});
  }

  getFlowDetails(flowId) {
    // 
    this.noResultReturned = true;
    var email = "";
    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      FlowId: flowId,
      userEmail: email
    });

    this.flowServiceService.getFlowById(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      if (data.flows != null) {
        this.flowData = data.flows;
        this.CheckIsUserFollowing();
        this.setFlowDetails();
        this.recordPageView();
      } else {
        this.toastr.success(
          "The flow you are trying to open is no longer available. Explore how-to DIY flows here"
        );
        this.router.navigate(["/home"]);
      }
    });

    this.getSupportFaqs(flowId);
  }

  saveFlowToServerAccessLevel(AccessType) {
    console.log(AccessType)
    var flowModel = {
      rowKey: this.flowData.rowKey,
      AccessType: AccessType,
      CustomerEmail: this.userData.emailAddress,
      uniqueId: this.flowData.uniqueId,
    };
    if (this.userData != null) {
      this.noResultReturned = true;
      this.flowServiceService
        .saveFlowToServerAccessLevel(flowModel, this.userData.emailAddress)
        .subscribe((data) => {
          this.flowData.accessType = flowModel.AccessType;
          this.noResultReturned = false;
        });
    }
  }

  restrictedAccessPage(accessType) {
    this.flowData.accessType = accessType;
    localStorage.setItem("flowModel", JSON.stringify(this.flowData));
    //this.router.navigate(["/restrictedPage"]);
    const dialogRef = this.dialog.open(RestrictedAccessComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
      var flowModel = {
        rowKey: this.flowData.rowKey,
        AccessType: accessType,
        CustomerEmail: this.userData.emailAddress,
        uniqueId: this.flowData.uniqueId,
      };
      if (this.userData != null) {
        this.noResultReturned = true;
        this.flowServiceService
          .saveFlowToServerAccessLevel(flowModel, this.userData.emailAddress)
          .subscribe((data) => {
            this.flowData.accessType = flowModel.AccessType;
            this.noResultReturned = false;
          });
      }
    });
  }

  flowByCategory(category) {
    // var categories = JSON.parse(
    //   localStorage.getItem("allcategoryflowsforviewprofilepage")
    // );

    // var categoryFlows = categories.filter((c) => c.CategoryName == category)[0];
    // localStorage.setItem("flowByCategoryCategoryName", category);
    // this.dataService.flowByCategorieschangeMessage(categoryFlows.FlowModels);
    localStorage.setItem(
      "flowByCategoryCategoryName",
      category
    );
    this.router.navigate(["/flowbycategory"]);
  }

  Editflow(flowData) {
    localStorage.removeItem("selectedCategoryName");

    var flowCoverPage = {
      coverImage: flowData.url,
      rowKey: flowData.rowKey,
      description: flowData.description,
      title: flowData.title,
      type: flowData.type,
      fileName: flowData.fileName,
      uniqueId: flowData.uniqueId,
      duration: flowData.duration,
      price: flowData.price,
      isPaid: flowData.isPaid,
      partitionKey: flowData.partitionKey
    };

    localStorage.setItem("flowCoverPageDetails", JSON.stringify(flowCoverPage));

    localStorage.setItem(
      "stepsCoverPageDetails",
      JSON.stringify(flowData.steps)
    );

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(flowData.flowSupportingDocumentsDocumentModels)
    );

    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(flowData.flowSupportingDocumentsHyperLinkModels)
    );

    localStorage.setItem("ItemsRequired", JSON.stringify(flowData.components));
    localStorage.removeItem("draftWord");
    localStorage.setItem("editWord", "Edit Content");
    this.router.navigate(["/create-flow"]);
  }

  showToast() {
    this.toastr.success("Flow Liked");
  }

  showToastForUnlike() {
    this.toastr.success("Flow Unliked");
  }

  showToastForDelete() {
    this.toastr.success("Flow successfully Deleted");
  }

  setFlowDetails() {
    var lightBoxImage;
    this.showVideoFromFile(this.flowData.url);
    // this.imageSource = this.flowData.url;
    // this.imageSource = lightBoxImage;
    localStorage.setItem("flowUrl", JSON.stringify(this.imageSource));
    this.flowId = this.flowData.rowKey;
    this.description = this.flowData.description;
    this.posterImageSource = this.flowData.videoThumbNailUrl;
    this.nextCount = 0;
    this.itemsRequired = 0;
    this.stepCount = 0;
    var stepIncrementCount = 0;
    localStorage.setItem("flow", JSON.stringify(this.flowData));
    this.titleandmetaserviceService.setTitle(this.flowData.title);


    if (this.flowData === "default message") {
      this.flowData = JSON.parse(localStorage.getItem("recentflow"));
    }

    if (this.flowData.components.length > 0) {
      this.dataSource = this.flowData.components;
      stepIncrementCount = 2;
    } else {
      stepIncrementCount = 1;
    }

    localStorage.removeItem("pageIndex");
    localStorage.setItem("pageIndex", "0");
    this.getBundles("0");
    const steps = this.flowData.steps.length + stepIncrementCount;
    this.progressBy = 100 / steps;
    this.progressPercentage = 100 / steps;

    if (this.flowData.steps.length > 0) {
      this.IsSteps = true;
      this.loadSteps(this.flowData.steps);
    }

    if (this.flowData.isLiked === "liked") {
      this.likecolor = true;
      this.likeCount = this.flowData.likeCount;
    } else if (this.flowData.isLiked === "unliked") {
      this.likecolor = false;
      this.likeCount = this.flowData.likeCount;
    }

    if (this.flowData.isSaved === true) {
      this.bookmarkcolor = "#5ea1d8";
      this.saveCount = this.flowData.saveCount;
    } else {
      this.bookmarkcolor = "gray";
      this.saveCount = this.flowData.saveCount;
    }

    this.viewsCount = this.flowData.viewsCount;
    if (this.flowData.viewsCount > 1) {
      this.viewText = "Views";
    } else this.viewText = "View";

    // this.isFlowLiked();

    this.getProfileUser();

    // this.getProfilepic();
    //this.noResultReturned = false;
  }

  InAppropriate() {
    if (this.userData === null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else {
      //this.router.navigate(["/in-Appropriate"]);
      localStorage.setItem("flowData", JSON.stringify(this.flowData));
      const dialogRef = this.dialog.open(InAppropriateComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }
  checkoutconfirmpopup() {
    if (this.userData === null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else {
      //this.router.navigate(["/in-Appropriate"]);
      console.log(this.tempFlows);
      console.log(this.flowData);
      localStorage.setItem("flowData", JSON.stringify(this.flowData));
      localStorage.setItem("BundleListToShare", JSON.stringify(this.tempFlows));
      const dialogRef = this.dialog.open(CheckoutNavigatorComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }

  openDialog() {
    this.dialog.open(DialogExampleComponent, {
      height: "180px",
      width: "250px",
    });
  }

  deleteFlow() {
    var flowModel = {
      rowKey: this.flowData.rowKey,
      CustomerEmail: this.userData.emailAddress,
      FlowStatus: "Approved",
    };

    //this.noResultReturned = true;
    //
    var navigateTo = JSON.parse(localStorage.getItem("navigateTo"));
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData != null) {
      this.flowServiceService
        .deleteFlow(flowModel)

        .subscribe((data) => {
          // 
          //this.noResultReturned = false;
          this.showToastForDelete();
          if (data.Deleted) {
            this.router.navigate(["/" + navigateTo]);
          }
        });
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize(event);
  }

  resize(event) {
    if (event.srcElement.outerWidth <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  onResize(event) {
    this.resize(event);
  }

  purchaseFlow() {
    if (this.tempFlows.length <= 0) {
      if (
        this.flowData.price != null &&
        this.flowData.isPaid &&
        this.userData == null
      ) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
        // this.router.navigate(['/login'], {
        //   queryParams: { returnUrl: this.router.url }
        // }); 
        localStorage.setItem("returnUrl", this.router.url);
        this.router.navigate(['/registration']);
      } else if (
        this.flowData.price != null &&
        this.flowData.isPaid &&
        !this.flowData.isFlowPurchased &&
        this.userData.emailAddress != this.flowData.customerEmail
      ) {
        if (this.userData != null) {
          localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
          this.router.navigate(["/checkout"]);
        } else {
          //   this.router.navigate(['/login'], {
          //   queryParams: { returnUrl: this.router.url }
          // }); 
          localStorage.setItem("returnUrl", this.router.url);
          this.router.navigate(['/registration']);
        }
      }
    } else {
      this.checkoutconfirmpopup();
    }
  }

  getProfileUser(): void {
    if (this.userData != null) {
      //this.noResultReturned = true;
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }

          this.email = data.userData.emailAddress;
          this.phone = data.userData.PhoneNumber;
          this.info = data.userData.Info;
          this.website = data.userData.Website;
          this.userDisplayName = data.userData.UserName;
          this.name = data.userData.FirstName;

          if (
            this.userData != null &&
            this.userData.emailAddress === this.flowData.customerEmail
          ) {
            this.showMenu = true;
            this.isPurchaseRequired = false;
            this.notFlowOwner = false;
          } else {
            this.showMenu = false;
            this.isPurchaseRequired =
              !this.flowData.isFlowPurchased && this.flowData.isPaid;
            this.notFlowOwner = true;
          }
        });
    } else {
      this.isPurchaseRequired =
        !this.flowData.isFlowPurchased && this.flowData.isPaid;
      this.notFlowOwner = true;
    }
  }

  checkLoggedIn() {
    if (this.userData === null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }

  moveOnCoverPage() {
    this.stepNumber = 0;
    this.stepCount = 0;
    this.nextCount = 0;

    this.imageSource = this.flowData.url;
    this.description = this.flowData.description;
    this.posterImageSource = this.flowData.videoThumbNailUrl;
    this.isFirstSlide = true;
    this.isFlowHomePage = true;
    this.showVideoFromFile(this.flowData.url);
  }

  prevClicked() {
    if (this.progressPercentage > 0) {
      this.progressPercentage = this.progressPercentage - this.progressBy;
    }
    this.isLastSlide = false;
    this.nextCount = this.nextCount - 1;
    if (this.nextCount === 0) {
      this.itemsRequired = 0;
      this.stepNumber = 0;
      this.stepCount = 0;
      this.imageSource = this.flowData.url;
      this.description = this.flowData.description;
      this.posterImageSource = this.flowData.videoThumbNailUrl;
      this.isFirstSlide = true;
      this.isFlowHomePage = true;
      this.showVideoFromFile(this.flowData.url);
      this.selectedStepIndex = -2;
    } else {
      if (this.nextCount === 1) {
        if (this.dataSource.length > 0) {
          this.itemsRequired = 1;
          this.description = this.stepText;
          this.stepNumber = this.stepNumber - 1;
          this.selectedStepIndex = -1;
        } else if (this.stepCount == 0) {
          this.itemsRequired = 0;
          this.stepNumber = 0;
          this.stepCount = 0;
          this.imageSource = this.flowData.url;
          this.description = this.flowData.description;
          this.posterImageSource = this.flowData.videoThumbNailUrl;
          this.selectedStepIndex = -2;
          this.isFirstSlide = true;
          this.isFlowHomePage = true;
          this.showVideoFromFile(this.flowData.url);
        } else {
          this.stepNumber = this.stepNumber - 1;
          this.stepCount = this.stepCount - 1;
          this.selectedStepIndex = this.stepCount - 1;
          this.description =
            this.flowData.steps[this.stepCount - 1].description;
          this.imageSource = this.flowData.steps[this.stepCount - 1].url;
          this.posterImageSource = this.flowData.videoThumbNailUrl;
          localStorage.setItem(
            "imageSource",
            JSON.stringify(this.flowData.steps[this.stepCount].url)
          );

          this.showVideoFromFile(this.imageSource);
        }
      } else {
        this.stepNumber = this.stepNumber - 1;
        this.stepCount = this.stepCount - 1;
        this.selectedStepIndex = this.stepCount - 1;
        this.description = this.flowData.steps[this.stepCount - 1].description;
        this.imageSource = this.flowData.steps[this.stepCount - 1].url;
        this.posterImageSource = this.flowData.videoThumbNailUrl;
        localStorage.setItem(
          "imageSource",
          JSON.stringify(this.flowData.steps[this.stepCount].url)
        );

        this.showVideoFromFile(this.imageSource);
      }
    }
  }

  goToQrCode(flowData) {
    // localStorage.setItem("flow", JSON.stringify(flowData));
    // this.router.navigate(["/qrcode"]);
    localStorage.setItem("flow", JSON.stringify(flowData));
    const dialogRef = this.dialog.open(QRCodeComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  nextClicked() {
    this.isFlowHomePage = false;

    if (
      this.userData == null &&
      this.flowData.price != null &&
      this.flowData.isPaid
    ) {
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // });
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else if (
      this.userData != null &&
      !this.flowData.isFlowPurchased &&
      this.flowData.price != null &&
      this.flowData.isPaid &&
      this.userData.emailAddress !== this.flowData.customerEmail
    ) {
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      this.router.navigate(["/checkout"]);
    } else {
      if (this.progressPercentage < 100) {
        this.progressPercentage += this.progressBy;
      }

      this.nextCount++;
      this.isFirstSlide = false;

      if (this.nextCount === 1) {
        if (this.dataSource.length > 0) {
          this.itemsRequired = 1;
          this.selectedStepIndex = -1;
          this.description = this.stepText;
        } else if (this.stepCount < this.flowData.steps.length) {
          this.updateStepData(this.stepCount);
        }
      } else if (this.stepCount < this.flowData.steps.length) {
        this.updateStepData(this.stepCount);
      }

      this.isLastSlide = this.progressPercentage >= 99;
    }
  }

  private updateStepData(stepIndex: number) {
    console.log(stepIndex);
    this.selectedStepIndex = stepIndex;
    this.description = this.flowData.steps[stepIndex].description;
    this.imageSource = this.flowData.steps[stepIndex].url;
    this.posterImageSource = this.flowData.videoThumbNailUrl;
    this.stepNumber++;
    this.stepCount++;
    this.itemsRequired = 2;
    this.showVideoFromFile(this.imageSource);
    localStorage.setItem("imageSource", JSON.stringify(this.imageSource));
  }

  isSeparator(value: string) {
    if (value === "/" || value === "\\" || value === ":") return true;
  }

  getExtension = (path: string): string => {
    for (let i = path.length - 1; i > -1; --i) {
      const value = path[i];
      if (value === ".") {
        if (i > 1) {
          if (this.isSeparator(path[i - 1])) {
            return "";
          }
          return path.substring(i + 1);
        }
        return "";
      }
      if (this.isSeparator(value)) {
        return "";
      }
    }
    return "";
  };
  isToggled: boolean = false;
  coverPage: boolean = true;
  selectedStepIndex = -2; // Default first step
  steps: any[] = []; 

  CheckUserLoggedIn() {
    if (
      this.userData == null &&
      this.flowData.price != null &&
      this.flowData.isPaid
    ) {
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    } else if (
      this.userData != null &&
      !this.flowData.isFlowPurchased &&
      this.flowData.price != null &&
      this.flowData.isPaid &&
      this.userData.emailAddress !== this.flowData.customerEmail
    ) {
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      this.router.navigate(["/checkout"]);
    }
  }
  // selectStep(index: number) {
  //   this.selectedStepIndex = index;
  //   this.itemsRequired = 0;
  //   this.coverPage = false;
  //   this.isFlowHomePage = false;
  //   this.stepNumber = 0;
  //   this.nextCount = 0;
  //   this.stepCount = 0;
  //   if (index === -2) {
  //     this.isFlowHomePage = true;
  //     this.coverPage = true;
  //     this.imageSource = this.flowData.url;
  //     this.description = this.flowData.description;
  //     this.posterImageSource = this.flowData.videoThumbNailUrl;
  //     this.isFirstSlide = true;
  //     this.isFlowHomePage = true;
  //     this.showVideoFromFile(this.flowData.url);
  //   } else if (index === -1) {
  //     this.CheckUserLoggedIn();
  //     this.description = this.stepText;
  //     this.itemsRequired = 1;
  //   } else {
  //     this.CheckUserLoggedIn();
  //     this.description = this.flowData.steps[index].description;
  //     this.imageSource = this.flowData.steps[index].url;
  //     this.showVideoFromFile(this.imageSource);
  //     localStorage.setItem("imageSource", JSON.stringify(this.imageSource));
  //     // this.selectedStepIndex = index;
  //     this.stepNumber = index + 1;
  //     this.stepCount = index + 1;
  //     this.nextCount = index + 1
  //     console.log(`Step ${index + 1} selected`);
  //   }
  // }
  NextStep() {
    this.imageSource = null;
    this.description = null;
    this.selectedStepIndex = this.selectedStepIndex + 1;
    this.itemsRequired = 0;
    this.coverPage = false;
    this.isFlowHomePage = false;
    this.stepNumber = 0;
    this.nextCount = 0;
    this.stepCount = 0; 
    this.isFirstSlide = false;

    if (this.selectedStepIndex === -2) {
      this.setCoverPage();
    } else if (this.selectedStepIndex === -1) {
      this.setItemsPage();
    } else {
      this.setStep(this.selectedStepIndex);
      if(this.selectedStepIndex+1 === this.flowData.steps.length){
        this.isLastSlide = true ;
      }
      console.log(this.isLastSlide);
      console.log(this.selectedStepIndex+1);
      console.log(this.flowData.steps.length);
    }
  }
  PreviousStep() {
    this.imageSource = null;
    this.description = null;
    this.selectedStepIndex = this.selectedStepIndex - 1;
    this.itemsRequired = 0;
    this.coverPage = false;
    this.isFlowHomePage = false;
    this.stepNumber = 0;
    this.nextCount = 0;
    this.stepCount = 0;
    this.isFirstSlide = false;
    this.isLastSlide = false ;
    if (this.selectedStepIndex === -2) {
      this.setCoverPage();
      this.isFirstSlide = true;
    } else if (this.selectedStepIndex === -1) {
      this.setItemsPage(); 
    } else {
      this.setStep(this.selectedStepIndex);
    } 
  }
  setStepData(index) {
    this.selectedStepIndex = index;
    var path = this.getExtension(this.flowData.steps[index].url);
    if (path.includes("PNG") || path.includes("JPEG") || path.includes("jpeg")|| 
    path.includes("JPG") || path.includes("jpg") || path.includes("png") || path.includes("jfif")) {
      this.showVideoOrImage = true;
    }else{
      this.showVideoOrImage = false;
    } 
  }
  selectStepAdvanced(index: number) {
    this.selectedStepIndex = index;
    this.itemsRequired = 0;
    this.coverPage = false;
    this.isFlowHomePage = false;
    this.stepNumber = 0;
    this.nextCount = 0;
    this.stepCount = 0;
    this.isLastSlide = false;
    if (index === -2) {
      this.setCoverPage();
    } else if (index === -1) {
      this.setItemsPage();
    } else {
      this.setStep(index);
    }
  }
  private setCoverPage() {
    this.isFlowHomePage = this.coverPage = true;
    this.imageSource = this.flowData.url;
    this.description = this.flowData.description;
    this.posterImageSource = this.flowData.videoThumbNailUrl;
    this.isFirstSlide = true;
    this.showVideoFromFile(this.flowData.url);
  }

  private setItemsPage() {
    this.CheckUserLoggedIn();
    this.description = this.stepText;
    this.itemsRequired = 1;
    this.nextCount = 1;
    this.stepCount = 0;
    this.isFirstSlide = false;
  }
  
  private setStep(index: number) {
    this.isFirstSlide = false;
    if(this.selectedStepIndex+1 === this.flowData.steps.length){
      this.isLastSlide = true ;
    }
    this.CheckUserLoggedIn();
    const step = this.flowData.steps[index];
    if (!step) return; // Prevent potential errors 
    if (this.isToggled) {
      this.setStepData(index);
    } else {
      this.imageSource = step.url;
      this.description = step.description;
      this.showVideoFromFile(this.imageSource);
      localStorage.setItem("imageSource", JSON.stringify(this.imageSource));
      this.stepNumber = index + 1;
      this.stepCount = index + 1;
      this.nextCount = index + 1;
      console.log(`Step ${this.stepNumber} selected`);
    }  
  }


  onToggleChange(event: any) {
    this.isToggled = event.checked;
    // this.selectedStepIndex = -2
    // this.stepNumber = 0;
    // this.selectStep(-2);
    this.selectStepAdvanced(-2);
  }

  showVideoFromFile(filePath: string) {
    var path = this.getExtension(filePath);
    if (
      path.includes("MP4") ||
      path.includes("mp4") ||
      path.includes("MOV") ||
      path.includes("mov") ||
      path.includes("WMV") ||
      path.includes("wmv") ||
      path.includes("FLV") ||
      path.includes("flv") ||
      path.includes("AVCHD") ||
      path.includes("avchd") ||
      path.includes("WEBM") ||
      path.includes("webm") ||
      path.includes("MKV") ||
      path.includes("mkv") ||
      path.includes("VOB") ||
      path.includes("vob") ||
      path.includes("OGV") ||
      path.includes("ogv") ||
      path.includes("GIFY") ||
      path.includes("gifv") ||
      path.includes("M4P") ||
      path.includes("m4p") ||
      path.includes("M4V") ||
      path.includes("m4v") ||
      path.includes("3GP") ||
      path.includes("3gp") ||
      path.includes("3G2") ||
      path.includes("3g2") ||
      path.includes("AMV") ||
      path.includes("amv") ||
      path.includes("NSV") ||
      path.includes("nsv") ||
      path.includes("OGG") ||
      path.includes("ogg") ||
      path.includes("FLV") ||
      path.includes("flv") ||
      path.includes("F4V") ||
      path.includes("f4v") ||
      path.includes("F4P") ||
      path.includes("f4p") ||
      path.includes("F4A") ||
      path.includes("f4a") ||
      path.includes("F4B") ||
      path.includes("f4b")
    ) {
      this.showVideoOrImage = true;
      this.imageSource = filePath;
    } else {
      this.showVideoOrImage = false;
      this.imageSource = filePath;
    }
    console.log(this.showVideoOrImage);
  }

  isFlowViewed() {
    if (this.flowData != null && this.flowData.rowKey > 0) {
      //this.noResultReturned = true;
      //
      const flowModel = {
        FlowId: this.flowData.rowKey,
      };

      this.flowServiceService.getIsFlowViewed(flowModel).subscribe((data) => {
        this.viewsCount = data.viewsCount;
        if (this.viewsCount > 1) {
          this.viewText = "Views";
        } else this.viewText = "View";
        //this.noResultReturned = false;
        //
        this.CheckIsUserFollowing();
      });
    }
  }

  isFlowLiked() {
    if (this.userData != null) {
      //
      //this.noResultReturned = true;
      const flowModel = {
        FlowId: this.flowData.rowKey,
        LikedByUserEmail: this.userData.emailAddress,
      };

      this.flowServiceService.isFlowLiked(flowModel).subscribe((data) => {
        //this.noResultReturned = false;
        if (data.IsLiked === "liked") {
          this.likecolor = true;
        } else if (data.IsLiked === "unliked") {
          this.likecolor = false;
        }

        //
        this.CheckIsUserFollowing();
      });
    }
  }

  viewUserProfile(selectedFlow) {
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedFlow.customerEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }

  likeFlow() {
    if (this.userData != null) {
      const flowModel = {
        PartitionKey: this.flowData.rowKey,
        LikedByUserEmail: this.userData.emailAddress,
      };

      this.flowServiceService.LikeFlow(flowModel).subscribe((data) => {
        //this.noResultReturned = false; 
        console.log('liked', this.flowData);
        this.flowData.likedFlowCount = data.totalLikes;
        if (data.liked === "liked") {
          this.likecolor = true;
          this.flowData.likeCount = this.flowData.likeCount + 1;
          this.showToast();
        } else if (data.liked === "unliked") {
          this.likecolor = false;
          this.flowData.likeCount = this.flowData.likeCount - 1;
          this.showToastForUnlike();
        }
        this.viewsCount = data.viewsCount;

        if (this.viewsCount > 1) {
          this.viewText = "Views";
        } else {
          this.viewText = "View";
        }

        //
      });
    } else {
      // this.router.navigate(['/registration'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }

  CheckIsUserFollowing() {
    if (this.userData != null) {
      if (this.userData.emailAddress === this.flowData.customerEmail) {
        this.showFollow = false;
      } else {
        //
        //this.noResultReturned = true;
        this.showFollow = true;

        const flowModel = {
          CustomerEmail: this.flowData.customerEmail,
          UserEmailAddress: this.userData.emailAddress,
        };

        this.flowServiceService
          .CheckIsUserFollowing(flowModel)
          .subscribe((data) => {
            if (data.messageText === "Following") {
              this.followButtonText = "Following";
              this.followingcolor = "#5ea1d8";
              this.followingbgcolor = "white";
            } else {
              this.followButtonText = "Follow";
              this.followingcolor = "white";
              this.followingbgcolor = "#5ea1d8";
            }
            // this.noResultReturned = false;
            // 
          });
      }
    }
  }

  followUser() {
    if (this.userData != null) {
      // 
      //this.noResultReturned = true;
      const flowModel = {
        FollowerEmail: this.userData.emailAddress,
        UserEmail: this.flowData.customerEmail,
      };

      this.flowServiceService.FollowUser(flowModel).subscribe((data) => {
        if (data.isFollowing === "following") {
          this.followButtonText = "Following";
          this.followingcolor = "#5ea1d8";
        } else {
          this.followButtonText = "Follow";
          this.followingcolor = "white";
          this.followingbgcolor = "#5ea1d8";
        }
        // this.noResultReturned = false;
        //
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }

  isFlowSaved() {
    if (this.userData != null) {
      // 
      const flowModel = {
        CustomerEmail: this.userData.emailAddress,
        FlowId: this.flowData.rowKey,
      };

      this.flowServiceService
        .getFlowBookMarkedStatus(flowModel)
        .subscribe((data) => {
          if (data.IsSaved === true) {
            this.bookmarkcolor = "#5ea1d8";
          } else {
            this.bookmarkcolor = "gray";
          }
          //this.noResultReturned = false;
          // 
        });
    }
  }

  shareFlow() { }
  showToastforSaved() {
    this.toastr.success("Flow Saved");
  }
  showToastforUnsaved() {
    this.toastr.success("Flow Unsaved");
  }

  saveFlow() {
    if (this.userData != null) {
      //
      //this.noResultReturned = true;
      const flowModel = {
        UserEmail: this.userData.emailAddress,
        FlowId: this.flowData.rowKey,
        FlowType: 1,
      };

      this.flowServiceService.postBookMarkFlow(flowModel).subscribe((data) => {
        //this.noResultReturned = false; 
        if (data.bookMarked === "bookmarked") {
          this.bookmarkcolor = "#5ea1d8";
          this.showToastforSaved();
          this.flowData.saveCount = this.flowData.saveCount + 1;
          console.log(this.flowData.saveCount);
        } else if (data.bookMarked === "bookmarkremoved") {
          this.bookmarkcolor = "gray";
          this.showToastforUnsaved();
          this.flowData.saveCount = this.flowData.saveCount - 1;
          console.log(this.flowData.saveCount);
        }
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }

  onChatClick() {
    if (this.userData != null) {
      // localStorage.setItem("flowData", JSON.stringify(this.flowData));
      // this.router.navigate(["/comment"]);
      localStorage.setItem("flowcommentData", JSON.stringify(this.flowData));
      localStorage.removeItem("bundlecommentData");
      const dialogRef = this.dialog.open(CommentComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getFlowDetails(this.flowData.rowKey);
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);
    }
  }

  getProfilepic(): void {
    if (this.userData != null) {
      this.userserviceService
        .getProfilepic()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
        });
    }
  }

  showImage(itemCategory, item, index) {
    itemCategory.flowModels.forEach((element) => {
      if (element.arrow !== undefined) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.Url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }

  loadSteps(steps) {
    var stepNo = 0;
    steps.forEach((element) => {
      this.dataSourceSteps.push({
        stepNo: stepNo + 1,
        title: element.title,
        url: element.url,
        description: element.description,
        id: element.rowKey,
        ShowImage: this.showimage,
      });
      stepNo++;
    });
    console.log(this.dataSourceSteps);
  }

  vidEnded(event) {
    var playcount = 0;
    var video = this.videoPlayCounter.filter(
      (v) => v.currentSrc == event.target.currentSrc
    );
    if (video != null) {
      playcount = video.length;
    }

    if (playcount < 3) {
      this.videoPlayCounter.push({
        eventobj: event,
        currentSrc: event.target.currentSrc,
        playcount: playcount,
      });
      event.target.play();
    }
  }

  vidfocus(event) { }

  vidplay(event) {
    if (!this.viewUnloaded) {
      var videos = this.videoPlayCounter.filter((v) => v.id == event.target.id);

      if (videos.length > 3) {
        videos.forEach((element) => {
          var obj = this.videoPlayCounter.indexOf(element);

          if (obj > -1) {
            this.videoPlayCounter.splice(obj, 1);
          }
        });
      }
      event.target.play();
    }
  }

  getBundles(pageIndex): void {
    var flowModel = {
      rowKey: this.flowData.rowKey,
      partitionKey: this.flowData.partitionKey
    };

    this.bundleService
      .getBundlesByFlowId(flowModel, pageIndex)
      .subscribe((data) => {
        console.log(data);
        //this.noResultReturned = false;
        data.forEach((element) => {
          this.tempFlows.push(element);
        });
      });
    console.log(this.tempFlows);
    localStorage.setItem("BundleListToShare", this.tempFlows);
  }

  onScrollDown() {
    //
    // this.noResultReturned = true;
    this.delay(2000).then(() => {
      this.delayedServiceCall();
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem("pageIndex");
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem("pageIndex", pageIndex);
    this.getBundles(pageIndex);
  }

  flowClicked(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "grid");

    if (flow.type == "Bundle") {
      //this.isItBundle = true;
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else {
      //this.isItBundle = false;
      this.router.navigate(["/view/" + flow.rowKey]);
    }
  }

  openImage(Url) {
    localStorage.setItem("itemsRequiredImageToShow", Url);

    this.dialog.open(ShowFlowImageDialogComponent, {});
  }

  resizeSrolling() {
    this.carouselOptions = {
      margin: 20,
      nav: true,
      dots: false,
      pagination: false,
      responsiveClass: true,
      loop: false,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        300: {
          items: 2,
          nav: true,
        },
        400: {
          items: 2,
          nav: true,
        },
        550: {
          items: 2,
          nav: true,
        },
        600: {
          items: 2.5,
          nav: true,
        },
        700: {
          items: 3,
          nav: true,
        },
        750: {
          items: 4,
          nav: true,
        },
        800: {
          items: 4,
          nav: true,
        },
        900: {
          items: 5,
          nav: true,
        },
        1000: {
          items: 5,
          nav: true,
          loop: false,
        },

        1100: {
          items: 6,
          nav: true,
          loop: false,
        },

        1200: {
          items: 6,
          nav: true,
          loop: false,
        },
        1300: {
          items: 6,
          nav: true,
          loop: false,
        },
        1400: {
          items: 6,
          nav: true,
          loop: false,
        },
        1500: {
          items: 6,
          nav: true,
          loop: false,
        },
      },
    };
  }

  openForECode(flowData: any) {
    localStorage.setItem("flowsData", JSON.stringify(flowData));
    const dialogRef = this.dialog.open(EmbededComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
