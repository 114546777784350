import { PublishDialogComponent } from "./../publish-dialog/publish-dialog.component";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CancalFlowDialogComponent } from "../cancal-flow-dialog/cancal-flow-dialog.component";
import { ToastrService } from "ngx-toastr";
import { ShowFlowImageDialogComponent } from "../show-flow-image-dialog/show-flow-image-dialog.component";
import { Location } from "@angular/common";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { Console } from "console";
import { RestrictedAccessComponent } from "../restricted-access/restricted-access.component";
import { elements } from "chart.js";
import { HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: "app-create-flow",
  templateUrl: "./create-flow.component.html",
  styleUrls: ["./create-flow.component.scss"],
})
export class CreateFlowComponent implements OnInit {
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flows: any;
  showNotificationCount: boolean;
  notificationCount: number;
  CategoryName: string;
  inames: any[];
  _steps: any[];
  supportingDocumentDocuments: any[];
  supportingDocumentHyperLinks: any[];
  allSteps: any[];
  coverImageUri: string;
  coverPosterPageURI: string;
  stepsImageUri: string;
  stepDescription: string;
  title: string;
  duration: number;
  description: string;
  quantity: string;
  coverPage: string;
  isSCenabled: any;
  stepsButton: any;
  flowData: any;
  accessType: string;
  reSelectedCategory: string;
  categoryPageNavigatedFrom: any;
  //flowId: any;
  flowNavigateTo: any;
  showItemsOrStepsButton: boolean;
  disablePublishButton: boolean;
  selectedCategoryName: any;
  showDeleteContentMenu: boolean;
  uniqueId: any;
  draft: string;
  edit: string;
  fileName: any;
  stepNumber = 1;
  noResultReturned: boolean;
  isPaid: boolean;
  price: any;
  showMessage: boolean;
  rowKey: any;
  partitionKey: any;
  faqs: any;
  _allFaqs: any[] = [];
  isFaqChecked: boolean = false;
  groupName: any;
  question: any;
  answer: any;
  progress: number = 0;
  isUploading: boolean;
  searchKeyWords: any;
  groupedData: Record<string, any[]> = {};
  groupedFaqData: Record<string, any[]> = {};

  constructor(

    private dataService: DataService,
    private flowServiceService: FlowServiceService,
    public dialog: MatDialog,
    private userserviceService: UserserviceService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {
    this.flows = JSON.parse(localStorage.getItem("flow"));
    this.rowKey = JSON.parse(localStorage.getItem("notificationflowId"));
    // this.flowNavigateTo = JSON.parse(localStorage.getItem("navigateTo"));
  }

  ngOnInit() {
    this.draft = localStorage.getItem("draftWord");
    this.edit = localStorage.getItem("editWord");
    this.flowServiceService.setScrollTop();
    this.showItemsOrStepsButton = true;
    this.disablePublishButton = true;
    this.showDeleteContentMenu = false;
    this.uniqueId = "";
    this.rowKey = 0;
    this.noResultReturned = false;

    this.stepDescription = null;
    this.title = null;
    this.duration = 0;
    this.CategoryName = null;
    //
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;

    var cName = localStorage.getItem("selectedCategoryName");

    this.accessType = localStorage.getItem("AccessType");
    // 
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });

    this.inames = JSON.parse(localStorage.getItem("ItemsRequired"));

    this.groupDataByGroupName();

    console.log(this.inames);
    var flowCoverPage = JSON.parse(
      localStorage.getItem("flowCoverPageDetails")
    );

    console.log(flowCoverPage);

    var supportingDocumentDocuments = JSON.parse(
      localStorage.getItem("SupportingDocumentDocuments")
    );

    var supportingDocumentHyperLinks = JSON.parse(
      localStorage.getItem("SupportingDocumentHyperLinksRequired")
    );

    if (supportingDocumentDocuments != null) {
      this.supportingDocumentDocuments = supportingDocumentDocuments;
    }

    if (supportingDocumentHyperLinks != null) {
      this.supportingDocumentHyperLinks = supportingDocumentHyperLinks;
    }


    if (flowCoverPage != null) {
      this.showItemsOrStepsButton = false;
      this.isCoverAImageOrVideo(flowCoverPage.coverImage);
      this.coverImageUri = flowCoverPage.coverImage;
      this.coverPosterPageURI = flowCoverPage.coverPosterPageURI;
      this.title = flowCoverPage.title;
      this.searchKeyWords = flowCoverPage.searchKeyWords;
      this.description = flowCoverPage.description;
      this.duration = flowCoverPage.duration;
      this.rowKey = flowCoverPage.rowKey;
      this.uniqueId = flowCoverPage.uniqueId;
      this.fileName = flowCoverPage.fileName;
      this.price = flowCoverPage.price;
      this.isPaid = flowCoverPage.isPaid;
      this.partitionKey = flowCoverPage.partitionKey;

      if (
        cName == null &&
        flowCoverPage.type != null &&
        flowCoverPage.type != undefined
      ) {
        this.CategoryName = flowCoverPage.type;
      } else {
        this.CategoryName = cName;
      }

      if (flowCoverPage.rowKey != null && flowCoverPage.rowKey != undefined) {
        this.showDeleteContentMenu = true;
      }
    } else {
      this.coverImageUri = null;
      this.CategoryName = cName;
    }

    this._steps = JSON.parse(localStorage.getItem("stepsCoverPageDetails"));
    this.allSteps = [];

    if (this._steps != null && this._steps.length > 0) {
      this.disablePublishButton = false;

      this._steps.forEach((element) => {
        this.isImageOrVideo(element.url);

        this.allSteps.push({
          Url: element.url,
          Description: element.description,
          showImage: this.showImage,
          VideoThumbNailUrl: element.posterUrl,
          showVideo: this.showVideo,
          stepFileName: element.fileName,
          stepNumber: this.stepNumber++,
        });
      });
    }

    this.getSupportFaqs(this.rowKey);
    console.log(this.rowKey);

    this.getProfileUser();

    var flowData = JSON.parse(localStorage.getItem("flow"));
    var notificationflowId = JSON.parse(
      localStorage.getItem("notificationflowId")
    );

    if (flowData == null || notificationflowId != null) {
      var email = "";

      if (this.userData != null) {
        email = this.userData.emailAddress;
      }

      var flowQueryModel = new FlowQueryModel({
        FlowId: notificationflowId,
        userEmail: email
      });

      // this.noResultReturned = true;
      //
      this.flowServiceService
        .getFlowById(flowQueryModel)
        .subscribe((data) => {
          //  this.noResultReturned = false; 
          this.flowData = data.flows;
        });

    } else if (flowData != null) {
      this.flowData = flowData;
    }

    var createflowcallsavedraft = localStorage.getItem("create-flow-savedraft");
    if (createflowcallsavedraft == "create-flow-call-savedraft") {
      localStorage.removeItem("create-flow-savedraft");
    }

    //get support Faq
    // this.faqs =  this.flowServiceService.getSupportFaq(flowData.rowKey);
    // console.log(this.faqs);
    // if(this.faqs.groups != null){
    //   this.isFaqChecked=true;
    //  this.faqs.forEach((element:any)=>{
    //    this._allFaqs.push({
    //      groupName : element.groupName,
    //      answer : element.answer,
    //      question : element.question,
    //      flowRowKey : element.partitionKey
    //    });
    //  });
    //  console.log(this._allFaqs);
    // }  
  }


  getSupportFaqs(flowId) {
    console.log(flowId);
    var flowQueryModel = new FlowQueryModel({
      FlowId: flowId,
    });
    this.flowServiceService.getSupportFaq(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      if (data.length > 0) {
        console.log(data);
        this.isFaqChecked = true;
        this._allFaqs = [];
        data.forEach((element: any) => {
          if (element.isValid) {
            this._allFaqs.push({
              groupName: element.groupName,
              answer: element.answer,
              question: element.question,
              partitionKey: element.partitionKey,
              flowRowKey: element.partitionKey,
              userEmail: element.userEmail,
              rowKey: element.rowKey
            });
          }
        });
        console.log(this._allFaqs);
        this.groupFaqDataByGroupName();
      } else {
        this.isFaqChecked = false;
      }
      console.log(this.isFaqChecked);
    });
  }

  groupDataByGroupName() {
    if (!this.inames || this.inames.length === 0) {
      this.groupedData = {}; // Ensure no errors on empty or undefined data
      return;
    }

    this.groupedData = this.inames.reduce((acc, item) => {
      const group = item.groupName || "Others"; // Default group if missing
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {} as Record<string, any[]>);
    console.log(this.groupedData);
  }

  getAllDropListIds(): string[] {
    return Object.keys(this.groupedData);
  }
  groupFaqDataByGroupName() {
    if (!this._allFaqs || this._allFaqs.length === 0) {
      this.groupedFaqData = {}; // Ensure no errors on empty or undefined data
      return;
    }

    this.groupedFaqData = this._allFaqs.reduce((acc, item) => {
      const group = item.groupName || "Others"; // Default group if missing
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {} as Record<string, any[]>);
    console.log(this.groupedFaqData);
  }

  getAllFaqDropListIds(): string[] {
    return Object.keys(this.groupedFaqData);
  }


  removeGroup(index: number, group: any) {
    console.log(group);
    var model = {
      partitionKey: group.flowRowKey,
      rowKey: group.rowKey,
      groupName: group.groupName,
      answer: group.answer,
      question: group.question,
      userEmail: group.userEmail
    };
    this.flowServiceService
      .deleteSupportFaq(model)
      .subscribe((data) => {
        console.log(data);
        if (data) {
          this.toastr.success('All FAQs saved successfully!');
          this.getSupportFaqs(this.rowKey);
        } else {
          this.toastr.error('Faqs deletion failed. Please try again!');
        }
      });
  }
  // Edit an existing group
  editGroup(i, group: any) {
    console.log(group);
    const index = this.getGlobalIndexForFaq(group);
    console.log(index);
    localStorage.setItem("SupportFaqs", JSON.stringify(this._allFaqs));
    localStorage.setItem("stepToFaq", index.toString());
    this.router.navigate(["/faq"]);
  }

  dropFaq(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this._allFaqs,
      event.previousIndex,
      event.currentIndex
    );
  }

  openLink(url) {
    url = "https://" + url;
    window.open(url);
  }

  getFileName(fileName) {
    return fileName.slice(((fileName.lastIndexOf("/") - 1) >>> 0) + 2);
  }

  showImage: boolean;
  showVideo: boolean;
  showCoverImage: boolean;
  showCoverVideo: boolean;

  isCoverAImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showCoverImage = true;
      this.showCoverVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showCoverVideo = true;
      this.showCoverImage = false;
    }
  }

  isImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showImage = true;
      this.showVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showVideo = true;
      this.showImage = false;
    }
  }

  leave() {
    localStorage.setItem("draftTabIndex", "2");
    var isDraft = localStorage.getItem("draftWord");
    var pageToNavigate = localStorage.setItem("navigatebackfromcreateflow", '/user-profile');
    localStorage.setItem("navigatebackfromcreatebundle", this.location.path());

    if (isDraft != null && isDraft != undefined) {
      this.router.navigate(['/user-profile']);
    } else if (this.rowKey > 0) {
      this.router.navigate([`/guide/${this.generateUrlSlug(this.title)}/${this.rowKey}`]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  generateUrlSlug(html) {
    if (!html) return '';

    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }

  showFlowImage(coverImageUri) {
    localStorage.setItem("flowImageToShow", coverImageUri);
    this.dialog.open(ShowFlowImageDialogComponent, {});
  }

  onButtonClick() {
    this.router.navigate(["/cover-page"]);
  }

  addSteps() {
    if ((this.title == undefined || null) && (this.description == undefined || null) && (this.coverImageUri == undefined || null)) {
      this.showMessage = true;
      this.toastr.warning("To add steps first cover page details.");
    }
    else {
      this.showMessage = false;
      localStorage.removeItem("stepToEdit");
      this.router.navigate(["/steps"]);
    }

  }
  addFaqs() {
    if ((this.title == undefined || null) && (this.description == undefined || null) && (this.coverImageUri == undefined || null)) {
      this.showMessage = true;
      this.toastr.warning("To add Faqs first cover page details.");
    }
    else {
      this.showMessage = false;
      localStorage.removeItem("stepToFaq");
      this.router.navigate(["/faq"]);
    }

  }
  checkFaq() {
    this.isFaqChecked = true;
    if ((this.title == undefined || null) && (this.description == undefined || null) && (this.coverImageUri == undefined || null)) {
      this.showMessage = true;
      this.toastr.warning("To add flow-FAQs, start by including all the details mentioned above.");
    }
  }

  // addFaqs(){ 
  //     this.router.navigate(["/faq"]); 
  // }

  gotocategory() {
    localStorage.setItem("categoryName", this.CategoryName);
    localStorage.setItem("Flowdata", this.flowData);
    this.router.navigate(["/create-flow"]);
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.inames, event.previousIndex, event.currentIndex);
  // }

  drop(event: CdkDragDrop<any[]>) {
    if (!event.item.data) return;

    const previousGroup = event.previousContainer.id;
    const newGroup = event.container.id;
    const item = event.item.data;

    if (previousGroup !== newGroup) {
      // Remove item from old group
      this.groupedData[previousGroup] = this.groupedData[previousGroup].filter(
        (i) => i !== item
      );

      // Update the item with new group information (optional)
      item.groupName = newGroup;

      // Add item to new group
      if (!this.groupedData[newGroup]) {
        this.groupedData[newGroup] = [];
      }
      this.groupedData[newGroup].push(item);
    } else {
      // Reorder within the same group
      moveItemInArray(
        this.groupedData[newGroup],
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  stepDragDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allSteps, event.previousIndex, event.currentIndex);

    var i = 1;
    this.allSteps.forEach((element) => {
      element.stepNumber = i;
      i++;
    });
  }

  // remove(index) {
  //   this.inames.splice(index, 1);
  //   localStorage.setItem("ItemsRequired", JSON.stringify(this.inames));
  // }

  remove(index, item) {
    const indexToDelete = this.inames.indexOf(item);
    this.inames.splice(indexToDelete, 1);
    localStorage.setItem("ItemsRequired", JSON.stringify(this.inames));
    this.groupDataByGroupName();
  }
  removeSupportingDocument(index) {
    this.supportingDocumentDocuments.splice(index, 1);
    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
  }

  removeSupportingDocumentHyperLink(index) {
    this.supportingDocumentHyperLinks.splice(index, 1);
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );
  }

  items() {
    this.router.navigate(["/items"]);
  }

  editItem(i, item) {
    console.log(this.inames);
    const index = this.getGlobalIndex(item);
    console.log(index);
    localStorage.setItem("EditItemsRequired", index.toString());
    this.router.navigate(["/edititem"]);
  }

  // Efficiently get the index using flatMap
  getGlobalIndex(item: any): number {
    return this.inames.findIndex(i =>
      i.itemkey === item.itemkey &&
      i.itemValue === item.itemValue &&
      i.wheretofindit === item.wheretofindit
    );
  }
  // Efficiently get the index using flatMap
  getGlobalIndexForFaq(item: any): number {
    return this._allFaqs.findIndex(i =>
      i.rowKey === item.rowKey);
  }

  openDialog(AccessType) {
    const dialogRef = this.dialog.open(PublishDialogComponent, {
      width: "250px",
      data: { accessType: this.accessType },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.accessType = result;
    });
  }

  restrictedAccessPage(accessType) {
    var flowModel = {
      rowKey: this.rowKey,
      partitionKey: this.partitionKey,
      UniqueId: "",
      UserName: this.uname,
      Description: this.description,
      CustomerEmail: this.userData.emailAddress,
      Title: this.title,
      AccessType: accessType,
      Url: this.coverImageUri,
      VideoThumbNailUrl: this.coverPosterPageURI,
      Type: this.CategoryName,
      FlowStatus: "Approved", //this.configProvider.ApprovedStatus,
      Duration: this.duration,
      Steps: this.allSteps,
      Components: this.inames,
      Price: this.price,
      IsPaid: this.isPaid,
      FlowSupportingDocumentsHyperLinkModels: this.supportingDocumentHyperLinks,
      FlowSupportingDocumentsDocumentModels: this.supportingDocumentDocuments,
    };
    console.log(flowModel);
    if (this.rowKey > 0 && this.uniqueId != "") {
      flowModel.rowKey = this.rowKey;
      flowModel.partitionKey = this.partitionKey;
      flowModel.UniqueId = this.uniqueId;
    }

    localStorage.setItem("flowModel", JSON.stringify(flowModel));
    // this.router.navigate(["/restrictedPage"]);
    const dialogRef = this.dialog.open(RestrictedAccessComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    }
  }

  getProfileUser(): void {
    if (
      this.userData != null &&
      this.userData.emailAddress != null &&
      this.userData.emailAddress != ""
    ) {
      //
      this.noResultReturned = true;
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          this.noResultReturned = false;
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            // 
            this.noResultReturned = false;
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  editStep(step, index) {
    localStorage.setItem(
      "stepToEdit",
      JSON.stringify({ stepToEdit: step, index: index })
    );
    this.router.navigate(["/steps"]);
  }

  deleteStep(step, index) {
    this.allSteps.splice(index, 1);
    localStorage.setItem(
      "stepsCoverPageDetails",
      JSON.stringify(this.allSteps)
    );

    if (this.allSteps.length <= 0) {
      this.disablePublishButton = true;
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  saveFlowToServer(status) {
    console.log(this.userData.enableConsultation);
    console.log(this.userData);
    var flowModel = {
      rowKey: this.rowKey,
      partitionKey: this.partitionKey,
      UniqueId: "",
      UserName: this.uname,
      Description: this.description,
      CustomerEmail: this.userData.emailAddress,
      Title: this.title,
      AccessType: "Public",
      Url: this.coverImageUri,
      VideoThumbNailUrl: this.coverPosterPageURI,
      Type: this.CategoryName,
      FlowStatus: status, //this.configProvider.ApprovedStatus,
      Duration: this.duration,
      Steps: this.allSteps,
      Components: this.inames,
      IsConsulatationAvailable: this.userData.enableConsultation,
    };
    if ((this.title == undefined || null) && (this.description == undefined || null) && (this.coverImageUri == undefined || null)) {
      this.showMessage = true;
      this.toastr.warning(" Add cover page details to save flow as draft.");
    }
    else {
      this.showMessage = false;
      if (this.rowKey > 0 && this.uniqueId != "") {
        flowModel.rowKey = this.rowKey;
        flowModel.partitionKey = this.partitionKey;
        flowModel.UniqueId = this.uniqueId;
      }

      if (this.userData != null) {
        this.noResultReturned = true;
        this.progress = 0;

        this.flowServiceService
          .publishUserFlow(flowModel, this.userData.emailAddress)
          .subscribe({
            next: (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request sent to server.');
                  break;

                case HttpEventType.UploadProgress:
                  this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                  console.log(`Progress: ${this.progress}%`);
                  break;

                case HttpEventType.Response:
                  console.log('Upload complete:', event.body);
                  localStorage.setItem("draftTabIndex", "2");
                  this.cdr.detectChanges();

                  setTimeout(() => {
                    this.progress = 100;
                    console.log("set is loading");
                    this.noResultReturned = false;
                    this.toastr.success("Successfully moved to draft");
                    this.router.navigate(['/user-profile']);
                    this.resetProgress();
                  }, 2000);

                  break;

                default:
                  console.log('Unhandled event:', event);
              }
            },
            error: (err) => {
              console.error('Error uploading:', err);
              this.resetProgress();
            }
          });
      }
      return flowModel;
    }
  }

  saveFlowToServerAccessLevel(AccessType) {
    if (
      this.allSteps.length <= 0
    ) {
      this.showMessage = true;
      this.toastr.warning(" Add cover page and steps to publish flow .");
    }
    else {
      this.noResultReturned = true;
      console.log(this.userData.enableConsultation);
      var flowModel = {
        rowKey: this.rowKey,
        partitionKey: this.partitionKey,
        UniqueId: "",
        UserName: this.uname,
        Description: this.description,
        CustomerEmail: this.userData.emailAddress,
        Title: this.title,
        AccessType: AccessType,
        Url: this.coverImageUri,
        VideoThumbNailUrl: this.coverPosterPageURI,
        Type: this.CategoryName,
        FlowStatus: "Approved", //this.configProvider.ApprovedStatus,
        Duration: this.duration,
        Steps: this.allSteps,
        Components: this.inames,
        Price: this.price,
        IsPaid: this.isPaid,
        FlowSupportingDocumentsHyperLinkModels: this.supportingDocumentHyperLinks,
        FlowSupportingDocumentsDocumentModels: this.supportingDocumentDocuments,
        IsConsulatationAvailable: this.userData.enableConsultation,
        SearchKeyWords: this.searchKeyWords
      };
      console.log(flowModel);

      this.showMessage = false;
      if (this.rowKey > 0 && this.uniqueId != "") {
        flowModel.rowKey = this.rowKey;
        flowModel.UniqueId = this.uniqueId;
        flowModel.partitionKey = this.partitionKey;
      }

      if (this.userData) {
        this.isUploading = true;
        this.progress = 0;
        this.flowServiceService
          .publishUserFlow(flowModel, this.userData.emailAddress)
          .subscribe({
            next: (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request sent to server.');
                  break;

                case HttpEventType.UploadProgress:
                  this.progress = Math.round((95 * event.loaded) / (event.total || 1));
                  console.log(`Progress: ${this.progress}%`);
                  break;

                case HttpEventType.Response:
                  console.log('Upload complete:', event.body);
                  // if (this.isFaqChecked) {
                  //   this.addFaqs();
                  // }
                  localStorage.setItem('draftTabIndex', '0');
                  this.cdr.detectChanges();

                  setTimeout(() => {
                    this.progress = 100;
                    console.log("set is loading");
                    this.noResultReturned = false;
                    this.toastr.success("Flow successfully published");
                    this.router.navigate(['/user-profile']);
                    this.resetProgress();
                  }, 1000);


                  break;

                default:
                  console.log('Unhandled event:', event);
              }
            },
            error: (err) => {
              console.error('Error uploading:', err);
              this.resetProgress();
            }
          });
      }
      // if (this.userData != null) {
      //   this.flowServiceService
      //     .publishUserFlow(flowModel, this.userData.emailAddress)
      //     .subscribe((data) => {
      //       if(this.isFaqChecked){ 
      //         this.addFaqs();
      //       }
      //       localStorage.setItem("draftTabIndex", "0");
      //       this.router.navigate(["/user-profile"]);
      //       this.noResultReturned = false;
      //     });
      // }
    }

  }

  resetProgress() {
    setTimeout(() => {
      this.progress = 0;
      this.isUploading = false;
    }, 500); // Small delay before hiding progress
  }

  openLeavingDialog() {
    const dialogRef = this.dialog.open(CancalFlowDialogComponent, {
      width: "250px",
    });
  }

  showToastForDelete(message) {
    this.toastr.success(message);
  }

  deleteFlow() {
    if (this.rowKey > 0) {
      var flowModel = {
        rowKey: this.rowKey,
        CustomerEmail: this.userData.emailAddress,
        FlowStatus: "Submitted",
      };

      this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
      if (this.userData != null) {
        this.noResultReturned = true;
        // 
        this.flowServiceService
          .deleteFlow(flowModel)
          .subscribe((data) => {
            this.noResultReturned = false;
            // 
            var isDraftDeleted = localStorage.getItem("draftWord");
            localStorage.setItem("draftTabIndex", "2");
            if (data.Deleted && isDraftDeleted == "Draft Flow") {
              this.router.navigate(["/user-profile"]);
              localStorage.removeItem("Draft Flow");
              this.showToastForDelete("Draft successfully deleted");
            } else {
              this.showToastForDelete("Flow deleted successfully");
              this.router.navigate(["/"]);
            }
          });
      }
    }
  }

  openImage(Url) {
    localStorage.setItem("itemsRequiredImageToShow", Url);

    this.dialog.open(ShowFlowImageDialogComponent, {});
  }
}
