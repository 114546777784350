import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { FlowQueryModel } from "../models/flowquerymodel";
import { Endpoint } from "../shared/endpoints";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FlowServiceService {



  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }


  searchInFlows(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.searchFlowsUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  searchInBundles(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.searchBundlesUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  searchInItemsRequired(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.searchItemsRequiredUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getSearchApprovedFlowsByFlowQueryModel(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getSearchApprovedFlowsByFlowQueryModel,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getFlowsByItemsRequired(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getFlowsByItemsRequired,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  addSupportFaq(flowQueryModel: any) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.SupportFaq,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  deleteSupportFaq(queryModel: any) {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    });

    return this.http.delete<any>(
      environment.apiBaseUrl + Endpoint.deleteSupportFaq,
      {
        headers: headers,
        body: queryModel // `body` is correctly used for DELETE with a request payload
      }
    );
  }

  deleteBlog(queryModel: any) {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    });

    return this.http.delete<any>(
      environment.apiBaseUrl + Endpoint.deleteBlog,
      {
        headers: headers,
        body: queryModel // `body` is correctly used for DELETE with a request payload
      }
    );
  }

  getSupportFaq(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");


    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getSupportFaq,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getFaqDetailsByQuestion(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");


    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getFaqDetailsByQuestion,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getSearchApprovedBundleByBundleQueryModel(flowQueryModel: FlowQueryModel) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getSearchApprovedBundleByBundleQueryModel,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  postFollowUser(userEmail, customerEmail) {
    let header = new HttpHeaders();
    header = header.append("Accept", "application/json");
    header = header.append("Content-Type", "application/json");
    header = header.append("userEmail", userEmail);
    header = header.append("followerEmail", customerEmail);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.postFollowUserUrl, {
      headers: header,
    });
  }

  setScrollTop() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    }
  }

  publishUserFlow(flowModel, value): Observable<HttpEvent<any>> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    header.append("emailAddress", value);
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.createFlowUrl,
      flowModel,
      {
        headers: header,
        reportProgress: true, // ✅ Enables progress tracking
        observe: 'events', // ✅ Allows listening to upload progress
      }
    );
  }
  publishBlog(flowModel, value): Observable<HttpEvent<any>> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    header.append("emailAddress", value);
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.createBlog,
      flowModel,
      {
        headers: header,
        reportProgress: true, // ✅ Enables progress tracking
        observe: 'events', // ✅ Allows listening to upload progress
      }
    );
  }

  flowCheckout(checkoutModel, value) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    header.append("emailAddress", value);
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.flowCheckoutUrl,
      checkoutModel,
      {
        headers: header,
      }
    );
  }

  saveFlowToServerAccessLevel(flowModel, value) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    header.append("emailAddress", value);
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.postAccessType, flowModel, {
      headers: header,
    });
  }

  getCouponCodeDiscountValue(price, couponCode, contentType): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("originalAmount", price + "");
    header = header.append("couponCode", couponCode);
    header = header.append("contentType", contentType);
    return this.http.get<any>(
      environment.apiBaseUrl + Endpoint.getCouponCodeDiscountValueUrl,
      {
        headers: header,
      }
    );
  }

  updateFlowTime(userEmail: string, timeSpentOnFlow: number, flowId: number):
    Observable<any> {
    const model = {
      UserEmail: userEmail,
      TimeSpentOnFlow: timeSpentOnFlow,
      FlowId: flowId
    };

    return this.http.put(environment.apiBaseUrl + Endpoint.updateTimeSpentOnFlowUrl, model);
  }

  getUserDashboard(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getUserDashboardUrl, flowQueryModel, {
      headers: header,
    });
  }

  getUserDashboardFlows(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getUserDashboardFlows, flowQueryModel, {
      headers: header,
    });
  }

  getUserDashboardBundles(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getUserDashboardBundles, flowQueryModel, {
      headers: header,
    });
  }

  getUserDashboardPaidFlows(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getUserDashboardPaidFlows, flowQueryModel, {
      headers: header,
    });
  }

  getUserDashboardPaidBundles(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getUserDashboardPaidBundles, flowQueryModel, {
      headers: header,
    });
  }

  getFollowerUsers(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getFollowersUrl, flowQueryModel, {
      headers: header,
    });
  }

  getUsersVoucherCodes(loginModel): Observable<any> {
    const header = new HttpHeaders();

    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getUsersVoucherCodesUrl, loginModel, {
      headers: header,
    });
  }
  getVoucherCode(voucherCodeModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");

    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getVoucherCodeUrl,
      voucherCodeModel,
      {
        headers: header,
      }
    );
  }

  getFollowingUsers(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getFollowingUrl, flowQueryModel, {
      headers: header,
    });
  }

  getNotifications(flowQueryModel): Observable<any> {
    let header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getNotificationUrl, flowQueryModel, {
      headers: header
    });
  }
  MarkAsReadNotifications(flowQueryModel): Observable<any> {
    let header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.markAsReadNotificationsUrl, flowQueryModel, {
      headers: header
    });
  }

  getFlows(userEmail, pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("userName", userEmail);
    header = header.append("pageIndex", pageIndex);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getFlowsByCategoriesUrl, {
      headers: header,
    });
  }

  getFlowsByInputCategory(
    userEmail,
    inputCategory,
    pageIndex
  ): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("userName", userEmail);
    header = header.append("inputCategory", inputCategory);
    header = header.append("pageIndex", pageIndex);

    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getFlowsByInputCategoryUrl, {
      headers: header,
    });
  }

  SearchFlows(
    userEmail,
    inputCategory,
    searchString,
    pageIndex
  ): Observable<any> {

    let header = new HttpHeaders();
    header = header.append("userName", userEmail);
    header = header.append("inputCategory", inputCategory);
    header = header.append("pageIndex", pageIndex);
    header = header.append("searchString", searchString);

    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getSearchFlowsUrl, {
      headers: header,
    });
  }

  // postLinkClick(linkClickDetails) {
  //   const header = new HttpHeaders();
  //   header.append("Accept", "application/json");
  //   header.append("Content-Type", "application/json");

  //   return this.http.post<any>(
  //     environment.apiBaseUrl + Endpoint.PostLinkClickUrl,
  //     linkClickDetails,
  //     {
  //       headers: header,
  //     }
  //   );
  // }

  // getLinkClickDetails(contentType, contentId): Observable<any> {
  //   let header = new HttpHeaders();
  //   header = header.append("contentType", contentType);
  //   header = header.append("contentId", contentId + '');
  //   return this.http.get<any>(environment.apiBaseUrl + Endpoint.GetLinkClickUrl, {
  //     headers: header,
  //   });
  // }


  getLinkClickDetails(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetLinkClickUrl, flowModel, {
      headers: header,
    });
  }
  getAllLinkClickDetails(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetAllLinkClickUrl, flowModel, {
      headers: header,
    });
  }
  GetAllPageViews(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetAllPageViews, flowModel, {
      headers: header,
    });
  }
  postLinkClick(linkClickDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.PostLinkClickUrl, linkClickDetails, {
      headers: header,
    });
  }
  postPageView(linkClickDetails) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");

    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.PostPageViewUrl,
      linkClickDetails,
      {
        headers: header,
      }
    );
  }

  getPageViewDetails(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetPageViewDetailsUrl, getPageViewDetails, {
      headers: header,
    });
  }
  getContentDetails(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetContentCount, getPageViewDetails, {
      headers: header,
    });
  }
  getContent(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetContent, getPageViewDetails, {
      headers: header,
    });
  }
  getBundleContentDetails(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetBundleContentCount, getPageViewDetails, {
      headers: header,
    });
  }
  getPurchaseByConsult(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetPurchaseByConsult, getPageViewDetails, {
      headers: header,
    });
  }

  reportFlow(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.inAppropriateUrl,
      flowModel,
      {
        headers: header,
      }
    );
  }

  deleteFlow(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.deleteFlowUrl, flowModel, {
      headers: header,
    });
  }

  isFlowLiked(flowModel): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("flowId", flowModel.FlowId.toString());
    header = header.append("userEmail", flowModel.LikedByUserEmail.toString());
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.IsFlowLikedUrl, {
      headers: header,
    });
  }

  getIsFlowViewed(flowModel): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("flowId", flowModel.FlowId.toString());
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getIsFlowViewedUrl, {
      headers: header,
    });
  }

  CheckIsUserFollowing(flowModel): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("userEmail", flowModel.UserEmailAddress);
    header = header.append("followerEmail", flowModel.CustomerEmail);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.IsUserFollowingUrl, {
      headers: header,
    });
  }

  getUsersFlowComments(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.usersFlowCommentsUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getFlowBookMarkedStatus(flowModel): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("flowId", flowModel.FlowId.toString());
    header = header.append("userName", flowModel.CustomerEmail.toString());
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.IsFlowBookMarkedStatusUrl, {
      headers: header,
    });
  }

  postBookMarkFlow(flowModel) {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.PostBookMarkFlowUrl, flowModel, {
      headers: header,
    });
  }

  LikeFlow(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.LikeFlowUrl, flowModel, {
      headers: header,
    });
  }

  LikeComment(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.postLikeFlowCommentUrl, flowModel, {
      headers: header,
    });
  }

  FollowUser(flowModel): Observable<any> {
    // let header = new HttpHeaders();
    // header = header.append("userEmail", flowModel.UserEmailAddress.toString());
    // header = header.append("followerEmail", flowModel.FollowerEmail.toString());
    // return this.http.get<any>(environment.apiBaseUrl + Endpoint.FollowUserUrl, {
    //   headers: header,
    // });
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.FollowUserUrl, flowModel, {
      headers: header,
    });
  }
  editComment(commentModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.editCommentUrl,
      commentModel,
      {
        headers: header,
      }
    );
  }

  addComment(commentModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.addFlowCommentUrl, commentModel, {
      headers: header,
    });
  }

  deleteComment(flowQueryModel: FlowQueryModel): Observable<any> {
    // const header = new HttpHeaders();
    // header.append("Accept", "application/json");
    // header.append("Content-Type", "application/json");
    // header.append("userName", emailAddress);

    // return this.http.post<any>(
    //   environment.apiBaseUrl + Endpoint.deleteFlowCommentUrl,
    //   commentModel,
    //   {
    //     headers: header,
    //   }
    // ); 
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.deleteFlowCommentUrl, flowQueryModel, {
      headers: header,
    });
  }

  getApprovedFlows(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.approvedUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getAllFlows(emailAddress, pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("userName", emailAddress);
    header = header.append("pageIndex", pageIndex);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getAllFlowsUrl, {
      headers: header,
    });
  }

  getAllUserFlows(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getAllUserFlowsUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getPurchasedHistoryFunds(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getPurchasedHistoryFunds, flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getGiftedFundsByCustomerRowKey(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getFundByCustomerRowKey,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getConsultationByDate(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    console.log(flowQueryModel);
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getConsultationsByDate,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getUserConsultation(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getUserConsultations,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getConsultation(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getConsultations,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  userFeedback(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.saveUserConsultationsFeedback,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  contentCreatorFeedback(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.saveContentCreatorConsultationsFeedback,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getConsultationByRowKey(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getConsultationsByRowKey,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  bookConsultation(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.bookConsultation,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getSavedFlows(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.bookMarkUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getPurchasedFlows(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.purchasedUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getAllBlogs(): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.get<any>(
      environment.apiBaseUrl + Endpoint.getAllBlogs,
      {
        headers: header,
      }
    );
  }

  getAllUserBlogs(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    let params = new HttpParams();
    Object.keys(flowQueryModel).forEach(key => {
      if (flowQueryModel[key] !== null && flowQueryModel[key] !== undefined) {
        params = params.append(key, flowQueryModel[key]);
      }
    });

    return this.http.get<any>(
      environment.apiBaseUrl + Endpoint.getUserBlogsDetails,
      {
        headers: header,
        params: params
      }
    );
  }


  getDraftFlows(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.draftUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getFlowById(flowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");


    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getFlowsByIdUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getBundles(emailAddress, pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.set("userName", emailAddress);
    header = header.set("pageIndex", pageIndex);
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getBundleUrl, {
      headers: header,
    });
  }

  getUserPublicBundles(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getUserPublicBundles,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }

  getUserBundles(flowQueryModel: FlowQueryModel): Observable<any> {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getUserBundleUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );
  }
  getContentLikeCount(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetContentLikeCount, getPageViewDetails, {
      headers: header,
    });
  }
  getContentSaveCount(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetContentSaveCount, getPageViewDetails, {
      headers: header,
    });
  }
  getContentCommentCount(getPageViewDetails): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetContentCommentCount, getPageViewDetails, {
      headers: header,
    });
  }
}
