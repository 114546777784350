import { BundleService } from "src/app/Services/bundleservice.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";

@Component({
  selector: "app-delete-user-dialog",
  templateUrl: "./delete-user-dialog.component.html",
  styleUrls: ["./delete-user-dialog.component.scss"],
})
export class DeleteUserDialogComponent implements OnInit {
  userData: any;
  bundleData: any;
  noResultReturned: boolean;
  bundleId: any;
  constructor(
    private toastr: ToastrService,
    private flowServiceService: FlowServiceService,
    private userService: UserserviceService,
    public formbuilder: UntypedFormBuilder,
    private dataService: DataService,
    public router: Router,
    private activateRouter: ActivatedRoute,

  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    console.log(this.userData);
  }

  // presetUserSettings() {
  //   this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
  //   if (this.userData.emailAddress != this.bundleData.customerEmail) {
  //     this.presentCurrentUserOptions();
  //   } else {
  //     this.deleteBundle();
  //   }
  // }

  selectedAction: string | null = null; // to store user's selection

  presetUserSettings(action: string) {
    if (action === 'delete') {
      this.userData.IsDeleteUser = true;
      this.userData.IsDeactivateUser = false;
      var result = this.deleteUser(this.userData); 
    } else if (action === 'deactivate') {
      this.userData.IsDeleteUser = false;
      this.userData.IsDeactivateUser = true;
      var result = this.deleteUser(this.userData); 
    }
  }
  presentCurrentUserOptions() { }

  deleteUser(bundleModel) {
    console.log(bundleModel);
    this.noResultReturned = true;
    var navigateTo = localStorage.getItem("navigateTo");
    if (this.userData.emailAddress == bundleModel.emailAddress) {
      this.userService
        .deleteCustomer(bundleModel)
        .subscribe((data) => {
          if (data == true) {
            this.noResultReturned = false;
            if (bundleModel.IsDeactivateUser === true) {
              this.toastr.success("User successfully Deactivated");
            } else if (bundleModel.IsDeleteUser == true) {
              this.toastr.success("User successfully Deleted");
            } else {
              this.toastr.warning("No action performed");
            }
            localStorage.setItem("LoggedInUser", null);
            this.router.navigate(["/"]);
          } else {
            this.noResultReturned = false;

            if (bundleModel.IsDeactivateUser === true) {
              this.toastr.warning("User Deactivation failed");
            } else if (bundleModel.IsDeleteUser === true) {
              this.toastr.warning("User Deletion failed");
            } else {
              this.toastr.warning("No action performed");
            }
            this.router.navigate(["/user-profile"]);
          }
        });

    } else {
      this.router.navigate(["/user-profile"]);
    }
  }

  showToastForDelete() {
    this.toastr.success("User successfully Deleted");
  }
}
