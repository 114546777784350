import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { GetContentService } from 'src/app/Services/getcontent.service';
import { TitleandmetaserviceService } from 'src/app/Services/titleandmetaservice.service';
import { prodcmsconfig, testcmsconfig } from 'src/app/shared/cms/cmsconfig';
import { ContentQueryModel } from 'src/app/shared/cms/contentquerymodel';
import { ContentTypes } from 'src/app/shared/enums/content.types';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class PreviewBlogComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  selectedBlogContent!: any;
  articalModel!: any;
  isLoading!: boolean;
  contentTitle: string;
  userData: any;
  isBlogOwner!: boolean;
  @ViewChild('confirmDialog') confirmDialog!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;
  
  constructor(
    private activateRouter: ActivatedRoute,
    private getContentService: GetContentService,
    private titleandmetaserviceService: TitleandmetaserviceService,
    private route: Router,
    private flowService :FlowServiceService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }
  onScrollDown() { }
  ngOnInit(): void {
    this.activateRouter.paramMap.subscribe((params) => {
      var title = params.get('title');
      this.titleandmetaserviceService.setTitle(title);
      // if (title == null) { 
      //   this.selectedBlogContent = JSON.parse(localStorage.getItem('selectedBlogContent'));
      // } else {
      //   this.contentTitle = this.createTitleFromUrl(title);
      //   this.getBlogDetails(this.contentTitle);
      // }
      console.log(title);
      this.selectedBlogContent = JSON.parse(localStorage.getItem('selectedBlogContent'));
      this.selectedBlogContent.hashtags = this.selectedBlogContent.hashtags.replace("Keywords: ", "").split(", ").map(tag => tag.trim());

    });
    
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.isBlogOwner = this.userData.partitionKey == this.selectedBlogContent.partitionKey ;
    console.log(this.isBlogOwner);
  }

  createTitleFromUrl(url: string) {
    // Replace hyphens with spaces and capitalize each word
    return url.replace(/-/g, ' ');//.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  }

  onRightClick($event: any) { }
  loadMoreblogs() { }

  async getBlogDetails(title: any) {
    this.noResultReturned = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: testcmsconfig.applicationKey,
      environmentKey: testcmsconfig.environmentKey,
      contentType: ContentTypes.Blogs,
      contentTitle: title,
    });

    (await this.getContentService.
      getContentByContentTitleQueryModel(contentQueryModel)).subscribe((data) => {
        this.selectedBlogContent = data;
        this.noResultReturned = false;
        this.articalModel = this.selectedBlogContent.articleModels[0];
        localStorage.removeItem('selectedBlogContent');
        localStorage.setItem('selectedBlogContent', JSON.stringify(this.articalModel));
        this.noResultReturned = false;
      });
  }

  onEdit() {
    console.log("Edit clicked!");
    if (this.selectedBlogContent) {
      this.route.navigateByUrl('/blog');
    }
    // Implement edit functionality here
  }

  onDelete() {
    this.dialogRef = this.dialog.open(this.confirmDialog, { width: '350px' });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedBlogContent.hashtags = this.selectedBlogContent.hashtags.join(", ")
        this.flowService.deleteBlog(this.selectedBlogContent).subscribe((data) => {
          if (data) {
            this.toastr.success(`Blog: ${this.selectedBlogContent.title} deleted successfully!`);
            localStorage.setItem('selectedBlogContent', "null");
            this.route.navigateByUrl('/bulbguide');
          } else {
            this.toastr.error('Blog deletion failed. Please try again!');
          }
        });
      }
    });
  }

}