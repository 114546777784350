import {AfterViewInit, Component,OnInit} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";  
@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.scss"],
})
export class UserDashboardComponent implements OnInit , AfterViewInit{   
  userEmail!:string;
  userData: any;  
  blue = "#ofa";
  toogleMenuClass: boolean = false;
  isToggleClass!: boolean;

  constructor(public formbuilder: UntypedFormBuilder,
    public router: Router,
    public dialog: MatDialog
  ) { 
  }

  addMenutoggleClass() {
    console.log(this.toogleMenuClass);
    this.toogleMenuClass = !this.toogleMenuClass;
    this.isToggleClass = false;

  }
  
  navigatetopage(navigateTo: string) {
    if (navigateTo == 'dashboard') {
      this.router.navigateByUrl("/user-dashboard/dashboard");
    }
    if (navigateTo == 'analytics') { 
      this.router.navigateByUrl("/user-dashboard/content-analytics");
    }
  } 
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.userEmail = "";

    console.log(this.userData);
    if (this.userData != null) {
      this.userEmail = this.userData.emailAddress;
    }else{
      this.router.navigate(['/']); 
    }  
  } 
  ngAfterViewInit(): void { 
    console.log(this.userData);
    if (this.userData != null) {
      this.userEmail = this.userData.emailAddress;
    }else{
      this.router.navigate(['/']); 
    } 
  } 
}
