// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default pink color for Rephrase and Cancel buttons */
.always-pink {
  background-color: #e91e63; /* Pink */
  color: white;
  padding: 5px;
  margin-left: 10px;
}

.always-pink:hover {
  background-color: #d81b60;
}

/* Pink color for Confirm button when enabled */
.enabled-pink {
  background-color: #e91e63; /* Pink */
  color: white;
  margin-left: 10px;
}

/* Hover effect for Confirm button when enabled */
.enabled-pink:hover {
  background-color: #d81b60;
}

/* Darker pink when the Confirm button is clicked */
.enabled-pink:active {
  background-color: #c2185b; /* Darker pink */
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header h2 {
  margin: 0;
}

.dialog-header .close {
  background: none;
  border: none;
  font-size: 30px;
  color: black;
  cursor: pointer;
}

.img-div-left.small-spinner {
  position: absolute !important;
  width: 100px !important;
  height: 100px !important;
  top: -3px !important;
}
.img-div-left.small-spinner img {
  width: 100px !important;
  height: 100px !important;
  top: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/generate-keyword/generate-keyword.component.scss"],"names":[],"mappings":"AAAA,uDAAA;AACA;EACI,yBAAA,EAAA,SAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEE;EACE,yBAAA;AACJ;;AAEE,+CAAA;AACA;EACE,yBAAA,EAAA,SAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEE,iDAAA;AACA;EACE,yBAAA;AACJ;;AAEE,mDAAA;AACA;EACE,yBAAA,EAAA,gBAAA;AACJ;;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;;AAEE;EACE,SAAA;AACJ;;AAEE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;AACJ;;AACE;EAGE,6BAAA;EACA,uBAAA;EACA,wBAAA;EAEA,oBAAA;AADJ;AAGE;EACE,uBAAA;EACA,wBAAA;EACA,qBAAA;AADJ","sourcesContent":["/* Default pink color for Rephrase and Cancel buttons */\n.always-pink {\n    background-color: #e91e63; /* Pink */\n    color: white;\n    padding: 5px;\n    margin-left: 10px;\n  }\n  \n  .always-pink:hover {\n    background-color: #d81b60;\n  }\n  \n  /* Pink color for Confirm button when enabled */\n  .enabled-pink {\n    background-color: #e91e63; /* Pink */\n    color: white;\n    margin-left: 10px;\n  }\n  \n  /* Hover effect for Confirm button when enabled */\n  .enabled-pink:hover {\n    background-color: #d81b60;\n  }\n  \n  /* Darker pink when the Confirm button is clicked */\n  .enabled-pink:active {\n    background-color: #c2185b; /* Darker pink */\n  }\n  .dialog-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n   // margin-bottom: 10px;\n  }\n  \n  .dialog-header h2 {\n    margin: 0;\n  }\n  \n  .dialog-header .close {\n    background: none;\n    border: none;\n    font-size: 30px;\n    color: black;\n    cursor: pointer;\n  }\n  .img-div-left.small-spinner{\n \n   \n    position: absolute !important;\n    width: 100px !important;\n    height: 100px !important;\n   \n    top: -3px !important;\n  \n  img{\n    width:100px !important;\n    height:100px !important;\n    top:unset !important;\n  }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
