import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/dataservice.service';
import { UserserviceService } from 'src/app/Services/userservice.service';


import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ShowFlowImageDialogComponent } from '../show-flow-image-dialog/show-flow-image-dialog.component';
import { environment } from 'src/environments/environment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flows: any;
  iname: string;
  itemsRequired: any[];
  quantity: string;
  fileData: File = null;
  noResultReturned: boolean;
  fileName: string;
  Url: string;
  isStepCoverPageURISet: boolean;
  showImage: boolean;
  showVideo: boolean;
  wheretofindit: any;
  progress: number;
  groupName: string;
  groupList: string[] = []; // List of options
  groupedData: Record<string, any[]> = {};

  constructor(
    private userserviceService: UserserviceService,
    private dataService: DataService,

    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    this.noResultReturned = false;

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });

    this.itemsRequired = JSON.parse(localStorage.getItem("ItemsRequired"));
    var editItemIndex = localStorage.getItem("EditItemsRequired");
    console.log('Test', editItemIndex);
    this.editItems(editItemIndex, this.itemsRequired[editItemIndex]);
    if (this.itemsRequired == null) {
      this.itemsRequired = [];
    }
    this.groupDataByGroupName();
    this.getProfileUser();
  }
  drop(event: CdkDragDrop<any[]>) {
    if (!event.item.data) return;

    const previousGroup = event.previousContainer.id;
    const newGroup = event.container.id;
    const item = event.item.data;

    if (previousGroup !== newGroup) {
      // Remove item from old group
      this.groupedData[previousGroup] = this.groupedData[previousGroup].filter(
        (i) => i !== item
      );

      // Update the item with new group information (optional)
      item.groupName = newGroup;

      // Add item to new group
      if (!this.groupedData[newGroup]) {
        this.groupedData[newGroup] = [];
      }
      this.groupedData[newGroup].push(item);
    } else {
      // Reorder within the same group
      moveItemInArray(
        this.groupedData[newGroup],
        event.previousIndex,
        event.currentIndex
      );
    }

    console.log(this.itemsRequired);
  }


  getAllDropListIds(): string[] {
    return Object.keys(this.groupedData);
  }

  groupDataByGroupName() {
    if (!this.itemsRequired || this.itemsRequired.length === 0) {
      this.groupedData = {}; // Ensure no errors on empty or undefined data
      return;
    }

    this.groupedData = this.itemsRequired.reduce((acc, item) => {
      const group = item.groupName || "Others"; // Default group if missing
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {} as Record<string, any[]>);

    this.getAllGroupName();
  }

  getAllGroupName() {
    if (this.itemsRequired && this.itemsRequired.length > 0) { // Ensure groups exist
      this.itemsRequired.forEach(element => {
        const trimmedGroup = element.groupName ? element.groupName.trim() : 'Others';
        if (trimmedGroup && !this.groupList.includes(trimmedGroup)) {
          this.groupList.push(trimmedGroup); // Add only if unique
        }
      });
    }

    console.log(this.groupList);
  }
  close() {
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    this.router.navigate(["/create-flow"]);
  }

  addItems() {
    var editItemsRequiredIndex = localStorage.getItem("editItemsRequiredIndex");

    if (editItemsRequiredIndex != null) {
      this.itemsRequired.splice(parseInt(editItemsRequiredIndex), 1);
      var item = {
        itemkey: this.iname,
        itemValue: this.quantity,
        url: this.Url,
        wheretofindit: this.wheretofindit,
        groupName: this.groupName,
      };

      this.itemsRequired.splice(parseInt(editItemsRequiredIndex), 0, item);
    } else {
      this.itemsRequired.push({
        itemkey: this.iname,
        itemValue: this.quantity,
        url: this.Url,
        wheretofindit: this.wheretofindit,
        groupName: this.groupName,
      });
    }
    if (this.groupName && !this.groupList.includes(this.groupName.trim())) {
      this.groupList.push(this.groupName.trim()); // Add only if it's unique
    }
    this.groupDataByGroupName();
    this.iname = "";
    this.quantity = "";
    this.Url = "";
    this.wheretofindit = "";
    // localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    localStorage.removeItem("editItemsRequiredIndex");
  }

  editItems(index, item) {
    console.log('ppp', { index, item });
    this.showImage = true;
    this.iname = item.itemkey;
    this.quantity = item.itemValue;
    this.Url = item.url;
    this.groupName = item.groupName;
    this.wheretofindit = item.wheretofindit;

    localStorage.setItem("editItemsRequiredIndex", index);
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  done() {
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {

      this.showNotificationCount = true;

      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  openLink(url) {
    url = "https://" + url;
    window.open(url);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }

  remove(index, item) { 
    const indexToDelete = this.itemsRequired.indexOf(item); 
    this.itemsRequired.splice(indexToDelete, 1);
    this.groupDataByGroupName(); 
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    }
  }

  selectFiles(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.noResultReturned = true;
      this.progress = 0; // Reset progress
      this.fileData = <File>input.files[0];
      const formData = new FormData();
      formData.append('fileData', this.fileData);
      this.fileName = this.fileData.name;
      this.isStepCoverPageURISet = true;

      // Determine if it's an image or video based on file extension
      const imageExtensions = ['JPEG', 'jpeg', 'png', 'PNG', 'jpg', 'JPG', 'JFIF', 'jfif', 'TIFF', 'tiff', 'TIF', 'tif'];
      const videoExtensions = [
        'MP4', 'mp4', 'MOV', 'mov', 'WMV', 'wmv', 'FLV', 'flv', 'AVCHD', 'avchd',
        'WEBM', 'webm', 'MKV', 'mkv', 'VOB', 'vob', 'OGV', 'ogv', 'GIFV', 'gifv',
        'M4P', 'm4p', 'M4V', 'm4v', '3GP', '3gp', '3G2', '3g2', 'AMV', 'amv',
        'NSV', 'nsv', 'OGG', 'ogg', 'F4V', 'f4v', 'F4P', 'f4p', 'F4A', 'f4a', 'F4B', 'f4b'
      ];

      const isImage = imageExtensions.some(ext => this.fileData!.name.includes(ext));
      const isVideo = videoExtensions.some(ext => this.fileData!.name.includes(ext));

      const uploadUrl = isImage
        ? `${environment.apiBaseUrl}${environment.uploadImageUrl}`
        : isVideo
          ? `${environment.apiBaseUrl}${environment.uploadVideoUrl}`
          : null;

      if (uploadUrl) {
        this.http
          .post(uploadUrl, formData, {
            reportProgress: true, // Enable progress reporting
            observe: 'events', // Observe all HTTP events
          })
          .subscribe({
            next: (event) => {
              if (event.type === HttpEventType.UploadProgress && event.total) {
                // Calculate and update progress percentage
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event.type === HttpEventType.Response) {
                // Handle successful response
                const mediaUploadStatus = event.body as any;
                this.noResultReturned = false;

                if (isImage) {
                  this.showImage = true;
                  this.showVideo = false;
                  this.Url = `${environment.imageStorageBaseUrl}${mediaUploadStatus.fileName}`;
                } else if (isVideo) {
                  this.showVideo = true;
                  this.showImage = false;
                  this.Url = `${environment.videoStorageBaseUrl}${mediaUploadStatus.fileName}`;
                }

                if (!mediaUploadStatus) {
                  alert(mediaUploadStatus.message);
                }
              }
            },
            error: (error) => {
              console.error('Upload failed:', error);
              this.noResultReturned = false;
              alert('Upload failed. Please try again.');
              this.progress = 0; // Reset progress on error
            },
            complete: () => {
              this.progress = 0; // Reset progress when complete
            },
          });
      } else {
        this.noResultReturned = false;
        alert('Unsupported file type.');
      }
    }
  }

  openImage(Url) {
    localStorage.setItem("itemsRequiredImageToShow", Url);

    this.dialog.open(ShowFlowImageDialogComponent, {});
  }
  removePicture() {
    this.Url = null;
  }

  onSelectGroup(event: MatAutocompleteSelectedEvent) {
    this.groupName = event.option.value; // Update the input with the selected value
  }
}
