import { MatDialog } from "@angular/material/dialog";

import { Component, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { ShowFlowImageDialogComponent } from "../show-flow-image-dialog/show-flow-image-dialog.component";
import { environment } from "src/environments/environment";
import { url } from "inspector";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
  selector: "app-items",
  templateUrl: "./items.component.html",
  styleUrls: ["./items.component.scss"],
})
export class ItemsComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flows: any;
  iname: string;
  itemsRequired: any[];
  quantity: string;
  fileData: File = null;
  noResultReturned: boolean;
  fileName: string;
  Url: string;
  isStepCoverPageURISet: boolean;
  showImage: boolean;
  showVideo: boolean;
  wheretofindit: any;
  progress: number = 0;
  groupName: string;
  groupedItems: { [key: string]: any[] } = {};
  groupList: string[] = []; // List of options
  groupedData: Record<string, any[]> = {};
  itemsRequiredCopy: any[];

  constructor(
    private userserviceService: UserserviceService,
    private dataService: DataService,

    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {


    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;


    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });

    this.itemsRequired = JSON.parse(localStorage.getItem("ItemsRequired"));
    if (this.itemsRequired == null) {
      this.itemsRequired = [];
    } else {
      this.groupDataByGroupName();
    }
    console.log(this.itemsRequired);
    this.getProfileUser();
    // this.groupItems();
  }

  groupDataByGroupName() {
    if (!this.itemsRequired || this.itemsRequired.length === 0) {
      this.groupedData = {}; // Ensure no errors on empty or undefined data
      return;
    }

    this.groupedData = this.itemsRequired.reduce((acc, item) => {
      const group = item.groupName || "Others"; // Default group if missing
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {} as Record<string, any[]>);

    this.getAllGroupName();
  }
  removePicture() {
    this.Url = null;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (!event.item.data) return;
  
    const previousGroup = event.previousContainer.id;
    const newGroup = event.container.id;
    const item = event.item.data;
  
    if (previousGroup !== newGroup) {
      // Remove item from old group
      this.groupedData[previousGroup] = this.groupedData[previousGroup].filter(
        (i) => i !== item
      );
  
      // Update the item with new group information (optional)
      item.groupName = newGroup; 
  
      // Add item to new group
      if (!this.groupedData[newGroup]) {
        this.groupedData[newGroup] = [];
      }
      this.groupedData[newGroup].push(item);
    } else {
      // Reorder within the same group
      moveItemInArray(
        this.groupedData[newGroup], 
        event.previousIndex, 
        event.currentIndex
      );
    }
  }

  
  getAllDropListIds(): string[] {
    return Object.keys(this.groupedData);
  }

  close() {
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    this.router.navigate(["/create-flow"]);
  }

  addItems() {
    var editItemsRequiredIndex = localStorage.getItem("editItemsRequiredIndex");

    if (editItemsRequiredIndex != null) {
      this.itemsRequired.splice(parseInt(editItemsRequiredIndex), 1);
    }
    this.itemsRequired.push({
      groupName: this.groupName,
      itemkey: this.iname,
      itemValue: this.quantity,
      url: this.Url,
      wheretofindit: this.wheretofindit,
    });

    if (this.groupName && !this.groupList.includes(this.groupName.trim())) {
      this.groupList.push(this.groupName.trim()); // Add only if it's unique
    }
    this.iname = "";
    this.quantity = "";
    this.Url = "";
    this.wheretofindit = "";
    // localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    localStorage.removeItem("editItemsRequiredIndex");
    this.groupDataByGroupName();
  }

  getAllGroupName() {
    if (this.itemsRequired && this.itemsRequired.length > 0) { // Ensure groups exist
      this.itemsRequired.forEach(element => {
        if (element.groupName) {
          const trimmedGroup = element.groupName.trim();
          if (trimmedGroup && !this.groupList.includes(trimmedGroup)) {
            this.groupList.push(trimmedGroup); // Add only if unique
          }
        }
      });
    }

    console.log(this.groupList);
  }
  editItems(index, item) {
    this.showImage = true;
    this.groupName = item.groupName;
    this.iname = item.itemkey;
    this.quantity = item.itemValue;
    this.Url = item.url;
    this.wheretofindit = item.wheretofindit;
    console.log(item.url);
    localStorage.setItem("editItemsRequiredIndex", index);
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  done() {
    localStorage.setItem("ItemsRequired", JSON.stringify(this.itemsRequired));
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {

      this.showNotificationCount = true;

      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  openLink(url) {
    url = "https://" + url;
    window.open(url);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }

  remove(index, item) { 
    const indexToDelete = this.itemsRequired.indexOf(item); 
    this.itemsRequired.splice(indexToDelete, 1);
    this.groupDataByGroupName(); 
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    }
  }

  selectFiles(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.fileName = this.fileData.name;
      this.isStepCoverPageURISet = true;

      // Add progress property if not already in your class
      // public progress: number = 0;

      const imageExtensions = ["JPEG", "jpeg", "png", "jpg", "PNG", "JFIF", "jfif", "TIFF", "tiff", "TIF", "tif", "JPG"];
      const videoExtensions = ["MP4", "mp4", "MOV", "mov", "WMV", "wmv", "FLV", "flv", "AVCHD", "avchd", "WEBM", "webm",
        "MKV", "mkv", "VOB", "vob", "OGV", "ogv", "GIFY", "gifv", "M4P", "m4p", "M4V", "m4v",
        "3GP", "3gp", "3G2", "3g2", "AMV", "amv", "NSV", "nsv", "OGG", "ogg", "F4V", "f4v",
        "F4P", "f4p", "F4A", "f4a", "F4B", "f4b"];

      const isImage = imageExtensions.some(ext => this.fileData.name.includes(ext));
      const isVideo = videoExtensions.some(ext => this.fileData.name.includes(ext));

      const uploadUrl = isImage
        ? environment.apiBaseUrl + environment.uploadImageUrl
        : environment.apiBaseUrl + environment.uploadVideoUrl;

      if (isImage || isVideo) {
        this.http
          .post(uploadUrl, formData, {
            reportProgress: true,
            observe: 'events'
          })
          .subscribe(
            (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.UploadProgress:
                  if (event.total) {
                    this.progress = Math.round((event.loaded / event.total) * 100);
                  }
                  break;

                case HttpEventType.Response:
                  const mediaUploadStatus = event.body as any;
                  this.noResultReturned = false;

                  if (mediaUploadStatus != null) {
                    if (isImage) {
                      this.showImage = true;
                      this.showVideo = false;
                      this.Url = environment.imageStorageBaseUrl + mediaUploadStatus.fileName;
                    } else if (isVideo) {
                      this.showVideo = true;
                      this.showImage = false;
                      this.Url = environment.videoStorageBaseUrl + mediaUploadStatus.fileName;
                    }
                  } else {
                    alert(mediaUploadStatus.message);
                  }
                  break;
              }
            },
            (error) => {
              this.noResultReturned = false;
              this.isStepCoverPageURISet = false;
              alert('Error uploading file');
              console.error('Upload error:', error);
            }
          );
      }
    }
  }

  openImage(Url) {
    localStorage.setItem("itemsRequiredImageToShow", Url);
    console.log(Url);
    this.dialog.open(ShowFlowImageDialogComponent, {});
  }

  onSelectGroup(event: MatAutocompleteSelectedEvent) {
    this.groupName = event.option.value; // Update the input with the selected value
  }
}
