import { BundleService } from "src/app/Services/bundleservice.service";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GetContentService } from "src/app/Services/getcontent.service";

@Component({
  selector: "app-generate-keyword",
  templateUrl: "./generate-keyword.component.html",
  styleUrls: ["./generate-keyword.component.scss"],
})
export class GenerateKeyword implements OnInit {
  selectedStyle: string = 'Generate keywords'; // Default style
  iskeywordGenerated: boolean = false;
  rephrasedText: string = '';
  showConfirm = false;
  title: any;
  originalContentTitleText: string;
  isTitleRephrased: any;
  predefinedKeywords: string[] = [];

  constructor(
    private getContentService: GetContentService,
    private toaster: ToastrService,
    public dialogRef: MatDialogRef<GenerateKeyword>,
    @Inject(MAT_DIALOG_DATA) public data: { textToRephrase: string, textDescrption: string }  // Input text passed from main component
  ) { }

  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  async getKeyWords(data) {
    const aiModel = {
      textToRephrase: data,
      userInput: "Generate Keywords"
    };

    try {
      this.iskeywordGenerated = true;
      const aiResponse = (await this.getContentService
        .generateKeyWord(aiModel))
        .subscribe((aiResponse) => {
          if (aiResponse) {
            console.log(aiResponse);
            this.iskeywordGenerated = false;
            const keywordsToCheck = [
              "I'm sorry", "it is not possible to extract keywords",
              "another string", "does not contain any specific keywords", '\"', "are:\n"
            ];

            const text = aiResponse[0].completions || ""; // Ensure text is a valid string

            // Remove the introductory text (before the keywords list)
            let cleanedText = text.replace(/^(The keywords from (the text|the following text) are:)\s*\n*/i, "").trim();

            // Extract keywords properly by splitting based on newline or comma
            let words = keywordsToCheck.some(keyword => cleanedText.includes(keyword))
              ? []
              : cleanedText.split(/[\n,-]+/).map(k => k.trim()).filter(k => k);

            // Check if the extracted keywords contain any invalid phrases
            const containsKeyword = keywordsToCheck.some(keyword => words.includes(keyword));

            // Assign valid keywords to `predefinedKeywords`
            this.predefinedKeywords = containsKeyword ? [] : words;

            console.log(this.predefinedKeywords);
            this.showConfirm = true;
            if (this.predefinedKeywords.length > 0) {
              this.toaster.success("Keywords generated successfully!");
            }
            this.confirmRephrase();
          }
        });
    } catch (error) {
      console.error('Error during rephrasing:', error);
    } finally {
      this.iskeywordGenerated = false;  // Ensure this runs regardless of success or error
    }
  }

  confirmRephrase() {
    console.log('Confirm Rephrase called'); // Debug log
    if (this.predefinedKeywords) {
      this.dialogRef.close({
        predefinedKeywords: this.predefinedKeywords,
        selectedStyle: this.selectedStyle
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
