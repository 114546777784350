import { UserMentionComponent } from "./../user-mention/user-mention.component";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { Component, OnInit } from "@angular/core";


import { UntypedFormBuilder } from "@angular/forms";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControlName,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { DataService } from "src/app/Services/dataservice.service";
import { Router } from "@angular/router";
import { UserserviceService } from "src/app/Services/userservice.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"],
})
export class CommentComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  showDefaultImage: boolean;
  comment: string;
  reply: string;
  comments = [];
  name: string;
  commentsForm: UntypedFormGroup;
  replyForm: UntypedFormGroup;
  flowData: any;
  userData: any;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  isBackSpacePressed: boolean;
  maxdesclenght = 500;
  currentdesclenght = 0;
  disableButton: boolean;
  noResultReturned: boolean;
  allUsers: any;
  tempUsers: any;
  isReplyClicked: boolean;
  previousComment = [];
  flowCoverImageUrl: any;
  bundleData: any;

  constructor(
    public formbuilder: UntypedFormBuilder,
    private flowServiceService: FlowServiceService,
    
    private dataService: DataService,
    private router: Router,
    private toastr: ToastrService,
    private userserviceService: UserserviceService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CommentComponent>
  ) { }

  ngOnInit(): void {
    this.flowServiceService.setScrollTop();
    this.commentsForm = new UntypedFormGroup({
      comment: new UntypedFormControl("", Validators.required),
    });
    this.replyForm = new UntypedFormGroup({
      reply: new UntypedFormControl("", Validators.required),
    });
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.flowData = JSON.parse(localStorage.getItem("flowcommentData"));

    if (this.flowData == null) {
      this.flowData = JSON.parse(localStorage.getItem("bundlecommentData"));
    }
    this.usersFlowComments();
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
  }

  users() {
    // this.noResultReturned = true;
    // 
    this.allUsers = [];
    this.tempUsers = [];
    localStorage.removeItem("searchuserpageIndex");
    localStorage.setItem("searchuserpageIndex", "0");

    this.getPaginatedUsers(undefined, "0");
  }

  replyToComment(comment) {
    if (this.previousComment.length > 0) {
      this.previousComment.forEach((element) => {
        element.isReplyClicked = false;
      });
    }
    this.reply = "";
    comment.isReplyClicked = true;
    this.previousComment.push(comment);
  }

  replyClicked(comment) { }

  getPaginatedUsers(infiniteScroll, pageIndex) {
    var email = "";

    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: "",
      searchString: "",
      userEmail: email,
      pageIndex: pageIndex,
    });

    this.userserviceService
      .getAllUsers(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        if (data.allUsers.length > 0) {
          data.allUsers.forEach((element) => {
            const user = {
              user: element,
              showFollow: false,
              followButtonText: element.followingText,
            };
            this.allUsers.push(user);
          });

          this.tempUsers = this.allUsers;

          this.tempUsers.forEach((element) => {
            if (element.followButtonText === "Following") {
              element.followButtonText = "Following";
              element.followingcolor = "black";
              element.followingbgcolor = "white";
            } else {
              element.followButtonText = "Follow";
              element.followingcolor = "white";
              element.followingbgcolor = "#5ea1d8";
            }
          });
        }
      });
  }

  showPopup: number;
  cursourPosition: number;
  previousKey: string;
  popupshown: boolean;
  searchInput: string;

  eventHandler(event: any) {
    if (this.previousKey == " ") {
    }

    if (event == "Backspace") {
      this.isBackSpacePressed = true;
      this.showPopup = NaN;
    } else {
      if (event == "@" && this.comment.length == 1) {
        this.showPopup = 0;
        this.popupshown = false;
        this.searchInput = "";
      }

      if (event == "@" && this.comment.length > 1 && this.previousKey == " ") {
        this.showPopup = 0;
        this.popupshown = false;
        this.searchInput = "";
      }

      if (event != " ") {
        if (
          event != "ShiftRight" &&
          event != "ShiftLeft" &&
          event != "ControlLeft" &&
          event != "ControlRight" &&
          event != "Escape" &&
          event != "CapsLock"
        ) {
          this.searchInput = this.searchInput + event;
          this.showPopup++;
        }
        if (this.showPopup == 3 && !this.popupshown) {
          this.popupshown = false;
          this.showPopup = NaN;
          //this.cursourPosition = event.target.selectionStart;
          this.showPopover(
            this.searchInput.substring(1, this.searchInput.length)
          );
        }
        this.previousKey = event;
      } else {
        this.previousKey = event;
        this.showPopup = NaN;
      }

      this.isBackSpacePressed = false;
    }
  }

  addReply(comment) { 
    if (
      this.userData != null &&
      !this.flowData.isFlowPurchased &&
      this.flowData.price != null &&
      this.flowData.isPaid &&
      this.userData.emailAddress !== this.flowData.customerEmail
    ) {
      this.toastr.success("To add comment please purchase flow");
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      this.router.navigate(["/checkout"]);
      return;
    }

    if (this.reply.length > 0) { 
    }
    comment.IsReplyClicked = false; 

    this.noResultReturned = true;
    const commentModel = {
      FlowId: this.flowData.rowKey,
      CommentedByUserEmail: this.userData.emailAddress,
      Comment: this.reply,
      ParentCommentId:
        comment.parentCommentId == null ? comment.rowKey : comment.parentCommentId,
    }; 
    this.flowServiceService.addComment(commentModel).subscribe((data) => {
      this.noResultReturned = false;
      this.comment = "";
      this.showToastForComment();
      this.usersFlowComments();
    });
  }

  showPopover(usertext) {
    if (!this.isBackSpacePressed) {
      let ev = {
        target: {
          getBoundingClientRect: () => {
            return {
              top: 5,
              left: 110,
            };
          },
        },
      };

      localStorage.setItem("filterByUserName", usertext);

      const popover = this.dialog.open(UserMentionComponent, {
        height: "500px",
        data: { ev },
      });
      popover.afterClosed().subscribe((data) => {
        if (data != null) {
          if (this.comment.length == 3) {
            this.comment = data.userName;
          } else {
          }
        }
      });
    } else {
      this.isBackSpacePressed = false;
    }
  }

  descChanged() {
    this.eventHandler(this.comment.substring(this.comment.length - 1));
    if (this.comment.length == this.maxdesclenght) {
      this.comment = this.comment.substring(0, this.comment.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght)
      this.currentdesclenght = this.comment.length;
    this.setIsDisable();
  }

  setIsDisable() {
    if (this.currentdesclenght > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  likeComment(comment) { 
    if (this.userData != null) {
      var email = "";

      if (this.userData != null && this.userData != undefined) {
        email = this.userData.emailAddress;
      }

      //
      this.noResultReturned = true;

      const flowModel = {
        PartitionKey: comment.rowKey,
        LikedByUserEmail: email,
        CustomerRowKey: this.userData.rowKey
      }; 

      this.flowServiceService.LikeComment(flowModel).subscribe((data) => { 
        this.noResultReturned = false;
        if (data.isCommentLiked === "liked") {
          comment.isCommentLiked = "liked";
        } else if (data.isCommentLiked === "unliked") {
          comment.isCommentLiked = "unliked";
        }
      });
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  usersFlowComments() {
    this.comments = [];
    this.noResultReturned = true;
    var email = "";

    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: "",
      searchString: "",
      userEmail: email,
      pageIndex: 0,
      partitionKey: this.flowData.rowKey
    }); 

    //   
    this.flowServiceService
      .getUsersFlowComments(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        // 
        if (data.flowComments.length > 0) {
          data.flowComments.forEach((element) => {
            if (this.userData.emailAddress === element.commentedByUserEmail) {
              element.isFlowOwner = true;
            }
            this.comments.push(element);
          });
        }
        this.getProfileUser();
        this.users();
      });
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      this.noResultReturned = true;
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          this.noResultReturned = false;
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            //           
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  editComment(comment) {
    this.dataService.changeMessage(comment);
    this.router.navigate(["/edit-comment"]);
    this.dialogRef.close();
  }

  viewUserProfile(commentedByUserEmail) {
    if (this.userData != null) {
      if (this.userData.emailAddress === commentedByUserEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }

      var flow = { CustomerEmail: commentedByUserEmail };

      this.dataService.flowByCategorieschangeMessage(flow);
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  deleteComment(comment) {
    var flowQueryModel = new FlowQueryModel({
      rowKey: comment.rowKey
    });

    this.noResultReturned = true;
    this.flowServiceService
      .deleteComment(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        this.usersFlowComments();
      });
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  showToastForComment() {
    this.toastr.success("Comment added Successfully");
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    }
  }

  addComment() { 
    if (
      this.userData != null &&
      !this.flowData.isFlowPurchased &&
      this.flowData.price != null &&
      this.flowData.isPaid &&
      this.userData.emailAddress !== this.flowData.customerEmail
    ) {
      this.toastr.success("To add comment please purchase flow");
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      this.router.navigate(["/checkout"]);
      this.closePopup();
      return;
    }

    this.noResultReturned = true;
    const commentModel = {
      FlowId: this.flowData.rowKey,
      CommentedByUserEmail: this.userData.emailAddress,
      Comment: this.comment,
    };

    this.flowServiceService.addComment(commentModel).subscribe((data) => {
      this.noResultReturned = false;
      this.comment = "";
      this.showToastForComment();
      this.usersFlowComments();
    });
  }

  editComments(comment) { 
    if (
      this.userData != null &&
      !this.flowData.isFlowPurchased &&
      this.flowData.price != null &&
      this.flowData.isPaid &&
      this.userData.emailAddress !== this.flowData.customerEmail
    ) {
      this.toastr.success("To add comment please purchase flow");
      localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
      this.router.navigate(["/checkout"]);
      this.closePopup();
      return;
    }

    this.noResultReturned = true;
    const commentModel = {
      FlowId: this.flowData.rowKey,
      CommentedByUserEmail: this.userData.emailAddress,
      Comment: this.comment,
    };

    this.flowServiceService.addComment(commentModel).subscribe((data) => {
      this.noResultReturned = false;
      this.comment = comment;
      this.showToastForComment();
      this.usersFlowComments();
    });
  }

  close() {
    var fromBundleData = localStorage.getItem("FromBundleData");
    if (fromBundleData) {
      this.router.navigate(["/viewbundle/" + this.flowData.rowKey]);
    } else {
      this.router.navigate([`/guide/${this.generateUrlSlug(this.flowData.title)}/${this.flowData.rowKey}`]); 
    }
    localStorage.removeItem("FromBundleData");
    this.dialogRef.close();
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.innerHTML = '';
    }
  }

  closePopup() {
    this.dialogRef.close();
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.innerHTML = '';
    }
  }
  generateUrlSlug(html) {
    if (!html) return '';
    
    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
    }
}
