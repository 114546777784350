import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlName,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";
 

import { CustomValidators } from "src/app/custom-validators";
import { Endpoint } from "src/app/shared/endpoints";

@Component({
  selector: "app-change-old-password",
  templateUrl: "./change-old-password.component.html",
  styleUrls: ["./change-old-password.component.scss"],
})
export class ChangeOldPasswordComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  oldPassword: string;
  Password: string;
  ConfirmPassword: string;
  userEmail: string;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  noResultReturned: boolean;

  constructor(
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,
    private router: Router,
    
  ) {}

  changeOldPasswordForm = new UntypedFormGroup({
    oldPassword: new UntypedFormControl("", [Validators.required, Validators.email]),
    Password: new UntypedFormControl(
      "",
      Validators.compose([
        Validators.required,
        // 2. check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(
          /[!,@,#,$,%,^,&,*,-,_,:,;,",',<,>,`,~,{,},/,?,|,(,)]/,
          { hasSpecialCharacters: true }
        ),
        Validators.minLength(8),
      ])
    ),
    ConfirmPassword: new UntypedFormControl("", [
      Validators.required,
      Validators.minLength(8),
      this.equalto("Password"),
    ]),
  });

  ngOnInit(): void {
    
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;

    
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.changeOldPasswordForm = this.formbuilder.group({
      oldPassword: new UntypedFormControl("", Validators.required),
      Password: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.patternValidator(
            /[!,@,#,$,%,^,&,*,-,_,:,;,",',<,>,`,~,{,},/,?,|,(,)]/,
            { hasSpecialCharacters: true }
          ),
          Validators.minLength(8),
        ])
      ),
      ConfirmPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
        this.equalto("Password"),
      ]),
    });

    this.getProfileUser();
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }

  changeOldPassword() {
    this.noResultReturned = true;
    const userModel = {
      EmailAddress: this.userData.emailAddress,
      Password: this.Password,
      ConfirmPassword: this.ConfirmPassword,
      OldPassword: this.oldPassword,
    };
    this.userserviceService
      .changeOldPassword(Endpoint.ChangePassword, userModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        this.router.navigate(["/login"]);
      });
  }

  equalto(field_name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }
}
