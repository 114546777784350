import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DialogExampleComponent } from "src/app/dialog-example/dialog-example.component";
import { BundleService } from "src/app/Services/bundleservice.service";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DeleteBundleDialogComponent } from "../delete-bundle-dialog/delete-bundle-dialog.component";
import { EmbedforbundlesComponent } from "../embedforbundles/embedforbundles.component";
import { QRCodeComponent } from "../qrcode/qrcode.component";
import { BundleqrcodeComponent } from "../bundleqrcode/bundleqrcode.component";
import { CommentComponent } from "../comment/comment.component";
import { ReportBundleComponent } from "../report-bundle/report-bundle.component";
import { SupportDocumentComponent } from "../support-document/support-document.component";
import { RestrictedAccessComponent } from "../restricted-access/restricted-access.component";
import { RestrictedforbundleComponent } from "../restrictedforbundle/restrictedforbundle.component";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { ShowFlowImageDialogComponent } from "../show-flow-image-dialog/show-flow-image-dialog.component";

@Component({
  selector: "app-view-bundle",
  templateUrl: "./view-bundle.component.html",
  styleUrls: ["./view-bundle.component.scss"],
})
export class ViewBundleComponent implements OnInit {
  imageSource: string;
  userData: any;
  bundleData: any;
  noResultReturned: boolean;
  likecolor: boolean;
  flowData: any;
  viewCount: number;
  viewText: string;
  bookmarkcolor: string;
  profilePicture: any;
  showDefaultImage: boolean;
  showNotificationCount: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  userDisplayName: any;
  uname: any;
  userProfilePicture: any;
  showMenu: boolean;
  videoPlayCounter: any;
  viewUnloaded: any;
  showVideoOrImage: boolean;
  showVideo: boolean;
  isPurchaseRequired: boolean;
  isBundleHomePage: boolean;
  viewsCount: any;
  saveCount: any;

  constructor(
    private bundleService: BundleService,
    private activateRouter: ActivatedRoute,
    private dataService: DataService,
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    private toastr: ToastrService,
    public router: Router,
    public dialog: MatDialog,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.showImage = false;
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.activateRouter.paramMap.subscribe((params) => {
      var bundleId = params.get("id");
      if (bundleId != null && bundleId != '') this.getBundleDetails(bundleId);
    });

  }

  recordPageView() {

    var email = '';
    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress
    }

    var getPageViewDetails = {
      ContentId: this.bundleData.rowKey,
      ContentTitle: this.bundleData.title,
      UserEmail: email,
      ContentType: this.bundleData.type,
      ContentUrl: this.bundleData.url
    };


    this.flowServiceService.postPageView(getPageViewDetails).subscribe(res => {

    });
  }

  purchaseBundle() {
    var email = "";
    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }
    if (
      this.bundleData.price != null &&
      this.bundleData.isPaid &&
      this.userData == null
    ) {
      localStorage.setItem("checkoutFlow", JSON.stringify(this.bundleData));
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    } else if (
      this.bundleData.price != null &&
      this.bundleData.isPaid &&
      !this.bundleData.isFlowPurchased &&
      email != this.bundleData.customerEmail
    ) {
      if (this.userData != null) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.bundleData));
        this.router.navigate(["/checkout"]);
      } else {
      //   this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
      }
    }
  }

  showImage: boolean;
  getBundleDetails(bundleId) {
    // 
    this.noResultReturned = true;
    var email = "";
    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      BundleId: bundleId,
      userEmail: email
    });


    this.bundleService.getBundleById(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;

      if (data.bundleModel != null) {
        this.bundleData = data.bundleModel;
        console.log(this.bundleData);
        this.titleandmetaserviceService.setTitle(this.bundleData.title);
        this.showVideoFromFile(this.bundleData.url);
        this.isPurchaseRequired = false;

        if (
          this.userData != null &&
          this.userData.emailAddress != this.bundleData.customerEmail
        ) {
          this.showMenu = true;
          this.isPurchaseRequired =
            !this.bundleData.isFlowPurchased && this.bundleData.isPaid;
        } else if (
          this.userData != null &&
          this.userData.emailAddress == this.bundleData.customerEmail
        ) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
          this.isPurchaseRequired =
            !this.bundleData.isFlowPurchased && this.bundleData.isPaid;
        }

        if (data.bundleModel.isLiked === "liked") {
          this.likecolor = true;
        } else if (data.bundleModel.isLiked === "unliked") {
          this.likecolor = false;
        }

        if (data.bundleModel.isSaved === true) {
          this.bookmarkcolor = "#5ea1d8";
          this.saveCount = data.bundleModel.saveCount;
        } else {
          this.bookmarkcolor = "gray";
          this.saveCount = data.bundleModel.saveCount;
        }
        console.log(this.bundleData)
        this.viewsCount = this.bundleData.viewsCount;

        if (
          this.bundleData.profilePic != undefined &&
          this.bundleData.profilePic != null &&
          this.bundleData.profilePic != ""
        ) {
          this.showImage = true;
        } else {
          this.showImage = false;
        }
        // }
        this.recordPageView();
      } else {
        this.toastr.success(
          "The bundle you are trying to open is no longer available. Explore how-to DIY flows here"
        );
        this.router.navigate(["/"]);
      }
    });
  }
  onChange() { }


  likeFlow() {
    if (this.userData != null) {
      const flowModel = {
        PartitionKey: this.bundleData.rowKey,
        LikedByUserEmail: this.userData.emailAddress,
      };

      this.bundleService.LikeBundle(flowModel).subscribe((data) => {
        //this.noResultReturned = false; 
        console.log('liked', this.bundleData);
        this.bundleData.likedFlowCount = data.totalLikes;
        console.log(data);
        if (data.liked === "liked") {
          this.likecolor = true;
          this.bundleData.likeCount = this.bundleData.likeCount + 1;
          this.showToast();
        } else if (data.liked === "unliked") {
          this.likecolor = false;
          this.bundleData.likeCount = this.bundleData.likeCount - 1;
          this.showToastForUnlike();
        }
        this.viewsCount = data.viewsCount;

        if (this.viewsCount > 1) {
          this.viewText = "Views";
        } else {
          this.viewText = "View";
        }

        //
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    }
  }

  showToast() {
    this.toastr.success("Bundle Liked");
  }
  showToastForUnlike() {
    this.toastr.success("Bundle Unliked");
  }

  saveFlow() {
    if (this.userData != null) {
      //
      this.noResultReturned = true;
      const flowModel = {
        UserEmail: this.userData.emailAddress,
        FlowId: this.bundleData.rowKey,
        FlowType: 2,
      };
      this.flowServiceService.postBookMarkFlow(flowModel).subscribe((data) => {
        this.noResultReturned = false;
        // 
        if (data.bookMarked === "bookmarked") {
          this.bookmarkcolor = "#5ea1d8";
          this.showToastforSaved();
        } else if (data.bookMarked === "bookmarkremoved") {
          this.bookmarkcolor = "gray";
          this.showToastforUnsaved();
        }
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    }
  }

  showToastforSaved() {
    this.toastr.success("Bundle Saved");
  }
  showToastforUnsaved() {
    this.toastr.success("Bundle Unsaved");
  }
  openImage(url){
  localStorage.setItem("itemsRequiredImageToShow", url);

  this.dialog.open(ShowFlowImageDialogComponent, {});
}
  flowClicked(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "/");
    if (flow.Type == "Bundle") {
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else { 
      this.router.navigate([`/guide/${this.generateUrlSlug(flow.title)}/${flow.rowKey}`]);
    }
  }

  generateUrlSlug(html) {
    if (!html) return ''; 
    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }

  viewUserProfile(selectedFlow) {
    const userFlows = [];
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedFlow.customerEmail) {
        userFlows.push(selectedFlow);
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    }
  }

  getProfilepic(): void {
    if (this.userData != null) {
      this.userserviceService
        .getProfilepic()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
        });
    }
  }

  getProfileUser(): void {
    if (this.userData != null) {
      //  
      this.noResultReturned = true;
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          this.noResultReturned = false;

          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            //
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.userDisplayName = this.uname = data.userData.userName;
          this.uname = data.userData.firstName;
        });
    }
  }

  ViewSupportingDocuments() {
    if (this.bundleData.isPaid && this.userData == null) {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    } else {
      //this.router.navigate(["/in-Appropriate"]);
      localStorage.setItem("flowData", JSON.stringify(this.bundleData));
      const dialogRef = this.dialog.open(SupportDocumentComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }

  onChatClick() {
    if (this.userData != null) {
      localStorage.setItem(
        "bundlecommentData",
        JSON.stringify(this.bundleData)
      );
      localStorage.removeItem("flowcommentData");
      localStorage.setItem("FromBundleData", "yes");
      //  this.router.navigate(["/comment"]);
      // this.router.navigate(["/bundleqrcode"]);
      const dialogRef = this.dialog.open(CommentComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.noResultReturned = false;
      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // });
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']);  
    }
  }
  InAppropriate() {
    if (this.userData != null) {
      localStorage.setItem("bundleData", JSON.stringify(this.bundleData));
      //this.router.navigate(["/reportBundle"]);
      const dialogRef = this.dialog.open(ReportBundleComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => {

      });
    } else {
      // this.router.navigate(['/login'], {
      //   queryParams: { returnUrl: this.router.url }
      // }); 
      localStorage.setItem("returnUrl", this.router.url);
      this.router.navigate(['/registration']); 
    }

  }

  openDialog() {
    localStorage.setItem("bundleIdToBeDeleted", this.bundleData.rowKey);
    localStorage.setItem("bundleData", JSON.stringify(this.bundleData));
    this.dialog.open(DeleteBundleDialogComponent, {
      height: "180px",
      width: "250px",
    });
  }

  editBundle(bundleData) {
    localStorage.removeItem("bundleData");
    localStorage.removeItem("bundleIdToBeDeleted");
    localStorage.removeItem("addbundleselectedflows");
    localStorage.removeItem("createbundledetails");

    localStorage.removeItem("editBundlesupportingDocumentHyperLinksIndex");
    localStorage.removeItem("BundleSupportingDocumentHyperLinksRequired");
    localStorage.removeItem("editBundleSupportingDocumentRequiredIndex");
    localStorage.removeItem("BundleSupportingDocumentDocuments");
    this.router.navigate(["/editbundle/" + bundleData.rowKey]);
  }

  goToQrCode(bundleData) {
    localStorage.setItem("bundleData", JSON.stringify(bundleData));
    // this.router.navigate(["/bundleqrcode"]);
    const dialogRef = this.dialog.open(BundleqrcodeComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  recordLinkClick(iname) {
    console.log(this.flowData);
    var linkClickDetails = {
      PartitionKey: this.flowData.rowKey,
      Link: iname.wheretofindit,
      LinkTitle: iname.itemkey,
      ContentTitle: this.flowData.title,
      UserEmail: this.userData.emailAddress,
      ContentType: this.flowData.type,
      ContentUrl: this.flowData.url
    };

    linkClickDetails.Link = iname.wheretofindit;
    linkClickDetails.LinkTitle = iname.itemkey;


    this.flowServiceService.postLinkClick(linkClickDetails).subscribe(res => {

    });
  }
  openLink(url) {
    if (this.isPurchaseRequired) return;
    else {
      if (url.includes("https://")) {
        url = url;
        window.open(url);
        this.recordLinkClick(url);
      } else {
        url = "https://" + url;
        window.open(url);
        this.recordLinkClick(url);
      }
    }
  }
  openDocument(documentName) {
    if (this.isPurchaseRequired) return;
    window.open(documentName);
  }

  vidEnded(event) {
    var playcount = 0;
    var video = this.videoPlayCounter.filter(
      (v) => v.currentSrc == event.target.currentSrc
    );
    if (video != null) {
      playcount = video.length;
    }

    if (playcount < 3) {
      this.videoPlayCounter.push({
        eventobj: event,
        currentSrc: event.target.currentSrc,
        playcount: playcount,
      });
      event.target.play();
    }
  }

  vidfocus(event) { }

  vidplay(event) {
    if (!this.viewUnloaded) {
      var videos = this.videoPlayCounter.filter((v) => v.id == event.target.id);

      if (videos.length > 3) {
        videos.forEach((element) => {
          var obj = this.videoPlayCounter.indexOf(element);

          if (obj > -1) {
            this.videoPlayCounter.splice(obj, 1);
          }
        });
      }
      event.target.play();
    }
  }

  showVideoFromFile(filePath: string) {
    if (
      filePath.includes("MP4") ||
      filePath.includes("mp4") ||
      filePath.includes("MOV") ||
      filePath.includes("mov") ||
      filePath.includes("WMV") ||
      filePath.includes("wmv") ||
      filePath.includes("FLV") ||
      filePath.includes("flv") ||
      filePath.includes("AVCHD") ||
      filePath.includes("avchd") ||
      filePath.includes("WEBM") ||
      filePath.includes("webm") ||
      filePath.includes("MKV") ||
      filePath.includes("mkv") ||
      filePath.includes("VOB") ||
      filePath.includes("vob") ||
      filePath.includes("OGV") ||
      filePath.includes("ogv") ||
      filePath.includes("GIFY") ||
      filePath.includes("gifv") ||
      filePath.includes("M4P") ||
      filePath.includes("m4p") ||
      filePath.includes("M4V") ||
      filePath.includes("m4v") ||
      filePath.includes("3GP") ||
      filePath.includes("3gp") ||
      filePath.includes("3G2") ||
      filePath.includes("3g2") ||
      filePath.includes("AMV") ||
      filePath.includes("amv") ||
      filePath.includes("NSV") ||
      filePath.includes("nsv") ||
      filePath.includes("OGG") ||
      filePath.includes("ogg") ||
      filePath.includes("FLV") ||
      filePath.includes("flv") ||
      filePath.includes("F4V") ||
      filePath.includes("f4v") ||
      filePath.includes("F4P") ||
      filePath.includes("f4p") ||
      filePath.includes("F4A") ||
      filePath.includes("f4a") ||
      filePath.includes("F4B") ||
      filePath.includes("f4b")
    ) {
      this.showVideo = true;
      this.imageSource = filePath;
    } else {
      this.showImage = false;
      this.imageSource = filePath;
    }
  }

  saveFlowToServerAccessLevel(AccessType) {
    var bundleModel = {
      Id: this.bundleData.rowKey,
      AccessType: AccessType,
    };
    if (this.userData != null) {
      // 
      this.noResultReturned = true;
      this.flowServiceService
        .saveFlowToServerAccessLevel(bundleModel, this.userData.emailAddress)
        .subscribe((data) => {
          this.bundleData.accessType = bundleModel.AccessType;
          this.noResultReturned = false;
        });
    }
  }

  restrictedAccessPage(accessType) {
    this.bundleData.AccessType = accessType;
    //this.router.navigate(["/restrictedPage"]);
    localStorage.setItem("flowModel", JSON.stringify(this.bundleData));
    const dialogRef = this.dialog.open(RestrictedAccessComponent, {
      panelClass: "dialoguebox",
      height: "550px",
      width: "550px",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  flowByCategory(category) {
    localStorage.setItem("flowByCategoryCategoryName", category);
    this.router.navigate(["/flowbycategory"]);
  }

  openForECode(flowData: any) {
    localStorage.setItem("flowsData", JSON.stringify(flowData));
    const dialogRef = this.dialog.open(EmbedforbundlesComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
