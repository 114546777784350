import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class TitleandmetaserviceService {
  constructor(private meta: Meta, private title: Title) {}

  setTitle(title) {
    this.title.setTitle(title);
  }

  setTags(flows) { 
    // flows.forEach((element) => {
    //   element.FlowModels.forEach((element) => {
    //     this.meta.addTags([
    //       { name: "title", content: element.Title },
    //       { name: "uniquecode", content: element.UniqueCode },
    //       { name: "username", content: element.UserName },
    //       { name: "description", content: element.Description },
    //       { name: "categories", content: element.Type },
    //       { name: "Type", content: element.Type },
    //     ]);
    //   });
    // });

    this.meta.addTags([
      {
        name: "title",
        content: "Flowchest - Create and share how-to DIY instruction guides",
      },
      {
        name: "description",
        content:
          "With flowchest you can create and share how-to DIY instructions in easy step-by-step format to guide your audience when completing tasks.",
      },
    ]);
  }

  setKeywords(keywords) { 
    keywords.forEach((element) => { 
      this.meta.addTags([ 
        { name: "Keywords", content: element }
      ]);
    });
  }

  setArticalTags(flows) {
    flows.forEach((element) => {
      this.meta.addTags([
        { name: "title", content: element.title },
        { name: "description", content: element.description },
      ]);
    });
  }

  setCanonicalUrl(url) { 
    // Remove existing canonical tag to prevent duplicates
    this.meta.removeTag('link[rel="canonical"]');

    // Add a new canonical tag
    this.meta.addTag({
      rel: 'canonical',
      href: url,
    });
  }
}
