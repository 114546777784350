
import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DataService } from "src/app/Services/dataservice.service";
import { HttpClient, HttpEvent, HttpResponse } from "@angular/common/http";
import { HttpEventType } from "@angular/common/http";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { link } from "fs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { GetContentService } from "src/app/Services/getcontent.service";
import { log } from "console";
import { SelectionRephraseAIComponent } from "../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { GenerateKeyword } from "../generate-keyword/generate-keyword.component";
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: "app-cover-page",
  templateUrl: "./cover-page.component.html",
  styleUrls: ["./cover-page.component.scss"],
})
export class CoverPageComponent implements OnInit {
  fileData: File = null;
  images: any = [];
  allfiles: any = [];
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flows: string;
  showNotificationCount: boolean;
  notificationCount: number;
  maxtitlelenght = 80;
  maxdesclenght = 500;
  currenttitlelenght = 0;
  currentdesclenght = 0;
  enableButton: boolean;
  title: string;
  @Input() description: string = '';
  imageURI: any;
  videoURI: any;
  coverPageURI: any;
  coverPosterPageURI: any;
  flowId: any;
  uniqueId: any;
  type: any;
  showImage: boolean;
  showVideo: boolean;
  fileName: string;
  posterURI: any;
  price: any;
  isPaid: boolean;
  noResultReturned: boolean;
  duration: number;
  Url: string;
  documentName: any;
  filename: string;
  supportingDocumentDocuments: any[];
  supportingDocumentHyperLinks: any[];
  isAddSupportingDocument: boolean;
  linkName: any;
  hyperLink: any;
  sffilename: any;
  sffilelink: any;
  tabIndex: any;
  FlowCount: any;
  noApprovedFlows: any;
  totalSupportingDocuments: number;
  rowKey: any;
  partitionKey: any;
  progress: number;
  isLoading: boolean;
  keywords: string[] = [];
  predefinedKeywords: string[] = ["Flowchest"];
  keyword: string = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userserviceService: UserserviceService,
    private dataService: DataService,
    private toastManager: ToastrService,
    public dialog: MatDialog,
    private getcontentService: GetContentService,
    private flowServiceService: FlowServiceService
  ) {
    this.title = "";
    this.description = "";
    this.documentName = "";
    this.enableButton = true;
    this.duration = 0;
    this.supportingDocumentHyperLinks = [];
    this.supportingDocumentDocuments = [];
    this.totalSupportingDocuments = 0;
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();

    //
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    this.noResultReturned = false;
    //
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    this.dataService.setFlowByCategorieschangeMessage.subscribe((flowData) => {
      this.flows = flowData;
      this.flows = JSON.parse(localStorage.getItem("flowByCategory"));
    });

    var flowCoverPage = JSON.parse(
      localStorage.getItem("flowCoverPageDetails")
    );

    if (flowCoverPage != null) {
      this.isImageOrVideo(flowCoverPage.coverImage);
      this.coverPageURI = flowCoverPage.coverImage;
      this.price = flowCoverPage.price;
      this.coverPosterPageURI = flowCoverPage.coverPosterPageURI;
      this.title = flowCoverPage.title;
      this.duration = flowCoverPage.duration;
      this.description = flowCoverPage.description;
      this.flowId = flowCoverPage.flowId;
      this.uniqueId = flowCoverPage.uniqueId;
      this.type = flowCoverPage.type;
      this.isPaid = flowCoverPage.isPaid;
      this.rowKey = flowCoverPage.rowKey;
      this.partitionKey = flowCoverPage.partitionKey;
      this.keywords = flowCoverPage.searchKeyWords;
    }
    var supportingDocumentDocuments = JSON.parse(
      localStorage.getItem("SupportingDocumentDocuments")
    );

    var supportingDocumentHyperLinks = JSON.parse(
      localStorage.getItem("SupportingDocumentHyperLinksRequired")
    );

    if (supportingDocumentDocuments != null) {
      this.supportingDocumentDocuments = supportingDocumentDocuments;
    }

    if (supportingDocumentHyperLinks != null) {
      this.supportingDocumentHyperLinks = supportingDocumentHyperLinks;
    }

    this.getProfileUser();
    this.titleChanged();
  }

  addKeyword(event: any): void {
    const input = event.input;
    const value = event.value;
    // const newKeyword = this.keyword.trim();
    if (value && !this.keywords.includes(value)) {
      this.keywords.push(value);
      // Clear input field
      if (input) {
        input.value = '';
      }
    }

  }

  updateInputField(selectedKeyword: string) {
    this.keyword = selectedKeyword; // Update input field when selecting from the list
  }

  removeKeyword(index: number) {
    this.keywords.splice(index, 1);
  }

  editKeyword(index: number, key: string) {
    this.keyword = key;
    this.keywords.splice(index, 1);
  }

  isImageOrVideo(url) {
    if (
      url.includes("JPEG") ||
      url.includes("jpeg") ||
      url.includes("JPG") ||
      url.includes("JFIF") ||
      url.includes("PNG") ||
      url.includes("jfif") ||
      url.includes("png") ||
      url.includes("tiff") ||
      url.includes("TIFF") ||
      url.includes("TIF") ||
      url.includes("tif") ||
      url.includes("jpg")
    ) {
      this.showImage = true;
      this.showVideo = false;
    } else if (
      url.includes("MP4") ||
      url.includes("mp4") ||
      url.includes("MOV") ||
      url.includes("mov") ||
      url.includes("WMV") ||
      url.includes("wmv") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("AVCHD") ||
      url.includes("avchd") ||
      url.includes("WEBM") ||
      url.includes("webm") ||
      url.includes("MKV") ||
      url.includes("mkv") ||
      url.includes("VOB") ||
      url.includes("vob") ||
      url.includes("OGV") ||
      url.includes("ogv") ||
      url.includes("GIFY") ||
      url.includes("gifv") ||
      url.includes("M4P") ||
      url.includes("m4p") ||
      url.includes("M4V") ||
      url.includes("m4v") ||
      url.includes("3GP") ||
      url.includes("3gp") ||
      url.includes("3G2") ||
      url.includes("3g2") ||
      url.includes("AMV") ||
      url.includes("amv") ||
      url.includes("NSV") ||
      url.includes("nsv") ||
      url.includes("OGG") ||
      url.includes("ogg") ||
      url.includes("FLV") ||
      url.includes("flv") ||
      url.includes("F4V") ||
      url.includes("f4v") ||
      url.includes("F4P") ||
      url.includes("f4p") ||
      url.includes("F4A") ||
      url.includes("f4a") ||
      url.includes("F4B") ||
      url.includes("f4b")
    ) {
      this.showVideo = true;
      this.showImage = false;
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  submit() {
    this.noResultReturned = true;
    //
    var flowCoverPage = {
      coverImage: this.coverPageURI,
      description: this.description,
      coverPosterPageURI: this.coverPosterPageURI,
      title: this.title,
      flowId: this.flowId,
      uniqueId: this.uniqueId,
      type: this.type,
      duration: this.duration,
      fileName: this.fileName,
      price: this.price,
      isPaid: this.isPaid,
      rowKey: this.rowKey,
      partitionKey: this.partitionKey,
      searchKeyWords: this.keywords.join(", ")
    };

    localStorage.setItem("flowCoverPageDetails", JSON.stringify(flowCoverPage));

    this.noResultReturned = false;
    this.router.navigate(["/create-flow"]);
  }

  checkValue() {
    if (!this.isPaid) {
      this.price = null;
    }
  }

  getProfileUser(): void {
    if (this.userData != null) {
      this.noResultReturned = true;
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser().pipe(
          catchError((error) => {
            // Handle the error here
            console.error('Error occurred while fetching profile user:', error);
            // You can return a default value or re-throw the error if needed
            return of(null); // Returning null as a default value
            // Or you can re-throw the error
            // return throwError(error);
          })
        )
        .subscribe((data) => {
          this.noResultReturned = false;
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            // 
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      });
    }
  }
  removePicture() {
    this.profilePicture = null;
  }
  setPosterImage(fileData) {
    let url = fileData.replace("." + this.getExtention(fileData), ".jpg");
    this.posterURI = url;
  }

  getExtention(fileData) {
    return fileData.slice(((fileData.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.supportingDocumentDocuments,
      event.previousIndex,
      event.currentIndex
    );
  }
  close() {
    localStorage.setItem(
      "supportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    this.router.navigate(["/create-flow"]);
  }

  editLink(index, link) {
    this.linkName = link.linkName;
    this.hyperLink = link.linkUrl;
    localStorage.setItem("editsupportingDocumentHyperLinksIndex", index);
  }

  addLink() {
    var editsupportingDocumentHyperLinksIndex = localStorage.getItem(
      "editsupportingDocumentHyperLinksIndex"
    );
    if (editsupportingDocumentHyperLinksIndex != null) {
      this.supportingDocumentHyperLinks.splice(
        parseInt(editsupportingDocumentHyperLinksIndex),
        1
      );
      var slink = {
        linkName: this.linkName,
        linkUrl: this.hyperLink,
      };
      this.supportingDocumentHyperLinks
        .splice(parseInt(editsupportingDocumentHyperLinksIndex), 0, slink);
    } else {
      this.supportingDocumentHyperLinks.push({
        linkName: this.linkName,
        linkUrl: this.hyperLink,
      });
    }
    this.linkName = "";
    this.hyperLink = "";
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );
    localStorage.removeItem("editsupportingDocumentHyperLinksIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  addSupportingDocument() {
    if (
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length >=
      10
    ) {
      return;
    }

    var editSupportingDocumentRequiredIndex = localStorage.getItem(
      "editSupportingDocumentRequiredIndex"
    );

    if (editSupportingDocumentRequiredIndex != null) {
      this.supportingDocumentDocuments.splice(
        parseInt(editSupportingDocumentRequiredIndex),
        1
      ); var document = {
        documentName: this.documentName,
        documentUrl: this.sffilelink,
      };

      this.supportingDocumentDocuments
        .splice(parseInt(editSupportingDocumentRequiredIndex), 0, document);
    } else {
      this.supportingDocumentDocuments.push({
        documentName: this.documentName,
        documentUrl: this.sffilelink,
      });
    }
    this.documentName = "";
    this.sffilename = "";
    this.sffilelink = "";

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );
    localStorage.removeItem("editSupportingDocumentRequiredIndex");

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  editSupportingDocument(index, document) {
    this.documentName = document.documentName;
    this.sffilelink = document.documentUrl;
    localStorage.setItem("editSupportingDocumentRequiredIndex", index);
  }

  tabChanged(tabChangedEvent) { }

  remove(index) {
    this.supportingDocumentDocuments.splice(index, 1);

    localStorage.setItem(
      "SupportingDocumentDocuments",
      JSON.stringify(this.supportingDocumentDocuments)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  removeLink(index) {
    this.supportingDocumentHyperLinks.splice(index, 1);
    localStorage.setItem(
      "SupportingDocumentHyperLinksRequired",
      JSON.stringify(this.supportingDocumentHyperLinks)
    );

    this.totalSupportingDocuments =
      this.supportingDocumentHyperLinks.length +
      this.supportingDocumentDocuments.length;
  }

  uploadSupportingDocuments(event, source) {
    if (event.target.files) {
      this.noResultReturned = true;
      //
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);

      // this.isStepCoverPageURISet = true;
      this.isAddSupportingDocument = true;
      if (
        this.fileData.name.includes("pdf") ||
        this.fileData.name.includes("PDF")
      ) {
        this.http
          .post(
            environment.apiBaseUrl + environment.uploadDocumentsUrl,
            formData
          )
          .subscribe((data) => {
            var mediaUploadStatus = data as any;
            this.showImage = true;
            this.showVideo = false;
            this.noResultReturned = false;
            //
            if (mediaUploadStatus != null) {
              this.sffilename = mediaUploadStatus.fileName;
              this.sffilelink =
                environment.documentsStorageBaseUrl +
                mediaUploadStatus.fileName;
              this.openLink(this.sffilelink);
            } else {
              alert(mediaUploadStatus.message);
            }
          });
      }
    }
  }

  openLink(url) {
    url = "https://" + url;
    window.open(url);
  }

  selectFiles(event: any, source: string) {
    if (event.target.files) {
      this.noResultReturned = true;
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.isAddSupportingDocument = true;
      this.isLoading = true;

      // File type detection
      const imageExtensions = ["JPEG", "jpeg", "png", "jpg", "PNG", "JFIF", "jfif", "TIFF", "tiff", "TIF", "tif", "JPG", "pdf", "PDF"];
      const videoExtensions = ["MP4", "mp4", "MOV", "mov", "WMV", "wmv", "FLV", "flv", "AVCHD", "avchd", "WEBM", "webm",
        "MKV", "mkv", "VOB", "vob", "OGV", "ogv", "GIFY", "gifv", "M4P", "m4p", "M4V", "m4v",
        "3GP", "3gp", "3G2", "3g2", "AMV", "amv", "NSV", "nsv", "OGG", "ogg", "F4V", "f4v",
        "F4P", "f4p", "F4A", "f4a", "F4B", "f4b"];

      const isImageOrPdf = imageExtensions.some(ext => this.fileData.name.includes(ext));
      const isVideo = videoExtensions.some(ext => this.fileData.name.includes(ext));

      const uploadUrl = isImageOrPdf
        ? environment.apiBaseUrl + environment.uploadImageUrl
        : environment.apiBaseUrl + environment.uploadVideoUrl;

      this.http
        .post(uploadUrl, formData, {
          reportProgress: true,
          observe: 'events'
        })
        .subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total) {
                  this.progress = Math.round((event.loaded / event.total) * 100);
                }
                break;
              case HttpEventType.Response:
                this.handleFileUpload(event.body, isImageOrPdf, source);
                break;
            }
          },
          (error) => {
            this.isLoading = false;
            this.noResultReturned = false;
            this.fileName = '';
            this.toastManager.warning("Please upload files up to 500MB");
            console.error('Upload error:', error);
          }
        );
    }
  }

  handleFileUpload(response: any, isImageOrPdf: boolean, source: string) {
    this.isLoading = false;
    this.noResultReturned = false;
    const mediaUploadStatus = response;

    if (mediaUploadStatus) {
      if (isImageOrPdf) {
        this.showImage = true;
        this.showVideo = false;
        if (source === "cp") {
          this.fileName = this.fileData.name;
          this.coverPageURI = environment.imageStorageBaseUrl + mediaUploadStatus.fileName;
        } else if (source === "sd") {
          this.sffilename = mediaUploadStatus.fileName;
        }
      } else {
        this.showVideo = true;
        this.showImage = false;
        if (source === "cp") {
          this.fileName = this.fileData.name;
          this.coverPageURI = environment.videoStorageBaseUrl + mediaUploadStatus.fileName;
          this.coverPosterPageURI = environment.imageStorageBaseUrl + mediaUploadStatus.posterImageName;
        } else if (source === "sd") {
          this.sffilename = mediaUploadStatus.fileName;
        }
      }
      this.toastManager.success("File has been uploaded successfully.");
      this.progress = 100;
    } else {
      this.toastManager.error("Upload failed");
    }
  }

  titleChanged() {
    this.title = this.title.trim();

    if (this.title.length == this.maxtitlelenght) {
      this.title = this.title.substring(0, this.title.length);
    }

    if (this.currenttitlelenght <= this.maxtitlelenght)
      this.currenttitlelenght = this.title.length;

    this.setIsDisable();
  }

  setIsDisable() {
    if (
      this.currenttitlelenght > 0 &&
      this.currentdesclenght > 0 &&
      (this.imageURI != null || this.videoURI != null)
    ) {
      this.enableButton = false;
    } else {
      this.enableButton = true;
    }
  }

  descChanged() {
    this.description = this.description.trim();
    if (this.description.length == this.maxdesclenght) {
      this.description = this.description.substring(0, this.description.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght)
      this.currentdesclenght = this.description.length;

    this.setIsDisable();
  }

  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;

  GoToOriginalText() {
    this.description = this.originalContentTitleText;
  }
  openDescrptionRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.description }  // Pass the text to the dialog
    });
    if (!this.isTitleRephrased) {
      this.originalContentTitleText = this.description;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isRephraseInProgress = true;
        // Update the title with the rephrased text and show the selected style 
        this.description = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
      }
    });
  }

  originalTitleText!: string;
  repharaseButtonTitleText: string = "Rephrase text with AI";
  isTitleRephrased: boolean = false;
  isTitleRephraseInProgress: boolean = false;

  GoToOriginalTitleText() {
    this.title = this.originalTitleText;
  }

  async rephraseTitleText(input: string) {

    if (input == undefined || input == "") {
      this.toastManager.warning(
        "The Text To Rephrase is required."
      );
      return;
    }
  }

  openRephraseDialog() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.title }  // Pass the text to the dialog
    });
    if (!this.isTitleRephrased) {
      this.originalTitleText = this.title;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style
        this.isTitleRephraseInProgress = true;
        this.title = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isTitleRephrased = true;
      }
    });
  }
  openGetKeyWordsDialog() {
    const dialogRef = this.dialog.open(GenerateKeyword, {
      width: '400px',
      data: { textToRephrase: this.title, textDescrption: this.description }  // Pass the text to the dialog
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  

        if (result.predefinedKeywords.length > 0) {
          this.keywords = Array.from(new Set([...this.keywords, ...result.predefinedKeywords]));
          this.predefinedKeywords = this.keywords;
        } else {
          this.toastManager.info("Keyword generation failed. Please provide more specific data in the title and description.");
          this.predefinedKeywords = []; // Ensures predefinedKeywords is always an array
        }
      }
    });
  }
  async getKeyWords(data) {
    const aiModel = {
      textToRephrase: data,
      userInput: "Generate Keywords"
    };

    try {
      const aiResponse = (await this.getcontentService
        .generateKeyWord(aiModel))
        .subscribe((aiResponse) => {
          if (aiResponse) {
            console.log(aiResponse);
            this.predefinedKeywords = aiResponse[0].completions ? aiResponse[0].completions.split(", ").map(k => k.trim()) : [];
            // if (aiResponse && aiResponse[0].completions && aiResponse[0].completions != undefined) {
            //   if (this.rephrasedText != null) {
            //     this.showConfirm = true;
            //     this.isTitleRephrased = true;
            //     this.data.textToRephrase = this.rephrasedText;
            //     this.isRephraseInProgress = false;
            //   }
            //   console.log('Rephrased Text:', this.rephrasedText); // Debugging log
            // } else {
            //   this.isRephraseInProgress = false;
            //   console.error('Unexpected AI response:', aiResponse);
            // }
          }
        });
    } catch (error) {
      console.error('Error during rephrasing:', error);
    } finally {
      this.isRephraseInProgress = false;  // Ensure this runs regardless of success or error
    }
  }

}  