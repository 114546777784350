import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { AnalyticsComponent } from "../../analyics/analytics.component";
import { Router } from "@angular/router";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild("likeCanvas") likeCanvas: ElementRef | undefined;
  @ViewChild("bookmarkCanvas") bookmarkCanvas!: { nativeElement: any };
  @ViewChild("salesCanvas") salesCanvas!: { nativeElement: any };
  @ViewChild("commentsCanvas") commentsCanvas!: { nativeElement: any };

  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;

  NoOfBookmarks: any;
  NoOfLikes: any;
  NoOfSales: any;
  NoOfComments: any;
  tabIndex: any;
  FlowCount: any;
  noApprovedFlows: any;
  noResultReturned: boolean=false;;
  userEmail: string;
  dashboardModel: any;
  userData: any;
  private searchTerms = new Subject<string>();
  userDashboardModelDetails: any[] = [];
  input: string = "";
  linksClick: any;
  totalLinkClicks: number;
  totalGrossSales: any;
  totalNetSales: any;
  totalDonatedFunds: any;
  selectedFilter: string = "";
  blue = "#ofa";
  toogleMenuClass: boolean = false;
  isToggleClass!: boolean;

  constructor( 
    private flowServiceService: FlowServiceService, 
    // private toastr: ToastrService,
    public formbuilder: UntypedFormBuilder,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog, 
  ) {
    this.userDashboardModelDetails = [];
    console.log("In Dashboard component");
    this.searchTerms
      .pipe(debounceTime(1000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        this.performSearch();
      });
  }

  ngOnDestroy() {
    console.log("ngOnDestroy()");
  }

  performSearch() {
    console.log(this.dashboardModel.userDashboardModelDetails);
    this.userDashboardModelDetails =
      this.dashboardModel.userDashboardModelDetails.filter((f) =>
        f.flowName.toLowerCase().includes(this.input.toLowerCase())
      );
  }

  async ngOnInit(): Promise<void> {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.userEmail = "";  
    if (this.userData != null && this.userData.emailAddress) {
      this.userEmail = this.userData.emailAddress;

      this.userDashboardModelDetails = [];
      var flowQueryModel = new FlowQueryModel({
        userEmail: this.userData.emailAddress,
      });
      this.getUserDashboard(flowQueryModel);
      this.selectedFilter = 'All';
      this.cdr.detectChanges(); // Ensures Angular detects changes
  
    }else{
      this.router.navigate(['/login']); 
    } 
  }

  selectedFilterValue(value: any) {
    switch (value) {
      case (value == 'All'):
        this.selectedFilter = 'All'
        break;
      case (value == 'Flows'):
        this.selectedFilter = 'Flows'
        break;
      case (value == 'Bundles'):
        this.selectedFilter = 'Bundles'
        break;
      case (value == 'Paid Flows'):
        this.selectedFilter = 'Paid Flows'
        break;
      case (value == 'Paid Bundles'):
        this.selectedFilter = 'Paid Bundles'
        break;
    }
  }

  goToFlowOrBundle(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "/");
    console.log(flow);
    if (flow.flowType == "Bundle") {
      //this.isItBundle = true;
      console.log(flow.flowType);
      this.router.navigate(["/viewbundle/" + flow.id]);
    } else {
      //this.isItBundle = false;
      this.router.navigate([`/guide/${this.generateUrlSlug(flow.flowName)}/${flow.id}`]);
    }
  }

  generateUrlSlug(html) {
    if (!html) return '';
    return html
      .replace(/<[^>]*>/g, '')  // Remove HTML tags
      .normalize("NFD").replace(/[\u0300-\u036f]/g, '') // Remove accents (é → e, ñ → n)
      .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and "-"
      .trim() // Trim spaces from start/end
      .replace(/\s+/g, '-') // Replace spaces with "-"
      .replace(/-+/g, '-') // Remove duplicate dashes
      .toLowerCase(); // Convert to lowercase
  }

  getLinksContentCount() {
    var flowModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
    });

    this.noResultReturned = true;
    // this.bundleService.getAllBundleLinkClicks(flowModel).subscribe((data) => {
    //   console.log(data);
    //   this.noResultReturned = false;
    //   // this.dashboardModel = data.DashboardModel;
    //   this.linksClick = data;
    //   this.calculateTotalLinkClicks();
    // });
  }
  handleFilter(filterType: string) {
    this.selectedFilter = '';
    // Implement your logic to handle the filter selection here
    let filter: any;
    switch (filterType) {
      case "All":
        filter = "All";
        this.getAll();
        break;
      case "Flows":
        filter = "Flows";
        this.getFlows();
        break;
      case "Bundles":
        filter = "Bundles";
        this.getBundles();
        break;
      case "Paid Flows":
        filter = "Paid Flows";
        this.getPaidFlows();
        break;
      case "Paid Bundles":
        filter = "Paid Bundles";
        this.getPaidBundles();
        break;
      default:
        filter = "";
        // Handle default case or throw error
        break;
    }
    console.log('filter', filter);
    this.selectedFilter = filter;
  }
  getAll() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
    });
    this.getUserDashboard(flowQueryModel);
  }

  getToAnalytics() {
    this.router.navigateByUrl("/content-analytics");
  }
  getToDonation() {
    this.router.navigateByUrl("/gift");
  }
  getToConsultation() {
    this.router.navigateByUrl("/pending-consultations");
  }
  getFlows() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Flow",
    });
    this.flowServiceService
      .getUserDashboardFlows(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  getBundles() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Bundles",
    });
    this.flowServiceService
      .getUserDashboardBundles(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  getPaidFlows() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Flow",
    });
    this.flowServiceService
      .getUserDashboardPaidFlows(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }
  addMenutoggleClass() {
    console.log(this.toogleMenuClass);
    this.toogleMenuClass = !this.toogleMenuClass;
    this.isToggleClass = false;

  }
  getPaidBundles() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Bundles",
    });
    this.flowServiceService
      .getUserDashboardPaidBundles(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  processDashboardData(data: any) {
    this.noResultReturned = true;
    this.dashboardModel = data;
    console.log(this.dashboardModel);
    this.userDashboardModelDetails =
      this.dashboardModel.userDashboardModelDetails;
    this.noResultReturned = false;
    this.calculateTotalSale(this.userDashboardModelDetails);
  }

  calculateTotalLinkClicks() {
    this.totalLinkClicks = 0;

    for (let content of this.linksClick) {
      this.totalLinkClicks += content.totalCount;
    }
  }
  searchFlow() {
    this.searchTerms.next(this.input);
  }

  async getUserDashboard(flowQueryModel) {
    this.noResultReturned = true;
    this.dashboardModel = this.flowServiceService.
      getUserDashboard(flowQueryModel)
      .subscribe((data) => {
        console.log(data);
        this.noResultReturned = false;
        this.dashboardModel = data;
        console.log(this.dashboardModel?.totalNnumberOfFlows);

        if (this.dashboardModel.totalViews == null) {
          this.dashboardModel.totalViews = 0;
        }
        this.userDashboardModelDetails =
          this.dashboardModel.userDashboardModelDetails;
        console.log(this.userDashboardModelDetails);
        setTimeout(() => this.cdr.detectChanges(), 0); // 🔥 Force update after data binding
        this.getLinksContentCount();
        this.calculateTotalSale(this.dashboardModel?.userDashboardModelDetails);
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      });
  }
  calculateTotalSale(data) {
    this.totalGrossSales = 0;
    this.totalNetSales = 0;

    data.forEach((element) => {
      this.totalGrossSales += element.totalGrossSales;
      this.totalNetSales += element.totalNetSales;
    });

    // Round to two decimal places
    this.totalGrossSales = this.totalGrossSales.toFixed(2);
    this.totalNetSales = this.totalNetSales.toFixed(2);

    console.log(this.totalGrossSales);
    console.log(this.totalNetSales);

    this.cdr.detectChanges();
  }

  analyticsPage(flow) {
    localStorage.setItem("analyticsflow", JSON.stringify(flow));
    const dialogRef = this.dialog.open(AnalyticsComponent, {
      panelClass: "dialogueboxnew",
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  ngAfterViewInit(): void { 
    console.log("ngAfterViewInit"); 
   this.userEmail = "";

    if (this.userData != null && this.userData.emailAddress) {
      this.userEmail = this.userData.emailAddress;

      this.userDashboardModelDetails = [];
      var flowQueryModel = new FlowQueryModel({
        userEmail: this.userData.emailAddress,
      });
      this.getUserDashboard(flowQueryModel);
      this.selectedFilter = 'All';
      this.cdr.detectChanges(); // Ensures Angular detects changes
  
    }else{
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.router.url }
      }); 
    } 
  }

  tabChanged(event) { }
}
